<template>
  <div class="logout-dropdown" @click="logout">
    <img src="@/assets/icons/icon-logout.svg" alt="icon" />
    <span class="logout-btn">Log out</span>
  </div>
</template>

<script>
import { useMsal } from "@/hooks/useMsal";
import { useStore } from "vuex";

export default {
  setup() {
    const { instance } = useMsal();
    const store = useStore();
    const homeAccountId = store.getters["auth/homeAccountId"];
    const currentAccount = instance.getAccountByHomeId(homeAccountId);
    const logout = () => {
      instance.logout({
        logoutHint: currentAccount.idTokenClaims.login_hint,
        mainWindowRedirectUri: process.env.VUE_APP_BASE_APP_URL,
      });
    };
    return { logout };
  },
};
</script>

<style>
</style>