import { msalInstance } from "./../auth";
// import { InteractionType } from "@azure/msal-browser";
import store from "@/store";

export function registerGuard(router) {
    router.beforeEach(async (to, from) => {
        // const isAuthenticated = store.getters["auth/isAuthenticated"];
        console.log(to, "to", from);
        if (to.meta.auth) {
            const shouldProceed = await isAuthenticatedCheck(
                msalInstance,
                to.meta.admin,
                to.meta.superAdmin
                // InteractionType.Popup,
                // request
            );
            return shouldProceed || "/login";

            // const request = {
            //     ...loginRequest,
            //     redirectStartPage: to.fullPath,
            // };
        }
        if (to.name === "login") {
            const shouldProceed = await isAuthenticatedCheck(
                msalInstance
                // InteractionType.Popup,
                // request
            );
            return !shouldProceed || "/";
        }

        return true;
    });
}

export async function isAuthenticatedCheck(
    instance,
    isAdmin,
    isSuperAdmin
   
    // interactionType,
    // loginRequest
) {
    // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
    return instance
        .handleRedirectPromise()
        .then(async () => {
            const accounts = instance.getAllAccounts();
            const scope = process.env.VUE_APP_AAD_API_SCOPE;
            const accessTokenRequest = {
                scopes: [scope],
                account: accounts?.[0],
            };

            if (accounts.length > 0) {
                store.commit("auth/setUsername", accounts?.[0]?.name);

                store.commit(
                    "auth/setHomeAccountId",
                    accounts?.[0]?.homeAccountId
                );
                const accessTokenResponse = await instance.acquireTokenSilent(
                    accessTokenRequest
                );
                let accessToken = accessTokenResponse.accessToken;
                // Call your API with token
                store.commit("auth/setToken", accessToken);
                await store.dispatch("auth/setSuperAdmin", accessToken);
                if (isSuperAdmin) {
                    const isSuperAdmin = store.getters["auth/isSuperAdmin"];
                    return isSuperAdmin;
                } else {
                    if (isAdmin) {
                        const isAdmin = store.getters["auth/isAdmin"];
                        return isAdmin;
                    }
                }
                return true;
            }

            // User is not signed in and attempting to access protected route. Sign them in.
            // if (interactionType === InteractionType.Popup) {
            //     return instance
            //         .loginPopup(loginRequest)
            //         .then(() => {
            //             return true;
            //         })
            //         .catch(() => {
            //             return false;
            //         });
            // } else if (interactionType === InteractionType.Redirect) {
            //     return instance
            //         .loginRedirect(loginRequest)
            //         .then(() => {
            //             return true;
            //         })
            //         .catch(() => {
            //             return false;
            //         });
            // }

            return false;
        })
        .catch(() => {
            return false;
        });
}

