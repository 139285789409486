<template>
  <div id="" class="tabs-container">
    <div
      v-for="option in options"
      :key="option"
      @click="setActive(option)"
      class="tabs-option"
      :class="{ 'tabs-option--active': activeItem === option }"
    >
      {{ option }}
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "@vue/reactivity";
import { onMounted, watch } from "vue";
export default {
  props: {
    options: Array,
    activeOption: String,
    setSelectedAction: {},
  },
  setup(props) {
    const { activeOption } = toRefs(props);
    const activeItem = ref("");
    watch(activeOption, () => {
      activeItem.value = activeOption.value;
    });
    onMounted(() => {
      activeItem.value = activeOption.value;
    });
    const setActive = (name) => {
      if (props.setSelectedAction) {
        props.setSelectedAction(name);
        activeItem.value = name;
      }
    };
    return { setActive, activeItem };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/tabs.scss";
</style>