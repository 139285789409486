<template>
    <div class="custom-time-picker" ref="target">
        <div class="picker-input-wrapper" @click="openAndScrollTo">
            <input
                ref="inputRef"
                type="time"
                class="date-container date-container--square"
                step="step"
                v-model="choosenTime"
                :placeholder="time"
                :min="minDate"
                :class="isTimePickerOpen ? 'active' : ''"
            />
        </div>

        <div class="time-container" v-if="isTimePickerOpen">
            <div class="hours-wrapper" ref="targetHours">
                <div
                    v-for="(item, index) in 24"
                    :key="index"
                    class="number-cell"
                    @click="choosenHours = index"
                    :class="index == choosenHours ? 'active' : ''"
                >
                    {{ moment().set("hours", index).format("HH") }}
                </div>
            </div>
            <div class="minutes-wrapper" ref="targetMinutes">
                <div
                    v-for="(item, index) in new Array(12)"
                    :key="index"
                    class="number-cell number-cell--minutes"
                    @click="choosenMinutes = index * 5"
                    :class="index * 5 == choosenMinutes ? 'active' : ''"
                >
                    {{
                        moment()
                            .set("minutes", index * 5)
                            .format("mm")
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, toRefs, watch } from "vue";
import moment from "moment";
import { onClickOutside } from "@vueuse/core";
export default {
    props: {
        time: String,
        minDate: String,
        step: Number,
    },
    emits: {
        setTime: Function,
    },
    setup(props, context) {
        const { time } = toRefs(props);
        const target = ref(null);
        const inputRef = ref(null);
        const targetHours = ref(null);
        const targetMinutes = ref(null);
        const isTimePickerOpen = ref(false);

        const choosenHours = ref(props.time?.split(":")?.[0]);
        const choosenMinutes = ref(props.time?.split(":")?.[1]);

        watch(time, () => {
            choosenHours.value = props.time?.split(":")?.[0];
            choosenMinutes.value = props.time?.split(":")?.[1];
        });

        const choosenTime = computed(() =>
            moment()
                .set("hours", choosenHours.value)
                .set("minutes", choosenMinutes.value)
                .format("HH:mm")
        );
        onClickOutside(target, () => {
            if (isTimePickerOpen.value) {
                if (
                    inputRef.value?.className?.includes("active") &&
                    inputRef.value.value !== choosenTime.value
                ) {
                    const hoursValue = inputRef.value?.value?.split(":")?.[0];
                    const minutesValue = inputRef.value?.value?.split(":")?.[1];

                    context.emit(
                        "set-time",
                        hoursValue +
                            ":" +
                            Math.round(
                                moment()
                                    .set("minutes", minutesValue)
                                    .minutes() / 5
                            ) *
                                5
                    );
                } else {
                    context.emit("set-time", choosenTime.value);
                }
                isTimePickerOpen.value = false;
            }
        });

        const openAndScrollTo = () => {
            isTimePickerOpen.value = true;
            setTimeout(() => {
                targetHours.value?.scrollTo(0, choosenHours.value * 40);
                targetMinutes.value?.scrollTo(
                    0,
                    (choosenMinutes.value / 5) * 40
                );
            }, 100);
        };

        return {
            isTimePickerOpen,
            choosenHours,
            choosenMinutes,
            moment,
            target,
            choosenTime,
            openAndScrollTo,
            targetHours,
            targetMinutes,
            inputRef,
        };
    },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/input.scss";
</style>

