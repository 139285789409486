<template>
  <div v-if="report.dates?.length" class="report-table-wrapper">
    <ul class="report-table">
      <li class="report-table-header--left">
        <div></div>
        <div class="report-table-header-cell--left">Total</div>
      </li>
      <li
        v-for="row in report?.reportGroupAvailabilityUtilization"
        :key="row.reportGroupId"
        class="report-table-row-wrapper"
      >
        <div class="report-table-row--left">
          <div
            class="report-table-cell"
            @click="setExpandedId(row.reportGroupId)"
          >
            {{ row.reportGroupName }}
          </div>
          <div class="report-table-cell" style="display: block">
            <div
              class="group-cell"
              :class="
                row.totalUtilizationPercentage > 95
                  ? 'cell-green'
                  : row.totalUtilizationPercentage > 90
                  ? 'cell-yellow'
                  : 'cell-red'
              "
              :style="{
                width:
                  row.totalUtilizationPercentage > 100
                    ? 100
                    : row.totalUtilizationPercentage < 0
                    ? 0
                    : row.totalUtilizationPercentage + '%',
              }"
            >
              {{
                row.totalUtilizationPercentage > 100
                  ? 100
                  : row.totalUtilizationPercentage < 0
                  ? 0
                  : row.totalUtilizationPercentage
              }}
              %
            </div>
          </div>
        </div>

        <div
          v-if="expandedId === row.reportGroupId"
          class="report-table-collapse-wrapper"
        >
          <div class="report-table-collapse-title" ref="title">
            {{ row.reportGroupName }}
          </div>
          <div class="report-collapse-row-wrapper">
            <div
              v-for="clinician in row.clinicianAvailabilityUtilization"
              :key="clinician.clinicId"
            >
              <div
                class="report-table-collapse-row--left"
                @click="setSubExpandedId(clinician?.clinicianId)"
              >
                <div class="report-table-cell">
                  {{ clinician.clinicianName }}
                </div>
                <!-- <div class="report-table-cell">
                  {{ row.percent > 100 ? 100 : row.percent }}
                </div> -->
              </div>

              <collapsed-report-wrapper
                :clinician="clinician"
                v-if="clinician?.clinicianId === subExpandedId"
                :startDate="startDate"
              />
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="report-table report-table--right">
      <li class="report-table-header--right">
        <div
          class="report-table-header-cell"
          v-for="date in report.dates"
          :key="date"
          :style="{
            width:
              report.dates?.length < 8
                ? 100 / report.dates?.length + '%'
                : '100px',
            minWidth: report.dates?.length > 7 ? '100px' : 'unset',
          }"
        >
          <template v-if="selectedGroup === 'month'">
            {{ moment(date).format("MMM") }}
          </template>
          <template v-if="selectedGroup === 'day'">
            {{ moment(date).format("DD.MM") }}
          </template>
          <template v-if="selectedGroup === 'year'">
            {{ moment(date).format("YYYY") }}
          </template>
        </div>
      </li>

      <li
        v-for="row in report?.reportGroupAvailabilityUtilization"
        :key="row.reportGroupId"
        class="report-table-row-wrapper"
        :class="{ expanded: expandedId === row.reportGroupId }"
      >
        <div class="report-table-row--right">
          <div
            v-for="(utilzPercent, index) in row.utilizationPercentages"
            :key="utilzPercent + index"
            class="report-table-cell"
            :style="{
              width:
                report.dates?.length < 8
                  ? 100 / report.dates?.length + '%'
                  : '100px',
              minWidth: report.dates?.length > 7 ? '100px' : 'unset',
            }"
          >
            <div
              :class="
                utilzPercent > 95
                  ? 'cell-green'
                  : utilzPercent > 90
                  ? 'cell-yellow'
                  : 'cell-red'
              "
            >
              {{
                utilzPercent > 100 ? 100 : utilzPercent < 0 ? 0 : utilzPercent
              }}
              {{ utilzPercent !== null ? "%" : "" }}
            </div>
          </div>
        </div>

        <div
          v-if="expandedId == row.reportGroupId"
          :style="{
            minHeight: subExpandedId ? 'unset' : rightBlockMinHeight + 'px',
          }"
        >
          <div
            v-for="clinician in row.clinicianAvailabilityUtilization"
            :key="clinician.clinicId"
            class="report-collapse-row-wrapper"
            :class="{
              subExpanded: clinician?.clinicianId === subExpandedId,
            }"
          >
            <div class="report-table-collapse-row--right">
              <div
                v-for="(percent, index) in clinician.utilizationPercentages"
                :key="percent + index"
                class="report-table-cell"
                :style="{
                  width:
                    report.dates.length < 8
                      ? 100 / report.dates.length + '%'
                      : '100px',
                  minWidth: report.dates.length > 7 ? '100px' : 'unset',
                }"
              >
                <div
                  :class="
                    percent > 95
                      ? 'cell-green'
                      : percent > 90
                      ? 'cell-yellow'
                      : 'cell-red'
                  "
                >
                  {{ percent > 100 ? 100 : percent }}
                  {{ percent !== null ? "%" : "" }}
                </div>
              </div>
            </div>

            <collapsed-clinician-info-report-wrapper
              v-if="clinician?.clinicianId === subExpandedId"
              :clinicianList="clinician"
            />
          </div>
        </div>
      </li>
    </div>
  </div>
  <div v-else>
    <h3 class="report-table--no-data">There is no data</h3>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import CollapsedReportWrapper from "./CollapsedReportWrapper.vue";
import CollapsedClinicianInfoReportWrapper from "./CollapsedClinicianInfoReportWrapper.vue";
import moment from "moment";

export default defineComponent({
  props: {
    report: Object,
    selectedGroup: String,
    startDate: String,
  },
  components: {
    CollapsedReportWrapper,
    CollapsedClinicianInfoReportWrapper,
  },
  setup() {
    const expandedId = ref("");
    const rightBlockMinHeight = ref(0);
    const title = ref(null);
    const subExpandedId = ref("");
    const collapseElementHeight = ref();
    const setElementHeight = (elementHeight) => {
      collapseElementHeight.value = elementHeight;
    };

    const setExpandedId = async (id) => {
      if (expandedId.value === id) {
        expandedId.value = "";
      } else {
        expandedId.value = await id;
      }
      subExpandedId.value = "";
    };

    watch(
      title,
      () => {
        if (title.value?.[0]?.clientHeight) {
          rightBlockMinHeight.value = title.value?.[0]?.clientHeight;
        }
      },
      {
        deep: true,
      }
    );

    const setSubExpandedId = (id) => {
      if (subExpandedId.value === id) {
        subExpandedId.value = "";
      } else {
        subExpandedId.value = id;
      }
    };
    return {
      setElementHeight,
      collapseElementHeight,
      moment,
      expandedId,
      setExpandedId,
      subExpandedId,
      setSubExpandedId,
      title,
      rightBlockMinHeight,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

