<template>
  <modal-layout v-if="isOpen">
    <div class="modal-block" ref="target">
      <div class="modal-closable-title-wrapper">
        <div class="modal-title-wrapper">
          <h3 class="modal-title">Delete Report Group</h3>
        </div>

        <div class="modal-close-wrapper" @click="closeWithoutSaving()">
          <img src="@/assets/icons/icon-close.svg" alt="icon" />
        </div>
      </div>

      <modal-separator />

      <div class="add-report-group-modal-block">
        <label for="">Name</label>
        <div class="input-text">{{ info.name }}</div>
        <div class="clinic-block">
          <h4 class="clinic-block-title">Associated Clinics</h4>
          <div class="clinic-block-list">
            {{ info?.clinicNames?.join(", ") || "None" }}
          </div>
        </div>
      </div>
      <modal-separator />
      <div class="btn-block">
        <btn-vue
          id="4a7402d6-e44d-4e68-a251-92ad7c09cc47"
          :type="'secondary-btn'"
          @click="closeWithoutSaving()"
          >Cancel</btn-vue
        >
        <btn-vue
          id="018231ce-3517-467b-91f1-cbaa6fef0c8e"
          :type="'primary-btn'"
          @click="deleteReportGroup"
          >Delete Group</btn-vue
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

export default {
  components: {
    ModalLayout,
    BtnVue,
    ModalSeparator,
  },
  props: {
    isOpen: Boolean,
    info: Object,
  },
  emits: {
    closeAddReport: Function,
  },
  setup(props, context) {
    const target = ref();
    const store = useStore();
    const closeWithoutSaving = () => {
      toast.warning("Your changes haven't been saved");
      context.emit("closeAddReport");
    };
    onClickOutside(target, () => closeWithoutSaving());

    const deleteReportGroup = async () => {
      if (props.info?.clinicNames?.length) {
        toast.error(
          "The Report Group can't be deleted. There are clinics assigned to it"
        );
      } else {
        const status = await store.dispatch(
          "report/deleteReportGroup",
          props.info.id
        );
        if (status === 200) {
          context.emit("closeAddReport");
        }
      }
    };

    return {
      deleteReportGroup,
      closeWithoutSaving,
      target,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

