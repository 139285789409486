export default {
    namespaced: true,
    state() {
        return {
            cliniciansList: [],
            cliniciansSelectedList: [],
            clinicsList: [],
            summarySlots: {},
            appointments: [],
        };
    },
    mutations: {
        setCliniciansList(state, payload) {
            state.cliniciansList = payload;
        },
        setCliniciansSelectedList(state, payload) {
            state.cliniciansSelectedList = payload;
        },
        setClinicsList(state, payload) {
            state.clinicsList = payload;
        },
        setAppointments(state, payload) {
            state.appointments = payload;
        },
        setSummarySlots(state, payload) {
            state.summarySlots = payload;
        },
    },
    getters: {
        cliniciansList(state) {
            return state.cliniciansList;
        },
        cliniciansSelectedList(state) {
            return state.cliniciansSelectedList;
        },
        clinicsList(state) {
            return state.clinicsList;
        },
        appointments(state) {
            return state.appointments;
        },
        summarySlots(state) {
            return state.summarySlots;
        },
    },
    actions: {
        setToken({ commit }, payload) {
            commit("setToken", payload);
        },

        async validatePostCode({ _, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };

                const result = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "/api/clinics/postcode/validate/" +
                        payload,
                    requestOptions
                )
                    .then((response) => {
                        return response.status;
                    })

                    .catch((error) => console.log("error", error));
                return result;
            } catch (error) {
                console.log(error, _);
                return true;
            }
        },
        async setCliniciansList({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                };

                const result = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/slot",
                    requestOptions
                )
                    .then((response) => {
                        if (response.status === 204) {
                            return "[]";
                        }
                        return response.text();
                    })

                    .catch((error) => console.log("error", error));

                if (result) {
                    commit("setCliniciansList", JSON.parse(result));
                    return true;
                }
            } catch (error) {
                console.log(error);
                return true;
            }
        },
        async setClinicsList({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const raw = JSON.stringify(payload);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/clinics",
                    requestOptions
                )
                    .then((response) => response.text())
                    .then((result) => {
                        commit("setClinicsList", JSON.parse(result));
                    })
                    .catch((error) => console.log("error", error));
            } catch (error) {
                console.log(error);
            }
        },
        async addSlot({ _, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const raw = JSON.stringify(payload);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                const status = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/slot/add",
                    requestOptions
                )
                    .then((response) => response.status)
                    .catch((error) => console.log("error", error));

                return status;
            } catch (error) {
                console.log(error, _);
            }
        },
        async getAppointments({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const raw = JSON.stringify(payload);

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinicians/appointments",
                    requestOptions
                )
                    .then((response) => {
                        if (response.status === 204) {
                            return [];
                        }
                        return response.json();
                    })
                    .catch((error) => console.log("error", error));

                commit("setAppointments", response);
            } catch (error) {
                console.log(error);
            }
        },
        async getSummarySlots({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const raw = JSON.stringify({
                    availabilitySummaryFilter: payload,
                    slotDurationInMinutes: 60,
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/availability/summary",
                    requestOptions
                )
                    .then((response) => {
                        if (response.status === 204) {
                            return {};
                        }
                        return response.json();
                    })
                    .catch((error) => console.log("error", error));

                commit("setSummarySlots", response);
            } catch (error) {
                console.log(error);
            }
        },
    },
};

