<template>
    <!-- eslint-disable -->
    <div>
        <data-table v-model:server-options="serverOptions" :headers="headers" :items="clinicianList?.items || []"
            :server-items-length="clinicianList?.totalItems" :loading="loading" :rows-items="[10, 20, 30]">
            <!-- @click="isFirstPage ? (serverOptions.page = 2) : nextPage()" -->
            <template #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }">
                <button class="icon-btn-containner icon-btn-prev" :disabled="isFirstPage" @click="prevPage" role="none"
                    aria-label="previous page"></button>
                <button class="icon-btn-containner" :disabled="isLastPage" @click="nextPage" role="none"
                    aria-label="next page"></button>
            </template>
            <template #item-name="{ name, id, g4PAccountStatus }">
                <div class="edit-container">
                    <edit-btn title="Edit Profile" @click="
                        g4PAccountStatus === 'NotRegistered' ?
                            router.push({
                                name: 'clinicians-account',
                                query: { id },
                            }) :
                            router.push({
                                name: 'clinicians-profile',
                                query: { id },
                            })
                        " />
                    {{ name }}
                </div>
            </template>
            <template #item-email="{ email }">
                <div class="copy-cell">
                    <div v-if="email" class="copy-btn" @click="copyText(email, 'Email')" title="Copy Email Address">
                        <img src="@/assets/icons/icon-copy.svg" alt="icon" />
                    </div>
                    <a :href="`mailto:${email}`" title="Send Email">{{
                        email
                    }}</a>
                </div>
            </template>
            <template #item-hcpType="{ hcpType: hcpTypeData }">
                {{ hcpTypeData }}
            </template>
            <template #item-isEmployed="{ isEmployed }">
                {{ isEmployed ? "Employed" : "Network" }}
            </template>
            <template #item-g4PAccountStatus="{ g4PAccountStatus }">
                {{
                    g4PAccountStatus === "ExpiredLink"
                    ? "Expired Link"
                    : g4PAccountStatus === "AwaitingRegistration"
                        ? "Pending Registration" : g4PAccountStatus === "NotRegistered"
                            ? "Not Registered"
                            : g4PAccountStatus
                }}
            </template>
            <template #item-telephone="{ telephone }">
                <div class="copy-cell">
                    <div v-if="telephone" class="copy-btn" @click="copyText(telephone, 'Telephone Number')"
                        title="Copy Telephone Number">
                        <img src="@/assets/icons/icon-copy.svg" alt="icon" />
                    </div>
                    <a :href="`tel:${telephone}`" title="Call Telephone Number">{{ telephone }}</a>
                </div>
            </template>
            <template #item-action="{ id, isActive, g4PAccountStatus }">
                <div class="edit-container">
                    <btn-vue @click="
                        isActive
                            ? router.push({
                                name: 'clinicians-schedule',
                                query: { id },
                            })
                            : undefined
                        " :type="'secondary-btn--square'"
                        :class="{ 'disabled-btn': !isActive || g4PAccountStatus === 'NotRegistered' }">SCHEDULE</btn-vue>
                </div>
            </template>
        </data-table>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onBeforeMount,
    ref,
    toRefs,
    watch,
} from "vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import BtnVue from "./BtnVue.vue";
import EditBtn from "./EditBtn.vue";

export default defineComponent({
    props: {
        statusSelected: String,
        includeSelected: String,
        g4pStatusSelected: String,
        specialismsSelected: Array,
        searchValue: String,
        hcpType: Array,
        hcpTypeSelected: Array,
    },
    components: {
        BtnVue,
        EditBtn,
    },
    setup(props) {
        const {
            statusSelected,
            includeSelected,
            g4pStatusSelected,
            specialismsSelected,
            searchValue,
            hcpType,
            hcpTypeSelected,
        } = toRefs(props);
        const router = useRouter();
        const store = useStore();
        const clinicianList = computed(() => {
            return store.getters["clinicians/clinicianList"];
        });
        const serverOptions = ref({
            page: 1,
            rowsPerPage: 10,
        });
        const loading = ref(false);
        const loadFromServer = async () => {
            loading.value = true;
            await store.dispatch("clinicians/setClinicianList", {
                pageNumber: serverOptions.value.page,
                pageSize: serverOptions.value.rowsPerPage,
                accountStatus: statusSelected.value?.toLowerCase(),
                searchByName: searchValue.value,
                sortBy:
                    serverOptions?.value?.sortBy === "isEmployed"
                        ? "hpstatus"
                        : serverOptions?.value?.sortBy === "g4PAccountStatus"
                            ? "g4pstatus"
                            : serverOptions?.value?.sortBy?.toLowerCase() || "name",
                sortDescending: serverOptions.value.sortType === "desc",
                employmentStatus: includeSelected.value?.toLowerCase(),
                healthcarePractitionerTypes: hcpType.value?.reduce(
                    (acc, data) => {
                        if (hcpTypeSelected.value?.includes(data)) {
                            return [
                                ...acc,
                                data.replace(" ", "").toLowerCase(),
                            ];
                        } else return acc;
                    },
                    []
                ),
                specializations: specialismsSelected.value,
                g4PAccountStatuses: g4pStatusSelected.value?.map((item) => item === 'Pending Registration' ? 'AwaitingRegistration' : item?.replace(" ", "")
                ),
            });

            loading.value = false;
        };

        watch(
            serverOptions,
            () => {
                loadFromServer();
            },
            { deep: true }
        );

        watch([statusSelected, includeSelected, searchValue], () => {
            serverOptions.value = { ...serverOptions.value, page: 1 };
        });
        watch(
            [specialismsSelected, hcpTypeSelected, g4pStatusSelected],
            (newValue, prevValue) => {
                if (JSON.stringify(newValue) != JSON.stringify(prevValue)) {
                    serverOptions.value = { ...serverOptions.value, page: 1 };
                }
            }
        );

        onBeforeMount(() => {
            loadFromServer();
        });

        const headers = [
            { text: "Name", value: "name", sortable: true },
            { text: "HCP Type", value: "hcpType", sortable: true },
            { text: "Employed Type", value: "isEmployed", sortable: true },
            { text: "G4P Status", value: "g4PAccountStatus", sortable: true },
            { text: "Email", value: "email", sortable: true },
            { text: "Telephone", value: "telephone", sortable: true },
            { text: "Action", value: "action" },
        ];

        const copyText = (text, type) => {
            navigator.clipboard.writeText(text);
            toast.success(type + " Copied to Clipboard");
        };

        const sortIcon = (column) => {
            if (!column.sortable) return "";
            return column.sortDirection === "asc"
                ? "fa-sort-up"
                : "fa-sort-down";
        };

        return {
            headers,
            serverOptions,
            loading,
            sortIcon,
            copyText,
            router,
            clinicianList,
        };
    },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

