<template>
    <div class="profile-wrapper clinician-account-page">
        <clinician-sidebar :id="profileDetails?.id" :profileDetails="profileDetails" :activeTab="'account'" />
        <div class="account-page">
            <div class="account-card-container">
                <h3 class="account-card-title">Account Details</h3>
                <div class="account-card-info-block">
                    <label class="account-card-label" for="">Email Address</label>
                    <input-vue id="4912f975-4e84-4089-9df6-c55751feb22a" :searchValue="profileDetails?.email"
                        :disabled="true" />
                    <label class="account-card-label" for="">Account Status</label>
                    <div id="d652b250-6742-4709-995b-a89a9e2bd38f" class="account-card-status" :class="'account-card-status--' +
                        profileDetails?.g4PAccountStatus
                        " v-if="!isLoading">
                        <template v-if="profileDetails?.accountStatus !== null">
                            <!-- NOT REGISTERED -->
                            {{
                                profileDetails?.g4PAccountStatus === "ExpiredLink"
                                ? "Expired Link"
                                : profileDetails?.g4PAccountStatus === "AwaitingRegistration"
                                    ? "Pending Registration" : profileDetails?.g4PAccountStatus === "NotRegistered"
                                        ? "Not registered"
                                        : profileDetails?.g4PAccountStatus
                            }}</template>
                        <template v-else> NOT REGISTERED </template>

                        <span v-if="profileDetails?.inviteExpiryDate &&
                                        (profileDetails?.g4PAccountStatus === 'ExpiredLink' ||
                                            profileDetails?.g4PAccountStatus === 'AwaitingRegistration')
                                        " class="account-card-status-expired">
                            Expiry Date:
                            {{
                                moment(profileDetails?.inviteExpiryDate).format(
                                    "DD/MM/yyyy HH:mm"
                                )
                            }}</span>
                        <span v-if="profileDetails?.deactivationReasons?.length &&
                                profileDetails?.g4PAccountStatus === 'Disabled'
                                " class="account-card-status-expired">
                            {{
                                profileDetails?.deactivationReasons?.join(", ")
                            }}</span>
                    </div>
                    <div class="account-loader" v-else>
                        <loader-vue />
                    </div>
                    <label class="account-card-label" for="">Account actions</label>
                    <btn-vue id="34ba7cb6-772c-465d-9f78-07cd609825d9" v-if="profileDetails?.g4PAccountStatus === 'AwaitingRegistration'
                            " :type="'primary-btn--blue-shadow-square'" @click="registerClinicianAccount"
                        :isDisabled="!isSuperAdmin">Resend Invite</btn-vue>
                    <btn-vue id="9a204304-04bc-4ff5-93d8-36ab22248d1e" v-if="profileDetails?.g4PAccountStatus === 'NotRegistered'"
                        :type="'primary-btn--blue-shadow-square'" @click="registerClinicianAccount"
                        :isDisabled="!isSuperAdmin">Send Invite</btn-vue>

                    <btn-vue id="34ba7cb6-772c-465d-9f78-07cd609825d9" v-if="profileDetails?.g4PAccountStatus === 'ExpiredLink'
                        " :type="'primary-btn--blue-shadow-square'" @click="registerClinicianAccount"
                        :isDisabled="!isSuperAdmin">Resend Invite</btn-vue>
                    <btn-vue id="e9f0e21b-37c9-4700-9168-076f79be625f" v-if="profileDetails?.g4PAccountStatus === 'Disabled'
                        " :type="'primary-btn--blue-shadow-square'" @click="resendInvite"
                        :isDisabled="!isSuperAdmin">Re-Enable</btn-vue>

                    <btn-vue v-if="profileDetails?.g4PAccountStatus === 'Active'
                        " :type="'primary-btn-shadow-square'" @click="isModalOpen = true"
                        :isDisabled="!isSuperAdmin">Disable Account</btn-vue>
                </div>
            </div>
        </div>
        <modal-disable-account :isOpen="isModalOpen" :name="profileDetails" @closeDisableAcc="closeDisableAcc"
            @disable-account="disableAccount" />
    </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import ClinicianSidebar from "../../components/ClinicianSidebar.vue";
import { useRoute, useRouter } from "vue-router";
import InputVue from "../../components/InputVue/InputVue.vue";
import BtnVue from "../../components/BtnVue.vue";
import ModalDisableAccount from "../../components/ModalVue/ModalDisableAccount.vue";
import { CLINICIAN_STATUSES_LIST } from "./CliniciansPage.vue";
import { toast } from "vue3-toastify";
import moment from "moment";
import LoaderVue from "../../components/LoaderVue.vue";

export default {
    components: {
        ClinicianSidebar,
        InputVue,
        BtnVue,
        ModalDisableAccount,
        LoaderVue,
    },

    setup() {
        // Account statuses from BE
        // AwaitingRegistration
        // Active
        // ExpiredLink
        // Disabled
        const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);

        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const isModalOpen = ref(false);
        const email = ref();
        const isLoading = ref(false);
        const profileDetails = computed(() => {
            return store.getters["clinicians/clinicianDetails"];
        });
        const closeDisableAcc = () => {
            isModalOpen.value = false;
        };

        const registerClinicianAccount = async () => {
            isLoading.value = true;
            const response = await store.dispatch(
                "clinicians/registerClinicianAccount",
                profileDetails.value?.id
            );
            if (response?.statusCode === 200) {
                toast.success("Invitation has been sent");
                store.dispatch(
                    "clinicians/updateClinicianDetails",
                    profileDetails.value?.id
                );
            } else {
                toast.error("Invitation hasn't been sent");
            }
            isLoading.value = false;
        };
        const disableAccount = async (deactivationReasons) => {
            isLoading.value = true;
            const response = await store.dispatch(
                "clinicians/deactivateClinicianAccount",
                {
                    clinicianId: profileDetails.value?.id,
                    deactivationReasons,
                }
            );
            if (response?.statusCode === 200) {
                toast.success("Account deactivated");
                store.dispatch(
                    "clinicians/updateClinicianDetails",
                    profileDetails.value?.id
                );
                closeDisableAcc();
            } else {
                toast.error("Account can't be deactivated");
            }
            isLoading.value = false;
        };

        const resendInvite = async () => {
            isLoading.value = true;
            const response = await store.dispatch(
                "clinicians/resendInvite",
                profileDetails.value?.id
            );
            if (response?.statusCode === 200) {
                toast.success("Account reaсtivated");
                store.dispatch(
                    "clinicians/updateClinicianDetails",
                    profileDetails.value?.id
                );
            } else {
                toast.error("Invitation hasn't been resent");
            }
            isLoading.value = false;
        };
        onMounted(async () => {
            isLoading.value = true;
            let localId;
            if (route.query.id) {
                localId =
                    route.query.id
            } else {
                localId =
                    profileDetails.value?.id || localStorage.getItem("clinicianId")

            }
            await store.dispatch(
                "clinicians/updateClinicianDetails",
                localId
            );
            isLoading.value = false;

            router.replace({
                query: {
                    id: undefined,
                },
            });
        });

        return {
            email,
            profileDetails,
            isModalOpen,
            closeDisableAcc,
            registerClinicianAccount,
            CLINICIAN_STATUSES_LIST,
            disableAccount,
            resendInvite,
            moment,
            isLoading,
            isSuperAdmin,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/ClinitianProfile.scss";
</style>

