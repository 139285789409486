<template>
    <div class="profile-wrapper clinician-account-page">
        <clinician-sidebar
            :id="profileDetails?.id"
            :profileDetails="profileDetails"
            :activeTab="'access'"
        />
        <div class="acceess-page">
            <common-layout>
                <tab-access />
            </common-layout>
        </div>
    </div>
</template>

<script>
import { computed, onMounted } from "vue";
import ClinicianSidebar from "../../components/ClinicianSidebar.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import TabAccess from "../../components/TabAccess.vue";
import CommonLayout from "../../views/CommonLayout.vue";
// import { toast } from "vue3-toastify";

export default {
    components: {
        ClinicianSidebar,
        TabAccess,
        CommonLayout,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const profileDetails = computed(() => {
            return store.getters["clinicians/clinicianDetails"];
        });

        onMounted(async () => {
            const localId =
                localStorage.getItem("clinicianId") || route.query.id;
            await store.dispatch("clinicians/updateClinicianDetails", localId);

            console.log(profileDetails.value?.g4PAccountStatus === 'NotRegistered')



            router.replace({
                query: {
                    id: undefined,
                },
            });

            if(profileDetails.value?.g4PAccountStatus === 'NotRegistered') {
                router.push({name: 'clinicians-account'})
            }
            
        });

        return { profileDetails };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/ClinitianProfile.scss";
</style>

