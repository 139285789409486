<template>
  <modal-layout v-if="isOpen">
    <div class="modal-block" ref="target">
      <div class="modal-closable-title-wrapper">
        <div class="modal-title-wrapper">
          <img
            src="@/assets/icons/icon-info.svg"
            alt="icon"
            title="Acceptable image format – jpg, jpeg, png, bmp. Maximum image size – 25 mb"
          />
          <h3 v-if="isPhotoExist" class="modal-title">Profile Image</h3>
          <h3 v-else class="modal-title">Add Profile Image</h3>
        </div>

        <div class="modal-close-wrapper" @click="close">
          <img src="@/assets/icons/icon-close.svg" alt="icon" />
        </div>
      </div>

      <modal-separator />
      <div v-if="isPhotoExist" class="modal-content modal-content--photo">
        <div class="modal-content-row">
          <label class="modal-content-row-label">Current image</label>
          <div class="row-current-photo">
            <img :src="photo" alt="clinician photo" />
          </div>
        </div>
        <btn-vue
          id="2144a819-0e44-487d-819a-4f2ce4c38159"
          :type="'primary-btn'"
          @click="deletePhoto"
          >Delete image</btn-vue
        >
      </div>
      <div v-else class="modal-content modal-content--photo">
        <div class="modal-content-row">
          <label class="modal-content-row-label"
            >Add Clinician’s Profile image</label
          >
        </div>
        <div class="modal-content-row" v-if="selectedFile">
          <div class="selected-file">
            <img :src="selectedFile" alt="" />
          </div>
          <div @click="selectedFile = ''" class="remove-file-link">
            Clear file
          </div>
        </div>
        <div class="modal-content-row" v-else>
          <label class="modal-content-row-label">Browse here</label>
          <div class="row-browse-photo">
            <form :action="onFileChange" enctype="multipart/form-data">
              <input type="file" @change="onFileChange" />
            </form>
            <img src="@/assets/icons/icon-upload.svg" alt="browse icon" />
          </div>
        </div>
      </div>
      <modal-separator />
      <div class="btn-block">
        <btn-vue
          id="11ebe460-bb3e-4914-9041-262ceba9aebf"
          :type="'secondary-btn'"
          @click="close"
          >Cancel</btn-vue
        >
        <btn-vue
          v-if="isPhotoExist"
          id="171c8c3c-3249-4cd3-8344-2a66b769a9d8"
          :type="'primary-btn--blue'"
          @click="clear"
          >Replace image</btn-vue
        >
        <btn-vue
          v-else
          id="171c8c3c-3249-4cd3-8344-2a66b769a9d8"
          :type="'primary-btn--blue'"
          @click="uploadPhoto"
          >Upload image</btn-vue
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import { toast } from "vue3-toastify";

export default {
  components: {
    ModalLayout,
    BtnVue,
    ModalSeparator,
  },
  props: {
    isOpen: Boolean,
    photoFile: String,
    id: String,
  },
  emits: {
    closeUploadPhoto: Function,
  },
  setup(props, context) {
    const store = useStore();
    const selectedFile = ref("");
    const selectedFileFormData = ref();
    const { id, photoFile } = toRefs(props);
    const isPhotoExist = ref(false);
    const target = ref();
    onClickOutside(target, () => context.emit("closeUploadPhoto"));
    watch(photoFile, () => {
      if (photoFile.value) {
        isPhotoExist.value = true;
      } else {
        isPhotoExist.value = false;
      }
    });
    const onFileChange = (event) => {
      const picture = event.target.files[0];
      selectedFileFormData.value = event.target.files[0];
      selectedFile.value = URL.createObjectURL(picture);
    };
    const close = () => {
      if (photoFile.value) {
        isPhotoExist.value = true;
      }
      selectedFile.value = "";
      context.emit("closeUploadPhoto");
    };
    const clear = () => {
      isPhotoExist.value = false;
    };
    const uploadPhoto = async () => {
      const objFile = selectedFileFormData.value;
      if (objFile) {
        const resp = await store.dispatch("clinicians/uploadPhoto", {
          id: id.value,
          file: objFile,
        });
        if (resp == 413 || resp == 400) {
          toast.error(
            "Fail to upload an image. Please check the size or format"
          );
        } else {
          context.emit("closeUploadPhoto", true);
          selectedFile.value = "";
        }
      } else {
        toast.error("Fail to upload an image. Image not selected.");
      }
    };
    const deletePhoto = async () => {
      await store.dispatch("clinicians/deletePhoto", id.value);
      selectedFile.value = "";
      isPhotoExist.value = false;
      context.emit("closeUploadPhoto", true);
    };

    return {
      uploadPhoto,
      deletePhoto,
      selectedFile,
      onFileChange,
      photo: photoFile,
      clear,
      isPhotoExist,
      close,
      target,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

