<template>
  <div class="layout-container">
    <slot name="breadcrumbs"></slot>
    <slot name="header"></slot>
    <div class="layout-title">
      <slot name="title"></slot>
      <slot name="button"></slot>
    </div>
    <div class="layout-block">
      <slot></slot>
    </div>
    <slot name="table"></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>