<template>
  <div class="edit-btn">
    <img src="@/assets/icons/icon-edit.svg" alt="icon" />
  </div>
</template>



<style lang="scss">
@import "@/assets/scss/components/btn.scss";
</style>