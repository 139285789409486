<template>
    <div class="modal-container">
            <slot></slot>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import '@/assets/scss/components/modal.scss';
</style>