<template>
  <div class="select-wrapper select-wrapper--single-search">
    <div
      ref="target"
      class="select-container"
      :class="selected?.length ? 'select-container--active' : ''"
    >
      <div class="selected-option selected-option--open">
        <span class="multiselect-text">
          <img
            v-if="withSearch"
            src="@/assets/icons/icon-search.svg"
            alt="icon"
          />
          <input
            :ref="inputRef"
            v-if="withSearch"
            type="text"
            v-model="searchText"
            autofocus
          />
          <span
            v-else
            @click="this.isOpen = true"
            :style="!withSearch ? { marginLeft: 0 } : {}"
            >{{ this.optionsTitle }}</span
          >
        </span>

        <div class="select-right-icons">
          <div v-if="withInfo" @click="this.showHintText = !this.showHintText">
            <img src="@/assets/icons/icon-info-input.svg" alt="icon" />
          </div>
          <div @click="this.isOpen = true">
            <img
              class="select-arrow-icon"
              src="@/assets/icons/select-icon.svg"
              alt="icon"
            />
          </div>
        </div>
      </div>
      <ul v-if="isOpen">
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          @click="setSelected(option)"
          class="option"
          :class="withDots ? 'withDots' : ''"
        >
          <span>{{ option.name }}</span>
        </li>
      </ul>
    </div>

    <div v-if="showHintText && withInfo" class="hint-text-wrapper">
      <span class="hint-text">Info text</span>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { toRefs, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useField } from "vee-validate";
import * as yup from "yup";

export default {
  props: {
    options: Array,
    selectedDefault: Array,
    withInfo: {
      require: false,
    },
    withSearch: {
      require: false,
    },
    withDots: {
      require: false,
    },
    setSelectedAction: {},
    isClinic: Boolean,
  },
  emits: {
    switchProfile: Function,
  },
  setup(props, context) {
    const { value: inputRef } = useField("search", yup.string().trim());
    const selected = ref(props.selectedDefault);
    const filteredOptions = ref([]);
    const { options } = toRefs(props);
    const searchText = ref(null);
    const optionsTitle = ref("");
    const isOpen = ref(true);
    const showHintText = ref(false);
    const target = ref(null);

    onClickOutside(target, () => {
      if (isOpen.value) {
        context.emit("switch-profile");
      }
    });
    const setDefault = () => {
      selected.value = props.selectedDefault;
    };
    const setSelected = (option) => {
      if (props.isClinic) {
        context.emit("switch-profile", option.codedId);
      } else {
        context.emit("switch-profile", option.id);
      }
    };

    watch(searchText, (value) => {
      filteredOptions.value = options?.value?.filter((data) =>
        data.name.toLowerCase().includes(value.toLowerCase())
      );
    });

    watch(options, () => {
      filteredOptions.value = options?.value;
    });

    return {
      selected,
      setSelected,
      isOpen,
      target,
      optionsTitle,
      showHintText,
      setDefault,
      searchText,
      filteredOptions,
      inputRef,
    };
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/select.scss";
</style>
