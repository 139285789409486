<template>
    <div class="select-wrapper select-wrapper--multi">
        <div ref="target" class="select-container" :class="selected?.length ? 'select-container--active' : ''">
            <div class="selected-option" :class="{ 'selected-option--open': this.isOpen }">
                <span class="multiselect-text">
                    <img v-if="withSearch" src="@/assets/icons/icon-search.svg" alt="icon" />
                    <input :ref="inputRef" v-if="this.isOpen && withSearch" type="text" v-model="searchText"
                        :placeholder="'enter here'" autofocus />
                    <span v-else @click="this.isOpen = true" :class="{
                        placeholderText: optionsTitle === placeholderText,
                    }" :style="!withSearch ? { marginLeft: 0 } : {}">{{ this.optionsTitle }}</span>
                </span>

                <div class="select-right-icons">
                    <div v-if="withInfo" @click="this.showHintText = !this.showHintText">
                        <img src="@/assets/icons/icon-info-input.svg" alt="icon" />
                    </div>
                    <div v-if="this.isOpen" @click="closeAndUpdate">
                        <img class="select-arrow-icon select-arrow-icon--inverted" src="@/assets/icons/select-icon.svg"
                            alt="icon" />
                    </div>
                    <div v-else @click="this.isOpen = true">
                        <img class="select-arrow-icon" src="@/assets/icons/select-icon.svg" alt="icon" />
                    </div>
                </div>
            </div>
            <ul v-if="isOpen" :class="withInputOption ? 'withInputOption' : ''">
                <div v-if="!withInputOption" @click="this.setSelectedAll()" class="select-all-block">
                    <input type="checkbox" class="select-checkbox" :checked="selected.length === options.length" />
                    <span>Select All</span>
                </div>
                <!-- <div class="clear-all-block" @click="cleardAll()" v-else>
          <span>Clear selected</span>
        </div> -->
                <li v-for="(option, index) in filteredOptions" :key="index" @click="this.setSelected(option)" class="option"
                    :class="withDots ? 'withDots' : ''">
                    <input type="checkbox" class="select-checkbox" :checked="selected.includes(option)" />
                    <span>{{ option }}</span>
                </li>
                <li v-if="withInputOption" class="option" :class="withInputOption ? 'withInputOption' : ''"
                    @click="inputOption ? this.setSelected(inputOption) : {}">
                    <label class="input-option-label" for="">Other reason</label>
                    <div class="input-option-block">
                        <input type="checkbox" class="select-checkbox" :checked="selected.includes(inputOption)" />
                        <input-vue :searchValue="inputOption" @setText="setInputOption" @blur="setInput"
                            @click="onInputFocus" :placeholder="'Write other reason'" />
                    </div>
                </li>
            </ul>
        </div>

        <div v-if="showHintText && withInfo" class="hint-text-wrapper">
            <span class="hint-text">Info text</span>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { toRefs, onMounted } from "vue";
import { onClickOutside } from "@vueuse/core";
import { computed, watch } from "@vue/runtime-core";
import { useField } from "vee-validate";
import * as yup from "yup";
import InputVue from "../InputVue/InputVue.vue";

export default {
    components: { InputVue },
    props: {
        options: Array,
        selectedDefault: Array,
        placeholderText: String,
        g4pMultiSelect: Boolean,
        defaultAll: {
            require: false,
        },
        withInfo: {
            require: false,
        },
        withSearch: {
            require: false,
        },
        withDots: {
            require: false,
        },
        withInputOption: {
            require: false,
        },
        setSelectedAction: {},
    },
    setup(props) {
        const { value: inputRef } = useField("search", yup.string().trim());
        const selected = ref(props.selectedDefault);
        const filteredOptions = ref([]);
        const inputOption = ref("");
        const settedInputOption = ref("");
        const { options, defaultAll, selectedDefault, g4pMultiSelect } = toRefs(props);
        const searchText = ref(null);
        const optionsTitle = computed(() => {
            let placeholderText;
            if (g4pMultiSelect.value) {
                if (selected.value?.join() === 'Active,Disabled,Expired Link,Pending Registration') {
                    placeholderText = "All Registered";
                    return placeholderText
                }
            }
            if (!props.withInputOption) {
                if (selected.value?.length === props.options?.length) {
                    placeholderText = "All";
                } else {
                    placeholderText =
                        selected.value?.join(", ") ||
                        props?.placeholderText ||
                        "None";
                }
            } else {
                if (selected.value?.length === props.options?.length + 1) {
                    placeholderText = "All";
                } else {
                    placeholderText =
                        selected.value?.join(", ") ||
                        props?.placeholderText ||
                        "None";
                }
            }

            return placeholderText;
        });
        const isOpen = ref(false);
        const showHintText = ref(false);
        const target = ref(null);
        onClickOutside(target, () => {
            if (isOpen.value) {
                props.setSelectedAction(selected.value);
                isOpen.value = false;
            }
        });
        const closeAndUpdate = () => {
            props.setSelectedAction(selected.value);
            isOpen.value = false;
        };
        const setInputOption = (text) => {
            inputOption.value = text;
        };
        const setSelectedAll = () => {
            if (selected.value?.length !== options?.value?.length) {
                selected.value = options.value;
            } else {
                selected.value = [];
            }
        };
        const setDefault = () => {
            selected.value = props.selectedDefault;
            isOpen.value = !isOpen.value;
        };
        const setSelected = (text) => {
            if (!selected.value?.includes(text)) {
                selected.value = [...selected.value, text];
            } else {
                selected.value = selected.value?.filter(
                    (data) => data !== text
                );
            }
        };
        const setInput = (e) => {
            if (settedInputOption.value !== e.target._value) {
                selected.value = selected.value?.filter(
                    (data) => data !== settedInputOption.value
                );
            }

            settedInputOption.value = e.target._value;
        };
        const onInputFocus = (e) => {
            e.stopImmediatePropagation();
        };

        watch(searchText, (value) => {
            filteredOptions.value = options?.value?.filter((data) =>
                data.toLowerCase().includes(value.toLowerCase())
            );
        });

        watch(options, () => {
            filteredOptions.value = options?.value;
            if (defaultAll.value) {
                selected.value = options?.value;
            }
        });

        watch(selectedDefault, () => {
            selected.value = selectedDefault?.value;
        });
        onMounted(() => {
            filteredOptions.value = options?.value;
        });

        return {
            selected,
            setSelected,
            isOpen,
            target,
            optionsTitle,
            setSelectedAll,
            showHintText,
            setDefault,
            searchText,
            filteredOptions,
            inputRef,
            closeAndUpdate,
            setInputOption,
            setInput,
            inputOption,
            onInputFocus,
        };
    },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/select.scss";
</style>

