<template>
    <div class="separator"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import '@/assets/scss/base.scss';

.separator {
    height: 1px;
    width: 100%;
    background-color: $separator;
}
</style>