<template>
  <div>
    <header-menu></header-menu>
    <router-view></router-view>
  </div>
</template>

<script>
import HeaderMenu from "./components/HeaderMenu.vue";

export default {
  name: "App",
  components: { HeaderMenu },
  setup() {},
};
</script>

<style>
</style>
