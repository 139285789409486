<template>
  <div class="dropdown-wrapper">
    <div ref="target" class="dropdown-container">
      <div @click="this.isOpen = !this.isOpen" class="dropdown-option">
        <span class="dropdown-title">
          {{ options?.join(", ") }}
        </span>
        <img src="@/assets/icons/icon-expand.svg" alt="icon" />
      </div>
      <ul v-if="isOpen">
        <li
          v-for="(option, index) in options"
          :key="index"
          @click="this.setSelected(option)"
          class="option"
        >
          <span>{{ option }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onClickOutside } from "@vueuse/core";

export default {
  props: {
    options: Array,
    width: String,
  },
  setup() {
    const isOpen = ref(false);
    const target = ref(null);
    onClickOutside(target, () => (isOpen.value = false));
    return { isOpen, target };
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/dropdown.scss";
</style>