<template>
  <aside class="profile-sidebar">
    <div class="icon-wrapper" @click="isSuperAdmin ? openPhotoModal() : undefined">
      <img v-if="profileDetails?.photo" :src="profileDetails.photo" alt="icon"
        style="width: auto; height: 100%; align-self: flex-start" />
      <img v-else src="@/assets/icons/icon-camera.svg" alt="icon" />
    </div>
    <div class="profile-info-wrapper">
      <h3 class="profile-title">
        {{
          (profileDetails?.forename || "") +
          " " +
          (profileDetails?.surname || "")
        }}
      </h3>
      <span class="profile-sub-title">{{
        ["CBT Therapist", "Psychologist", "Counsellor", "Not set"][
          profileDetails?.clinicianType
        ]
      }}</span>

      <div class="clincian-status-info" :class="'clincian-status-info--' +
          profileDetails?.g4PAccountStatus
          ">
        {{
          profileDetails?.g4PAccountStatus === "ExpiredLink"
          ? "Expired Link"
          : profileDetails?.g4PAccountStatus === "AwaitingRegistration"
            ? "Pending Registration" : profileDetails?.g4PAccountStatus === "NotRegistered"
              ? "Not registered"
              : profileDetails?.g4PAccountStatus
        }}

      </div>
    </div>
    <div class="profile-btns-block">
      <btn-vue :type="'nav-secondary-btn'" class="profile-icon"
        :class="{ 'active-sidebar-btn': activeTab === 'profile', 'disabled-btn': profileDetails?.g4PAccountStatus === 'NotRegistered' }"
        @click="
          router.push({
            name: 'clinicians-profile',
            query: { id },
          })
          ">Profile</btn-vue>
      <btn-vue @click="
        profileDetails?.isActive
          ? router.push({
            name: 'clinicians-schedule',
            query: { id },
          })
          : undefined
        " :type="'nav-secondary-btn'" :class="{
    'active-sidebar-btn': activeTab === 'schedule',
    'disabled-btn': !profileDetails?.isActive || profileDetails?.g4PAccountStatus === 'NotRegistered'
  }" class="schedule-icon">Schedule</btn-vue>
      <btn-vue @click="
        router.push({
          name: 'clinicians-account',
          query: { id },
        })
        " :type="'nav-secondary-btn'" :class="{ 'active-sidebar-btn': activeTab === 'account' }"
        class="account-icon">Account</btn-vue>
      <btn-vue @click="
        router.push({
          name: 'clinicians-access',
          query: { id },
        })
        " :type="'nav-secondary-btn'" :class="{ 'active-sidebar-btn': activeTab === 'access', 'disabled-btn': profileDetails?.g4PAccountStatus === 'NotRegistered' }"
        class="access-icon">Case Access</btn-vue>
    </div>
    <a class="link-wrapper" :href="internalLink + '#/hcps/hcp/' + id" target="_blank"><btn-vue
        :type="'secondary-btn--square'">view HCP record</btn-vue></a>
    <modal-upload-photo :id="id" :photoFile="profileDetails?.photo" :isOpen="isPhotoModalOpen"
      @closeUploadPhoto="closeUploadPhoto" />
  </aside>
</template>

<script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import ModalUploadPhoto from "./ModalVue/ModalUploadPhoto.vue";
import BtnVue from "./BtnVue.vue";
import { CLINICIAN_STATUSES_LIST } from "./../pages/Clinicians/CliniciansPage.vue";
import { useStore } from 'vuex';

export default {
  components: { ModalUploadPhoto, BtnVue },
  props: {
    id: String,
    activeTab: String,
    profileDetails: Object,
  },
  setup() {
    const store = useStore();
    const internalLink = process.env.VUE_APP_INTERNAL_PORTAL_URL;
    const router = useRouter();
    const isPhotoModalOpen = ref(false);
    const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);
    const openPhotoModal = () => {
      isPhotoModalOpen.value = true;
    };
    const closeUploadPhoto = () => {
      isPhotoModalOpen.value = false;
    };
    return {
      router,
      openPhotoModal,
      isPhotoModalOpen,
      closeUploadPhoto,
      internalLink,
      CLINICIAN_STATUSES_LIST,
      isSuperAdmin
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/ClinitianProfile.scss";
</style>