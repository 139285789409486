import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../pages/LoginPage.vue";
import HomePage from "../pages/HomePage.vue";
import ClinicianPage from "../pages/Clinicians/CliniciansPage.vue";
import ClinicianProfilePage from "../pages/Clinicians/ClinicianProfilePage.vue";
import CliniciansTablePage from "../pages/Clinicians/CliniciansTablePage.vue";
import AvailabilityTablePage from "../pages/Availability/AvailabilityTablePage.vue";
import AvailabilityPage from "../pages/Availability/AvailabilityPage.vue";
import ReportPage from "../pages/Report/ReportPage.vue";
import ReportMainPage from "../pages/Report/ReportMainPage.vue";
import ReportTablePage from "../pages/Report/ReportTablePage.vue";
import AvailabilityReservationPage from "../pages/Availability/AvailabilityReservationPage.vue";
import ClinicPage from "../pages/Clinics/ClinicsPage.vue";
import ClinicProfilePage from "../pages/Clinics/ClinicProfilePage.vue";
import ClinicTablePage from "../pages/Clinics/ClinicsTablePage.vue";
import CliniciansSchedulePage from "../pages/Clinicians/CliniciansSchedulePage.vue";
import CliniciansAccountPage from "../pages/Clinicians/CliniciansAccountPage.vue";
import { registerGuard } from "./guard";
import CliniciansAccessPage from "@/pages/Clinicians/CliniciansAccessPage.vue";

const routes = [
    {
        path: "/login",
        name: "login",
        component: LoginPage,
        meta: {
            auth: false,
            superAdmin: false,
            admin: false,
        },
    },
    {
        path: "/clinicians",
        name: "clinicians",
        component: ClinicianPage,
        children: [
            {
                path: "/clinicians",
                name: "clinicians",
                component: CliniciansTablePage,
            },
            {
                path: "/clinicians/profile",
                name: "clinicians-profile",
                component: ClinicianProfilePage,
                props: true,
            },
            {
                path: "/clinicians/schedule",
                name: "clinicians-schedule",
                component: CliniciansSchedulePage,
                props: true,
            },
            {
                path: "/clinicians/account",
                name: "clinicians-account",
                component: CliniciansAccountPage,
                props: true,
            },
            {
                path: "/clinicians/access",
                name: "clinicians-access",
                component: CliniciansAccessPage,
                props: true,
            },
        ],
        meta: {
            auth: true,
            admin: true,
            menuActive: "clinicians",
        },
    },
    {
        path: "/clinics",
        name: "clinics",
        component: ClinicPage,
        children: [
            {
                path: "/clinics",
                name: "clinics",
                component: ClinicTablePage,
            },
            {
                path: "/clinics/profile",
                name: "clinic-profile",
                component: ClinicProfilePage,
                props: true,
            },
        ],
        meta: {
            auth: true,
            admin: true,
            menuActive: "clinics",
        },
    },
    {
        path: "/availability",
        name: "availability",
        component: AvailabilityPage,
        children: [
            {
                path: "/availability",
                name: "availability-search",
                component: AvailabilityTablePage,
            },
            {
                path: "/availability/reservation",
                name: "availability-reservation",
                component: AvailabilityReservationPage,
            },
        ],
        meta: {
            auth: true,
            menuActive: "availability",
        },
    },
    {
        path: "/report",
        name: "report",
        component: ReportPage,
        children: [
            {
                path: "/report",
                name: "report-main",
                component: ReportMainPage,
            },
            {
                path: "/report/groups",
                name: "report-groups",
                component: ReportTablePage,
            },
        ],
        meta: {
            auth: true,
            superAdmin: true,
        },
    },
    {
        path: "/",
        name: "home",
        component: HomePage,
        meta: {
            auth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "menu-item--active",
});

registerGuard(router);

export default router;

