<template>
  <div class="multi-calendar" :class="'appoint--' + filter.filter">
    <schedule-filter
      :data="filterStructure"
      :setData="setFilter"
      :id="'slot'"
    />
    <div>
      <span class="multi-calendar-header">
        {{ moment(date).format("Do MMM YYYY") }}</span
      >
    </div>
    <div class="multi-calendar-block">
      <ul>
        <li class="multi-calendar-row multi-calendar-header-names">
          <div
            class="multi-calendar-row-title"
            :class="sortType"
            @click="changeSortType"
          >
            <div class="sortType-icon--wrapper">
              <i class="sortType-icon"></i>
            </div>

            Time
          </div>
        </li>
        <li
          class="multi-calendar-row"
          v-for="slot in existedSlotNames"
          :key="slot"
        >
          <div class="multi-calendar-row-title">{{ slot }}</div>
        </li>
      </ul>

      <div ref="timelineDayRef" class="multi-calendar-right-block" tabindex="0">
        <div class="multi-calendar-header-hours">
          <div
            class="multi-calendar-hours-row-slot"
            v-for="hour in 24"
            :key="hour"
          >
            <div
              v-if="
                moment({ hour: hour - 1 }).format('HH') ===
                moment().format('HH')
              "
              class="curTime"
              :style="{
                left: (7.5 / 6) * moment().minute() + 'px',
                height: existedSlotNames?.length * 75 - 20 + 'px',
              }"
            >
              <span id="currentTime">{{ moment().format("HH:mm") }}</span>
            </div>
            <span> {{ moment({ hour: hour - 1 }).format("HH:mm") }}</span>
          </div>
        </div>
        <ul>
          <li
            class="multi-calendar-row"
            v-for="slot in existedSlots"
            :key="slot.slotId"
          >
            <ul class="multi-calendar-hours-row">
              <li
                class="multi-calendar-hours-row-slot"
                v-for="hour in 24"
                :key="hour"
              >
                <span class="inner-lines"></span>
                <span class="inner-lines"></span>
              </li>
              <template v-for="appoint in appointments">
                <li
                  :title="`${appoint.patientName} - ${
                    appoint.appointmentType
                  } ${moment(appoint.appointmentStart).format(
                    'HH:mm'
                  )} - ${moment(appoint.appointmentStart)
                    .add(appoint.appointmentDuration, 'minutes')
                    .format('HH:mm')}`"
                  style="margin-top: 20px"
                  :key="appoint.appointmentId"
                  v-if="
                    filter.filter !== 'newPatientAvailability' &&
                    appoint.clinicianSystemId == slot?.clinicianSystemId
                  "
                  :style="{
                    width: `calc((100% / 24) / 60 * ${appoint?.appointmentDuration})`,
                    left: `calc((100% / 24) * ${moment(
                      appoint.appointmentStart
                    ).hour()} + ${moment(
                      appoint.appointmentStart
                    ).minute()} * ((100% / 24)/60))`,
                  }"
                  class="multi-calendar-hours-row-appoint"
                  :class="[
                    {
                      isOpen: appoint?.isOpen,
                      isCancelled: appoint?.isCancelled,
                      isAttended: appoint?.isAttended,
                      isDna: appoint?.isDna,
                    },
                    'appoint--' + filter.filter,
                  ]"
                >
                  {{ appoint.patientName }}
                </li>
              </template>
              <template v-for="currentSlot in slot?.slots">
                <li
                  :title="`${moment(currentSlot.time).format(
                    'HH:mm'
                  )} - ${moment(currentSlot.time)
                    .add(currentSlot.duration, 'minutes')
                    .format('HH:mm')}`"
                  ref="slotRef"
                  :key="currentSlot.time"
                  v-if="
                    filter.filter === 'showAll' ||
                    filter.filter === 'newPatientAvailability'
                  "
                  class="multi-calendar-hours-row-availability--empty multi-calendar-hours-row-availability"
                  :class="
                    pickedSlotId === currentSlot.slotId ? 'picked-slot' : ''
                  "
                  :style="{
                    width: `calc((100% / 24) / 60 * ${currentSlot?.duration})`,
                    left: `calc((100% / 24) * ${moment(
                      currentSlot.time
                    ).hour()} + ${moment(
                      currentSlot.time
                    ).minute()} * ((100% / 24)/60))`,
                  }"
                  @click="setSlot(currentSlot.slotId)"
                ></li>
              </template>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="btns-schedule-container">
      <div class="btn-schedule-view" @click="$emit('goToSlotView')">
        <img src="@/assets/icons/icon-schedule-view.svg" alt="icon" /> slot view
      </div>
      <btn-vue
        v-if="pickedSlotId"
        @click="setPickedSlot()"
        :type="'primary-btn primary-btn-shadow'"
        >Select</btn-vue
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import ScheduleFilter from "./ScheduleFilter.vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import BtnVue from "./BtnVue.vue";

export default {
  components: { ScheduleFilter, BtnVue },
  emits: {
    goToSlotView: Function,
  },
  props: {
    existedTimeslots: Array,
    date: Date,
  },
  setup(props, context) {
    const router = useRouter();
    const timelineDayRef = ref({});
    const filterStructure = ref({});
    const sortType = ref("none");
    const filter = ref({ filter: "showAll" });
    const slots = ref([]);
    const pickedSlotId = ref(null);
    const store = useStore();
    const slotRef = ref(null);
    const choosenSlot = ref(null);
    const existedSlots = ref(
      props.existedTimeslots?.flat()?.reduce((acc, value) => {
        const currAccItem = acc?.find(
          (data) => data.clinicianName === value.clinicianName
        );
        if (currAccItem) {
          currAccItem.slots = [
            ...currAccItem?.slots,
            {
              time: value.nextSlotTime,
              duration: value.nextSlotDuration,
              clinicianSystemId: value.clinicianSystemId,
              slotId: value.slotId,
              clinicianName: value.clinicianName,
            },
          ];
          return acc;
        } else {
          return [
            ...acc,
            {
              ...value,
              slots: [
                {
                  time: value.nextSlotTime,
                  duration: value.nextSlotDuration,
                  clinicianSystemId: value.clinicianSystemId,
                  slotId: value.slotId,
                  clinicianName: value.clinicianName,
                },
              ],
            },
          ];
        }
      }, [])
    );
    const existedSlotNames = ref(
      props.existedTimeslots?.flat()?.reduce((acc, value) => {
        if (!acc?.includes(value.clinicianName)) {
          return [...acc, value.clinicianName];
        } else {
          return acc;
        }
      }, [])
    );
    onClickOutside(slotRef, () => {
      pickedSlotId.value = null;
    });
    const appointments = computed(() => {
      return store.getters["availability/appointments"];
    });
    const setFilter = (data) => {
      filter.value = data;
    };

    const goBack = () => {
      router.go(-1);
    };
    watch(appointments, () => {
      setFilterStructure();
    });
    onMounted(() => {
      timelineDayRef.value.scrollTo(75 * moment().hour(), 0);

      const startDate = moment(props.date).startOf("day");
      const endDate = moment(props.date).endOf("day");
      store.dispatch("availability/getAppointments", {
        systemIds: props.existedTimeslots?.reduce((acc, value) => {
          if (!acc?.includes(value[0].clinicianSystemId)) {
            return [...acc, value[0].clinicianSystemId];
          } else {
            return acc;
          }
        }, []),
        startDate,
        endDate,
        keyFilter: "showAll",
      });
    });
    const setFilterStructure = () => {
      const defaultFilterValue = [
        {
          title: "Attended",
          key: "isAttended",
          filter: "attended",
          value: 0,
        },
        {
          title: "Cancelled",
          key: "isCancelled",
          filter: "cancelled",
          value: 0,
        },
        { title: "DNA", key: "isDna", filter: "dna", value: 0 },
        {
          title: "Unconfirmed",
          key: "isOpen",
          filter: "unconfirmed",
          value: 0,
        },
      ];
      appointments?.value?.map((itemAppoint) => {
        ["isAttended", "isCancelled", "isDna", "isOpen"].forEach((item) => {
          const keyFilter = defaultFilterValue?.find(
            (data) => data.key === item
          );

          if (itemAppoint[keyFilter.key]) {
            keyFilter.value++;
          }
        });
      });
      filterStructure.value = defaultFilterValue;
    };
    const setSlot = (id) => {
      pickedSlotId.value = id;
      choosenSlot.value = id;
    };
    const setPickedSlot = () => {
      context.emit("go-to-slot-view", choosenSlot.value);
    };

    watch(sortType, () => {
      if (sortType.value === "asc") {
        existedSlotNames.value = existedSlotNames.value?.sort((a, b) =>
          a.toLowerCase() > b.toLowerCase() ? 1 : -1
        );

        existedSlots.value = existedSlots.value?.sort((a, b) =>
          a.clinicianName.toLowerCase() > b.clinicianName.toLowerCase() ? 1 : -1
        );
      }
      if (sortType.value === "desc") {
        existedSlotNames.value = existedSlotNames.value?.sort((a, b) =>
          a.toLowerCase() < b.toLowerCase() ? 1 : -1
        );

        existedSlots.value = existedSlots.value?.sort((a, b) =>
          a.clinicianName.toLowerCase() < b.clinicianName.toLowerCase() ? 1 : -1
        );
      }
      if (sortType.value === "none") {
        existedSlotNames.value = props.existedTimeslots
          ?.flat()
          ?.reduce((acc, value) => {
            if (!acc?.includes(value.clinicianName)) {
              return [...acc, value.clinicianName];
            } else {
              return acc;
            }
          }, []);

        existedSlots.value = props.existedTimeslots
          ?.flat()
          ?.reduce((acc, value) => {
            const currAccItem = acc?.find(
              (data) => data.clinicianName === value.clinicianName
            );
            if (currAccItem) {
              currAccItem.slots = [
                ...currAccItem?.slots,
                {
                  time: value.nextSlotTime,
                  duration: value.nextSlotDuration,
                  clinicianSystemId: value.clinicianSystemId,
                  slotId: value.slotId,
                  clinicianName: value.clinicianName,
                },
              ];
              return acc;
            } else {
              return [
                ...acc,
                {
                  ...value,
                  slots: [
                    {
                      time: value.nextSlotTime,
                      duration: value.nextSlotDuration,
                      clinicianSystemId: value.clinicianSystemId,
                      slotId: value.slotId,
                      clinicianName: value.clinicianName,
                    },
                  ],
                },
              ];
            }
          }, []);
      }
    });

    const changeSortType = () => {
      if (sortType.value === "none") {
        sortType.value = "asc";
      } else {
        if (sortType.value === "asc") {
          sortType.value = "desc";
        } else {
          if (sortType.value === "desc") {
            sortType.value = "none";
          }
        }
      }
    };
    return {
      setPickedSlot,
      changeSortType,
      sortType,
      moment,
      goBack,
      timelineDayRef,
      slots,
      filterStructure,
      setFilter,
      filter,
      appointments,
      setSlot,
      pickedSlotId,
      slotRef,
      existedSlotNames,
      existedSlots,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Clinitians.scss";
</style>