<template>
  <common-layout
    class="report-page"
    :class="showSuccesMessage ? 'success-reservation-messsage-layout' : ''"
  >
    <template #header v-if="!showSuccesMessage">
      <div class="reservation-header">
        <h4 class="reservation-header-title">Clinicians Selected</h4>
        <div class="reservation-header-right-block">
          <span class="reservation-header-right-block-title">
            Clinician Type
          </span>
          <div class="reservation-header-right-block-btn-type">
            {{ clinicianTypesSelected }}
          </div>
          <div class="reservation-header-right-block--line"></div>
          <span class="reservation-header-right-block-title">
            {{
              clinicianList?.reduce((acc, value) => {
                if (!acc?.includes(value.clinicianName)) {
                  return [...acc, value.clinicianName];
                } else {
                  return acc;
                }
              }, []).length
            }}
            Clinicians Selected
          </span>

          <div
            @click="goBack()"
            class="reservation-header-right-block-btn-edit"
          >
            Edit
          </div>
        </div>
      </div>
    </template>
    <multi-clinicians-calendar
      @go-to-slot-view="goToSlotView"
      :date="date"
      :existedTimeslots="existedTimeslots"
      v-if="isMultiCalendarShown"
    />
    <template v-else>
      <div v-if="showSuccesMessage" class="success-reservation-messsage">
        <h3 class="success-reservation-messsage--title">
          Availability Slot Held
        </h3>
        <img src="@/assets/icons/icon-success-hold.svg" alt="icon" />
        <h5 class="success-reservation-messsage--subtitle">
          {{
            moment(choosenSlot?.nextSlotTime).format(
              "Do MMMM, YYYY [at] hh:mma"
            )
          }}
          <br />
          with {{ choosenSlot?.clinicianName }} <br />
          {{ choosenSlot?.clinicLocation }}
        </h5>
        <h5 class="success-reservation-messsage--subtitle"></h5>
        <p class="success-reservation-messsage--description">
          Your appointment slot will be held for <br />
          <b>30 mins</b> from
          <b> {{ moment().format("hh:mma") }}</b>
          on the
          <b> {{ moment().format("DD/MM/YYYY") }}</b
          >. <br />
          After this time this appointment slot will be reallocated.<br />
          Please book this appointment in Gateway
        </p>
        <btn-vue :type="'primary-btn--blue'" @click="close()">Close</btn-vue>
      </div>
      <div v-else class="slot-wrapper">
        <div class="slot-block">
          <h2 class="label-slot">Select a day</h2>
          <VueDatePicker
            :min-date="moment().startOf('day')"
            v-model="date"
            inline
            auto-apply
            :highlight="highlightedDates"
          >
            <template #month-year="{ month, handleMonthYearChange }">
              <div class="calendar-header-block" :class="{ choosen: nextSlot }">
                <div class="filter-warning-message">
                  <h2 @click="setNextSlot()">
                    Next Available Slot:
                    <span>
                      {{
                        clinicianList?.[0]?.nextSlotTime
                          ? moment(clinicianList?.[0]?.nextSlotTime).format(
                              "HH:mm DD/MM/YYYY"
                            )
                          : ""
                      }}</span
                    >
                  </h2>
                </div>
                <div class="calendar-header-row">
                  <div class="calendar-header-row-title">Available dates</div>

                  <div class="calendar-month-switch">
                    <div
                      class="calendar-header-btn"
                      @click="handleMonthYearChange(false)"
                    >
                      <img
                        src="@/assets/icons/icon-prev-month.svg"
                        alt="icon"
                      />
                    </div>
                    <span style="color: #0d4d57">
                      {{ moment(month + 1, "M").format("MMMM") }}</span
                    >
                    <div
                      class="calendar-header-btn"
                      @click="handleMonthYearChange(true)"
                    >
                      <img
                        src="@/assets/icons/icon-next-month.svg"
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #time-picker="{}"></template>
          </VueDatePicker>
          <div
            v-if="existedTimeslots?.length"
            class="btn-schedule-view"
            @click="isMultiCalendarShown = true"
          >
            <img src="@/assets/icons/icon-schedule-view.svg" alt="icon" />
            schedule view
          </div>
        </div>
        <div class="slot-block slot-block--time-slot">
          <div>
            <h2 class="label-slot">Select a timeslot</h2>
            <div class="calendar-month-switch timeslot">
              <div class="calendar-header-btn" @click="nextDay()">
                <img src="@/assets/icons/icon-prev-month.svg" alt="icon" />
              </div>
              <span style="color: #0d4d57">
                {{ moment(date).format("Do MMM YYYY") }}</span
              >
              <div class="calendar-header-btn" @click="prevDay()">
                <img src="@/assets/icons/icon-next-month.svg" alt="icon" />
              </div>
            </div>
            <template v-if="existedTimeslots?.length">
              <div
                class="slots-scroll-container"
                tabindex="0"
                :style="
                  existedTimeslots?.length > 8 ? 'padding-right: 10px;' : ''
                "
              >
                <div
                  v-for="timeslot in existedTimeslots"
                  :key="timeslot.clinicDomainId"
                  class="timeslot-block"
                  :class="
                    availableTimeslotClinicians[0]?.nextSlotTime ===
                    timeslot[0]?.nextSlotTime
                      ? 'timeslot-block--choosen'
                      : ''
                  "
                  @click="
                    (availableTimeslotClinicians = timeslot), (choosenSlot = {})
                  "
                >
                  {{ moment(timeslot[0].nextSlotTime).format("HH:mm") }}
                </div>
              </div></template
            >
            <template v-else>
              <div class="available-time-slot-message">No available slots</div>
            </template>
          </div>
          <div class="slot-block-bottom" v-if="existedTimeslots?.length">
            {{ existedTimeslots?.length }} timeslot<span
              v-if="existedTimeslots?.length > 1"
              >s</span
            >
          </div>
        </div>

        <div class="slot-block">
          <h2 class="label-slot label-slot-clinicians">Select a clinician</h2>
          <div
            v-for="info in availableTimeslotClinicians"
            :key="info?.clinicianSystemId"
            class="clinician-block"
            :class="
              choosenSlot?.clinicianSystemId === info.clinicianSystemId
                ? 'clinician-block--selected'
                : ''
            "
            @click="chooseSlot(info)"
          >
            <h4
              class="clinician-block-title"
              :class="info.distanceInMiles ? 'clinician-block-title--f2f' : ''"
            >
              {{ info.clinicianName }} - {{ info.hcpType }}
            </h4>
            <div class="clinician-block-info-wrapper">
              <div>
                <h5 class="clinician-block-info-wrapper-title">Location</h5>
                {{ info.clinicLocation }} <br />
                {{ info.clinicType }} <br />
                {{ info.addressLine1 }}
              </div>
              <div v-if="info.distanceInMiles">
                <h5 class="clinician-block-info-wrapper-title">Distance</h5>

                <b>{{ info.distanceInMiles }} </b> miles from:
                {{ info.clinicPostcode }}
              </div>
            </div>
          </div>
          <div
            class="slot-block-bottom"
            v-if="availableTimeslotClinicians?.length"
          >
            {{ availableTimeslotClinicians?.length }} clinician<span
              v-if="availableTimeslotClinicians?.length > 1"
              >s</span
            >
          </div>
          <btn-vue
            v-if="choosenSlot?.clinicianSystemId"
            @click="holdSlot()"
            :type="'primary-btn primary-btn-shadow'"
            >Temporarily hold slot</btn-vue
          >
        </div>
      </div>
    </template>
  </common-layout>
</template>

<script>
import CommonLayout from "../../views/CommonLayout.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { computed, onMounted, ref, watch } from "vue";
import moment from "moment";
import BtnVue from "../../components/BtnVue.vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import MultiCliniciansCalendar from "../../components/MultiCliniciansCalendar.vue";
function compare(a, b) {
  const dateA = moment(a.nextSlotTime, "YYYY-MM-DD");
  const dateB = moment(b.nextSlotTime, "YYYY-MM-DD");
  if (dateA.isBefore(dateB)) {
    return -1;
  }
  if (dateA.isAfter(dateB)) {
    return 1;
  }
  return 0;
}
export default {
  components: {
    CommonLayout,
    VueDatePicker,
    BtnVue,
    MultiCliniciansCalendar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isMultiCalendarShown = ref(false);
    const showSuccesMessage = ref(false);
    const clinicianTypesSelected = ref("");
    const choosenSlot = ref({});
    const date = ref(new Date());
    const timeSlotDate = ref(new Date());
    const existedTimeslots = ref([]);
    const highlightedDates = ref();
    const availableTimeslotClinicians = ref([]);
    const nextSlot = ref(route.query.nextSlot);
    const clinicianList = computed(
      () => store.getters["availability/cliniciansSelectedList"]
    );
    watch(date, () => {
      const groupedSlotsValue = clinicianList.value?.filter((data) =>
        moment(data.nextSlotTime).isSame(moment(date.value), "day")
      );
      const groupedSlots = groupedSlotsValue?.reduce((result, slot) => {
        const hourMinute = moment(slot.nextSlotTime).format("HH:mm");
        if (!result[hourMinute]) {
          result[hourMinute] = [];
        }
        result[hourMinute].push(slot);
        return result;
      }, {});
      existedTimeslots.value = Object.values(groupedSlots || {});
      if (!existedTimeslots.value?.length) {
        toast.error(
          "No time is available for the date. Please, select another date"
        );
      }
      if (
        !moment(clinicianList.value?.[0]?.nextSlotTime).isSame(
          existedTimeslots.value?.[0]?.[0]?.nextSlotTime,
          "day"
        )
      ) {
        availableTimeslotClinicians.value = [];
        choosenSlot.value = {};
        nextSlot.value = false;
      }
      if (!nextSlot.value) {
        availableTimeslotClinicians.value = [];
        choosenSlot.value = {};
      }
    });

    onMounted(() => {
      highlightedDates.value = clinicianList?.value
        ?.sort(compare)
        ?.map((data) => data.nextSlotTime);

      const filterJSON = sessionStorage.getItem("filter");
      const filterData = JSON.parse(filterJSON);

      clinicianTypesSelected.value =
        filterData?.clinicianTypeSelected?.join(", ");

      if (route.query.nextSlot) {
        setNextSlot();
      } else {
        if (
          moment(highlightedDates.value?.[0]?.nextSlotTime).isSame(
            moment(),
            "day"
          )
        ) {
          const groupedSlotsValue = clinicianList.value?.filter((data) =>
            moment(data.nextSlotTime).isSame(moment(date.value), "day")
          );
          const groupedSlots = groupedSlotsValue?.reduce((result, slot) => {
            const hourMinute = moment(slot.nextSlotTime).format("HH:mm");
            if (!result[hourMinute]) {
              result[hourMinute] = [];
            }
            result[hourMinute].push(slot);
            return result;
          }, {});

          existedTimeslots.value = Object.values(groupedSlots || {});
        }
      }
    });

    const chooseSlot = async (slot) => {
      choosenSlot.value = slot;
    };

    const holdSlot = async () => {
      const status = await store.dispatch("availability/addSlot", {
        clinicianSystemId: choosenSlot.value.clinicianSystemId,
        clinicSystemId: choosenSlot.value.clinicSystemId,
        nextSlotTime: choosenSlot.value.nextSlotTime,
        nextSlotDuration: choosenSlot.value.nextSlotDuration,
      });
      if (status === 200) {
        showSuccesMessage.value = true;
        sessionStorage.removeItem("cliniciansList");
        sessionStorage.removeItem("filter");
      } else {
        toast.error("The availability slot has been already reserved");
      }
    };

    const close = async () => {
      router.push({
        name: "availability-search",
      });
    };

    const goBack = () => {
      router.go(-1);
    };
    const setNextSlot = () => {
      date.value = clinicianList.value?.[0]?.nextSlotTime;

      const groupedSlotsValue = clinicianList.value?.filter((data) =>
        moment(data.nextSlotTime).isSame(
          moment(clinicianList.value?.[0]?.nextSlotTime),
          "day"
        )
      );
      const groupedSlots = groupedSlotsValue?.reduce((result, slot) => {
        const hourMinute = moment(slot.nextSlotTime).format("HH:mm");
        if (!result[hourMinute]) {
          result[hourMinute] = [];
        }
        result[hourMinute].push(slot);
        return result;
      }, {});
      existedTimeslots.value = Object.values(groupedSlots || {});
      availableTimeslotClinicians.value = existedTimeslots.value?.[0];
      if (existedTimeslots.value?.[0]?.length === 1) {
        chooseSlot(existedTimeslots.value?.[0]?.[0]);
      }

      nextSlot.value = true;
    };

    const goToSlotView = (slotId) => {
      isMultiCalendarShown.value = false;
      if (slotId) {
        const slot = clinicianList.value.find((data) => data.slotId == slotId);
        availableTimeslotClinicians.value = [slot];
        choosenSlot.value = slot;
      }
    };

    const nextDay = () => {
      date.value = moment(date.value).subtract(1, "day");
    };

    const prevDay = () => {
      date.value = moment(date.value).add(1, "day");
    };

    onBeforeRouteLeave((to) => {
      if (to.fullPath !== "/availability") {
        sessionStorage.removeItem("filter");
      }
    });

    return {
      setNextSlot,
      nextSlot,
      goToSlotView,
      nextDay,
      prevDay,
      date,
      highlightedDates,
      moment,
      timeSlotDate,
      clinicianList,
      existedTimeslots,
      availableTimeslotClinicians,
      showSuccesMessage,
      chooseSlot,
      choosenSlot,
      close,
      holdSlot,
      clinicianTypesSelected,
      goBack,
      isMultiCalendarShown,
      route,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Clinitians.scss";
@import "@/assets/scss/components/calendar.scss";
</style>