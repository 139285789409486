<template>
    <section class="profile-wrapper">
        <clinician-sidebar
            :id="id"
            :profileDetails="profileDetails"
            :activeTab="'profile'"
        />
        <div class="layout-container">
            <schedule-breadcrumbs
                :cliniciansList="cliniciansList"
                :type="'profile'"
                :profileName="
                    (profileDetails?.forename || '') +
                    ' ' +
                    (profileDetails?.surname || '')
                "
                @setIsLoading="setIsLoading"
            />
            <div class="profile-container">
                <div
                    class="profile-info-block"
                    :class="{ 'profile-info-block__loading-mask': isLoading }"
                >
                    <div v-if="isLoading">
                        <loader-vue />
                    </div>

                    <template v-else>
                        <h4 class="profile-container-title">Profile</h4>

                        <div class="info-rows">
                            <label for="5edbd984-464a-4191-aa28-b451eadb2045"
                                >Title</label
                            >
                            <input-vue
                                id="5edbd984-464a-4191-aa28-b451eadb2045"
                                :disabled="true"
                                :placeholder="profileDetails?.title"
                            />
                            <label for="d28e9dc1-d6de-4751-8bef-b82fd58aee2d"
                                >Forename</label
                            >
                            <input-vue
                                id="d28e9dc1-d6de-4751-8bef-b82fd58aee2d"
                                :disabled="true"
                                :placeholder="profileDetails?.forename"
                            />
                            <label for="f6f4c160-e6e0-4f61-b043-7e43b40e6cb9"
                                >Surname</label
                            >
                            <input-vue
                                id="f6f4c160-e6e0-4f61-b043-7e43b40e6cb9"
                                :disabled="true"
                                :placeholder="profileDetails?.surname"
                            />
                            <label for="961f6b7a-f14e-4df6-9b52-51e8d3066889"
                                >Tel</label
                            >
                            <div class="copy-input">
                                <input-vue
                                    id="961f6b7a-f14e-4df6-9b52-51e8d3066889"
                                    :disabled="true"
                                    :placeholder="profileDetails?.telephone"
                                />
                                <div
                                    class="copy-input-icon"
                                    @click="
                                        copyText(
                                            profileDetails?.telephone,
                                            'Telephone Number'
                                        )
                                    "
                                    title="Copy Telephone Number"
                                >
                                    <img
                                        src="@/assets/icons/icon-copy.svg"
                                        alt="icon"
                                    />
                                </div>
                            </div>

                            <label for="e148ff82-6a77-4623-90d1-e3a561476eda"
                                >Email</label
                            >
                            <div class="copy-input">
                                <input-vue
                                    id="e148ff82-6a77-4623-90d1-e3a561476eda"
                                    :disabled="true"
                                    :placeholder="profileDetails?.email"
                                />
                                <div
                                    class="copy-input-icon"
                                    @click="
                                        copyText(profileDetails?.email, 'Email')
                                    "
                                    title="Copy Email Address"
                                >
                                    <img
                                        src="@/assets/icons/icon-copy.svg"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <label for="1666f86a-a2e1-475a-b692-cfea735f128a"
                                >HCP Type</label
                            >
                            <select-vue
                                v-if="isSuperAdmin"
                                id="1666f86a-a2e1-475a-b692-cfea735f128a"
                                :selectedDefault="hcpTypeChoosen || 'Not Set'"
                                :options="hcpType"
                                :setSelectedAction="setHcpType"
                            ></select-vue>
                            <input-vue
                                v-else
                                id="1a6673e8-db22-4462-a3d3-184274f62551"
                                :disabled="true"
                                :placeholder="hcpTypeChoosen || 'Not Set'"
                            />
                            <label for="7e373591-d642-406b-81e5-ca9f907a701f"
                                >Prof. Reg.</label
                            >
                            <input-vue
                                id="7e373591-d642-406b-81e5-ca9f907a701f"
                                :disabled="true"
                                :placeholder="
                                    profileDetails?.professionalRegistrationNumber
                                "
                            />
                            <label>Specialisms</label>
                            <dropdown-vue
                                :options="profileDetails?.specialisations"
                            />
                            <label>Employed type</label>
                            <radio-btns
                                :disabled="!isSuperAdmin"
                                :btns="[
                                    {
                                        title: 'Y',
                                        checked: profileDetails?.isEmployed,
                                        id: '72303561-a0f2-4ad9-b8b2-7db2497ca9d9',
                                    },
                                    {
                                        title: 'N',
                                        checked: !profileDetails?.isEmployed,
                                        id: '134b68cf-b320-4655-9284-1f8e8ac5d10a',
                                    },
                                ]"
                                :name="'employed'"
                                @set-checked="setChecked"
                                class="profile-radio-group"
                            />
                            <label>Has Schedule</label>
                            <radio-btns
                                :btns="[
                                    {
                                        title: 'Y',
                                        checked: profileDetails?.hasSchedule,
                                        id: '583cc351-ac80-466c-8f88-b3dd5acf30b1',
                                    },
                                    {
                                        title: 'N',
                                        checked: !profileDetails?.hasSchedule,
                                        id: '91037c45-2aaa-4b8e-8700-7c39a008f12b',
                                    },
                                ]"
                                :name="'schedule'"
                                :disabled="true"
                                class="profile-radio-group"
                            />
                            <label>Self Manage Availability</label>
                            <radio-btns
                                :btns="[
                                    {
                                        title: 'Y',
                                        checked:
                                            profileDetails?.selfManagedAvailability,
                                        id: '4f5d7953-c5d6-4b20-a31e-5a5c7a51a331',
                                    },
                                    {
                                        title: 'N',
                                        checked:
                                            !profileDetails?.selfManagedAvailability,
                                        id: '18aadec3-51ab-40e0-9128-0c314c3556c6',
                                    },
                                ]"
                                :name="'avalibillity'"
                                @set-checked="setCheckedAvailability"
                                class="profile-radio-group"
                            />
                            <label for="c00f923f-844e-4366-8e98-6b4aa55c7e0c"
                                >Active</label
                            >
                            <input
                                id="c00f923f-844e-4366-8e98-6b4aa55c7e0c"
                                class="profile-checkbox"
                                type="checkbox"
                                :checked="profileDetails?.isActive"
                                disabled
                            />
                        </div>
                        <div class="profile-btn" v-if="isSuperAdmin">
                            <btn-vue
                                :type="'primary-btn--blue-shadow '"
                                @click="updateDetails"
                                >Save</btn-vue
                            >
                        </div>
                    </template>
                </div>
                <div class="profile-notes-block">
                    <h4 class="profile-notes-block-title">Clinician Notes</h4>
                    <div class="scroll-notes-block">
                        <div
                            v-for="(note, index) in profileDetails?.notes"
                            :key="index"
                        >
                            <label class="notes-block-label">{{
                                note.noteText
                            }}</label>
                            <div class="notes-row">
                                <span class="notes-text">{{
                                    note.addedBy
                                }}</span>
                                <span class="notes-text">{{
                                    moment(note.createdOn).format(
                                        "DD/MM/YYYY HH:mm"
                                    )
                                }}</span>
                            </div>
                        </div>
                        <div
                            v-if="!profileDetails?.notes?.length"
                            class="profile-empty-list"
                        >
                            <h3>No notes</h3>
                            Sorry, this clinician doesn't have any notes yet
                        </div>
                    </div>
                    <span class="notes-total"
                        >{{ profileDetails?.notes?.length }} Notes</span
                    >
                </div>
                <div class="profile-clinics-block">
                    <h4 class="profile-notes-block-title">
                        Associated Clinics
                    </h4>
                    <div
                        v-if="profileDetails?.clinics?.length"
                        class="clinics-rows-header"
                    >
                        <span> Clinic Name </span>
                        <div class="clinics-rows-header-right">
                            <span> Remote </span>
                            <span> Active </span>
                        </div>
                    </div>
                    <div class="scroll-notes-block">
                        <div
                            v-for="(clinic, index) in profileDetails?.clinics"
                            :key="index"
                        >
                            <div class="clinics-row">
                                <edit-btn
                                    @click="
                                        router.push({
                                            name: 'clinic-profile',
                                            query: { id: clinic?.codedId },
                                        })
                                    "
                                />
                                <span class="clinics-text">{{
                                    clinic.name
                                }}</span>
                                <input
                                    class="profile-checkbox"
                                    type="checkbox"
                                    :checked="clinic.isRemote"
                                    disabled
                                />
                                <input
                                    class="profile-checkbox"
                                    type="checkbox"
                                    :checked="clinic.isActive"
                                    disabled
                                />
                            </div>
                        </div>
                        <div
                            v-if="!profileDetails?.clinics?.length"
                            class="profile-empty-list"
                        >
                            <h3>No Clinics</h3>
                            Sorry, this clinician doesn't have any associated
                            clinics yet
                        </div>
                    </div>
                    <span class="notes-total"
                        >{{ profileDetails?.clinics?.length }} Clinics</span
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SelectVue from "../../components/SelectVue/SelectVue.vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import BtnVue from "../../components/BtnVue.vue";
import InputVue from "../../components/InputVue/InputVue.vue";
import RadioBtns from "../../components/InputVue/RadioBtns.vue";
import EditBtn from "../../components/EditBtn.vue";
import ScheduleBreadcrumbs from "../../components/ScheduleBreadcrumbs.vue";
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import * as moment from "moment";
import DropdownVue from "../../components/DropdownVue/DropdownVue.vue";
import LoaderVue from "@/components/LoaderVue.vue";
import { toast } from "vue3-toastify";
import ClinicianSidebar from "../../components/ClinicianSidebar.vue";
export default {
    components: {
        BtnVue,
        InputVue,
        RadioBtns,
        EditBtn,
        ScheduleBreadcrumbs,
        SelectVue,
        DropdownVue,
        LoaderVue,
        ClinicianSidebar,
    },
    setup() {
        const internalLink = process.env.VUE_APP_INTERNAL_PORTAL_URL;
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const id = ref(route.query.id);
        const isLoading = ref(false);
        const profileDetails = computed(() => {
            return store.getters["clinicians/clinicianDetails"];
        });
        const cliniciansList = computed(() => {
            return store.getters["clinicians/cliniciansListDetails"];
        });
        const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);
        const isEmployed = ref(profileDetails?.value?.isEmployed);
        const isSelfManageAvailability = ref(
            profileDetails?.value?.selfManagedAvailability
        );

        const hcpType = ["CBT Therapist", "Psychologist", "Counsellor"];
        const hcpTypeChoosen = ref(
            hcpType[profileDetails?.value?.clinicianType]
        );

        watch(
            profileDetails,
            () => {
                hcpTypeChoosen.value =
                    hcpType[profileDetails?.value?.clinicianType];
                isEmployed.value = profileDetails?.value?.isEmployed;
                isSelfManageAvailability.value =
                    profileDetails?.value?.selfManagedAvailability;

                id.value = profileDetails.value?.id;
            },
            {
                deep: true,
            }
        );
        onMounted(async () => {
            isLoading.value = true;

            if (!id.value) {
                const localId = localStorage.getItem("clinicianId");
                await store.dispatch("clinicians/setClinicianDetails", localId);
            } else {
                await store.dispatch(
                    "clinicians/setClinicianDetails",
                    id.value
                );
            }

            if(profileDetails.value?.g4PAccountStatus === 'NotRegistered') {
                router.push({name: 'clinicians-account'})
            }

            isLoading.value = false;

            await store.dispatch("clinicians/cliniciansListDetails");

            router.replace({
                query: {
                    id: undefined,
                },
            });
        });
        onBeforeRouteLeave((to) => {
            if (
                hcpTypeChoosen.value !==
                    hcpType[profileDetails?.value?.clinicianType] ||
                isEmployed.value !== profileDetails?.value?.isEmployed ||
                isSelfManageAvailability.value !==
                    profileDetails?.value?.selfManagedAvailability
            ) {
                toast.warning("Your changes have not been saved");
            }
            if (
                to.path === "/clinicians/account" ||
                to.path === "/clinicians/schedule"
            ) {
                store.commit(
                    "clinicians/setClinicianDetails",
                    profileDetails?.value
                );
            } else {
                store.commit("clinicians/setClinicianDetails", {});
                console.log("clear store");
            }
        });

        const copyText = (text, type) => {
            navigator.clipboard.writeText(text);
            toast.success(type + " Copied to Clipboard");
        };

        const setHcpType = (type) => {
            hcpTypeChoosen.value = type;
        };

        const setChecked = (title) => {
            isEmployed.value = title === "Y";
        };
        const setCheckedAvailability = (title) => {
            isSelfManageAvailability.value = title === "Y";
        };

        const updateDetails = () => {
            store.dispatch("clinicians/updateProfileDetails", {
                clinicianId: localStorage.getItem("clinicianId"),
                isEmployed: isEmployed.value,
                clinicianType:
                    hcpTypeChoosen.value === "Not Set"
                        ? ""
                        : hcpTypeChoosen?.value?.replace(" ", ""),
                SelfManagedAvailability: isSelfManageAvailability.value,
            });
        };

        const setIsLoading = async (id) => {
            isLoading.value = true;
            await store.dispatch("clinicians/setClinicianDetails", id);
            isLoading.value = false;
        };

        return {
            setIsLoading,
            copyText,
            hcpType,
            setHcpType,
            profileDetails,
            updateDetails,
            setChecked,
            hcpTypeChoosen,
            moment,
            isLoading,
            router,
            id,
            cliniciansList,
            internalLink,
            isSuperAdmin,
            setCheckedAvailability,
            isSelfManageAvailability,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/ClinitianProfile.scss";
</style>

