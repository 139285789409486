<template>
  <common-layout>
    <div class="home-wrapper">
      <h4 class="select-label">Availability Added</h4>
      <select-vue
        :selectedDefault="selectedOption"
        :options="options"
        :setSelectedAction="setTime"
      ></select-vue>
      <div v-if="isLoading">
        <loader-vue />
      </div>
      <appointments-added
        v-else
        :appointmentList="slotList"
      ></appointments-added>
      <div class="cards-container">
        <card-vue
          @click="router.push('/availability')"
          id="b6dfa8a3-364a-4879-bf5b-9bbdcae19b73"
          >Availability Search</card-vue
        >
        <card-vue
          id="0c7bbf25-3516-4ec0-be7d-0fac41e90565"
          :class="`card-container${isAdmin ? '' : '--disabled'}`"
          @click="isAdmin ? router.push('/clinicians') : undefined"
          >Clinicians</card-vue
        >
        <card-vue
          id="dea91173-78c3-46ee-8454-9f25cd5d214b"
          :class="`card-container${isAdmin ? '' : '--disabled'}`"
          @click="isAdmin ? router.push('/clinics') : undefined"
          >Clinics</card-vue
        >
        <card-vue
          id="1b2dd03e-8f30-41ec-b4f8-b5f358a81682"
          :class="`card-container${isSuperAdmin ? '' : '--disabled'}`"
          @click="isSuperAdmin ? router.push('/report') : undefined"
          >Reports</card-vue
        >
      </div>
    </div>
    <modal-session-timeout />
  </common-layout>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "@vue/runtime-core";
import { useRouter } from "vue-router";
import AppointmentsAdded from "../components/AppointmentsAdded.vue";
import CardVue from "../components/CardVue.vue";
import ModalSessionTimeout from "../components/ModalVue/ModalSessionTimeout.vue";
import SelectVue from "../components/SelectVue/SelectVue.vue";
import CommonLayout from "../views/CommonLayout.vue";
import { useStore } from "vuex";
import LoaderVue from "../components/LoaderVue.vue";
export default {
  components: {
    SelectVue,
    CommonLayout,
    AppointmentsAdded,
    CardVue,
    ModalSessionTimeout,
    LoaderVue,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);
    const selectedOption = ref("All");
    const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);
    const isAdmin = computed(() => store.getters["auth/isAdmin"]);
    const options = ["All", "Last 24hrs", "Last week", "Last month"];
    const summarySlots = computed(
      () => store.getters["availability/summarySlots"]
    );
    const slotList = ref([]);

    watch(summarySlots, () => {
      slotList.value = [
        {
          title: "Available Slots",
          desc: summarySlots.value?.totalAvailabilitySlotCount,
        },
        {
          title: "Employed",
          desc: summarySlots.value?.employedAvailabilitySlotCount,
        },
        {
          title: "Network",
          desc: summarySlots.value?.networkAvailabilitySlotCount,
        },
        {
          title: "Psychologist",
          desc: summarySlots.value?.psychologistAvailabilitySlotCount,
        },
        {
          title: "CBT Therapist",
          desc: summarySlots.value?.cbtTherapistAvailabilitySlotCount,
        },
        {
          title: "Counsellor",
          desc: summarySlots.value?.counsellorAvailabilitySlotCount,
        },
      ];
    });

    const loadFromServer = async (timeStart) => {
      isLoading.value = true;
      await store.dispatch("availability/getSummarySlots", timeStart);
      isLoading.value = false;
    };
    const setTime = (option) => {
      selectedOption.value = option;
      let time = "all";
      if (option === "Last 24hrs") {
        time = "last24Hours";
      }
      if (option === "Last week") {
        time = "lastWeek";
      }
      if (option === "Last month") {
        time = "lastMonth";
      }
      loadFromServer(time);
    };

    onMounted(() => {
      loadFromServer("all");
    });

    onBeforeUnmount(() => {
      store.commit("availability/setSummarySlots", {});
    });

    return {
      options,
      slotList,
      router,
      isSuperAdmin,
      isAdmin,
      isLoading,
      setTime,
      selectedOption,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Home.scss";
</style>