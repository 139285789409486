<template>
  <input
    type="date"
    class="date-container date-container--square"
    @change="setDate($event.target.value)"
    :value="date"
    :min="minDate"
    :max="maxDate"
  />
</template>

<script>
export default {
  props: {
    setDate: Function,
    date: String,
    minDate: String,
    maxDate: String,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/input.scss";
</style>