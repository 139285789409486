<template>
  <modal-layout v-if="isOpen" class="session-modal-container">
    <div class="modal-block">
      <div class="modal-title-wrapper">
        <img src="@/assets/icons/icon-info.svg" alt="icon" />
        <h3 class="modal-title">Session Inactive</h3>
      </div>
      <modal-separator />
      <div class="modal-content">
        <p class="modal-description">
          Your session is about to time out due to inactivity and any unsaved
          information will be lost.
        </p>
        <p class="modal-description">
          To stay signed in please select ‘Stay logged in’ or you will be logged
          out automatically.
        </p>
        <p class="modal-description--bold">
          Time left: {{ timerCount }} seconds
        </p>
      </div>
      <modal-separator />
      <div class="btn-block">
        <btn-vue
          id="e1409048-9cc2-4dc9-8f08-0e360cedd68b"
          :type="'secondary-btn'"
          @click="logout"
          >Logout</btn-vue
        >
        <btn-vue
          id="c09497a2-8cef-465c-a3db-53ba8350e680"
          :type="'primary-btn'"
          @click="stay"
          >Stay logged in</btn-vue
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
const TIMER_COUNTER = 60;

const envVar = parseInt(process.env.VUE_APP_SESSION_TIMEOUT_DELAY);
const IDLE_TIME_DELAY = envVar - TIMER_COUNTER;
let useSessionTimeOut;

import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { useIdle, useIntervalFn, useTimestamp } from "@vueuse/core";
import { computed, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useMsal } from "@/hooks/useMsal";

export default {
  components: { ModalLayout, BtnVue, ModalSeparator },
  setup() {
    const { instance } = useMsal();
    const timerCount = ref(TIMER_COUNTER);
    const modalRef = ref();
    const { pause, resume } = useIntervalFn(() => {
      timerCount.value--;
    }, 1000);
    pause();
    const { lastActive } = useIdle(60000);
    const store = useStore();
    const router = useRouter();
    const now = useTimestamp();
    const idledFor = computed(() =>
      Math.floor((now.value - lastActive.value) / 1000)
    );

    const showModal = computed(() => idledFor.value > IDLE_TIME_DELAY);
    const homeAccountId = store.getters["auth/homeAccountId"];
    const currentAccount = instance.getAccountByHomeId(homeAccountId);
    const isOpen = ref(false);
    watch(showModal, () => {
      if (showModal.value) {
        // modalRef.value.style.display = 'flex';
        isOpen.value = true;
        resume();
      }
    });
    const logout = () => {
      instance.logout({
        logoutHint: currentAccount.idTokenClaims.login_hint,
        mainWindowRedirectUri: process.env.VUE_APP_BASE_APP_URL,
      });
      pause();
      store.commit("auth/setToken", undefined);
      store.commit("auth/setUsername", undefined);
      sessionStorage.clear();
      isOpen.value = false;

      router.push("/login");
    };
    const stay = () => {
      pause();
      timerCount.value = TIMER_COUNTER;
      isOpen.value = false;
      showModal.value = false;
      // modalRef.value.style.display = 'none';
      clearTimeout(useSessionTimeOut);
    };
    watch(timerCount, () => {
      if (!timerCount.value) {
        instance.logout({
          logoutHint: currentAccount.idTokenClaims.login_hint,
          mainWindowRedirectUri: process.env.VUE_APP_BASE_APP_URL,
        });
      }
    });
    onMounted(() => {
      // const modal = document.querySelector('.session-modal-container');
      // modalRef.value = modal;
      window.addEventListener("blur", () => {
        useSessionTimeOut = setTimeout(() => {
          showModal.value = true;
          timerCount.value = TIMER_COUNTER;
          // modal.style.display = 'flex';
          isOpen.value = true;
          resume();
        }, IDLE_TIME_DELAY * 1000);
      });
      window.addEventListener("focus", () => {
        clearTimeout(useSessionTimeOut);
      });
    });
    return { timerCount, isOpen, logout, stay, modalRef };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>