<template>
    <modal-layout v-if="isOpen">
        <div class="modal-block" ref="target">
            <div class="modal-closable-title-wrapper">
                <div class="modal-title-wrapper">
                    <img
                        src="@/assets/icons/icon-calendar-with-pen.svg"
                        alt="icon"
                    />
                    <h3 class="modal-title">Edit Availability slot</h3>
                </div>
                <div
                    class="modal-close-wrapper"
                    @click="$emit('closeEditAvailibility')"
                >
                    <img src="@/assets/icons/icon-close.svg" alt="icon" />
                </div>
            </div>

            <modal-separator />
            <div class="modal-content">
                <div class="modal-content-row">
                    <label class="modal-content-row-label"
                        >Edit your availability</label
                    >
                </div>
                <div class="modal-content-row">
                    <label class="modal-content-row-label">Location</label>
                    <search-select
                        id="eff696f7-df50-4445-8a95-f4870b312ada"
                        :selectedDefault="location"
                        :options="locations"
                        @setSelectedAction="setLocationAction"
                        :style="{ zIndex: 6 }"
                    ></search-select>
                </div>
                <div class="modal-content-row modal-content-row--datetime">
                    <div>
                        <label class="modal-content-row-label">Date</label>
                        <date-vue
                            id="2318d6a1-31d0-440e-8c43-64434a4991b9"
                            :date="date"
                            :setDate="setDate"
                            :minDate="moment().format('yyyy-MM-DD')"
                        />
                    </div>
                    <div>
                        <label class="modal-content-row-label">From</label>
                        <custom-time-vue
                            id="ab32fd5a-d98b-43d3-b3a9-ba524512d562"
                            :time="timeStartFormatted"
                            @setTime="setStartDate"
                        />
                    </div>
                    <div>
                        <label class="modal-content-row-label">To</label>
                        <custom-time-vue
                            id="0dc9c6a2-ddce-42e1-a6d0-a384a077ab46"
                            :time="timeEndFormatted"
                            @setTime="setEndDate"
                            :minDate="timeStartFormatted"
                        />
                    </div>
                </div>
                <div class="modal-content-row">
                    <label class="modal-content-row-label">Recurrence</label>
                    <select-vue
                        id="9cff775c-d536-467e-bfb9-320a0928dd0a"
                        :selectedDefault="selectedRecurrence"
                        :options="recurrence"
                        :setSelectedAction="setRecurrenceAction"
                    ></select-vue>
                </div>
                <div
                    v-if="selectedRecurrence !== 'Does not repeat'"
                    class="modal-content-row modal-content-row--recurrence"
                >
                    <div>
                        <label class="modal-content-row-label"
                            >Recurrence type</label
                        >
                        <select-vue
                            id="85624c6e-02f2-4509-8751-efd3c683db53"
                            :selectedDefault="selectedRecurrenceType"
                            :options="recurrenceType"
                            :setSelectedAction="setRecurrenceType"
                        ></select-vue>
                    </div>
                    <div v-if="selectedRecurrenceType !== 'Repeats until date'">
                        <label class="modal-content-row-label"
                            >Occurrences</label
                        >
                        <input-vue
                            id="b3f42646-7b0d-41e1-a99b-6a826a0710d5"
                            @setText="setOccurences"
                            :value="occurences"
                            :type="'number'"
                            @blurAction="clearNumber"
                        />
                    </div>
                    <div v-else>
                        <label class="modal-content-row-label">End date</label>
                        <date-vue
                            id="2841764d-a42f-4b78-aa42-32edc5280164"
                            :date="endDate"
                            :setDate="setEndRequestDate"
                            :minDate="moment(date).format('yyyy-MM-DD')"
                        />
                    </div>
                </div>
                <div class="btn-delete-block edit-availability-delete-block">
                    <btn-vue
                        id="d077c6c9-4aee-44e1-8576-e20070936765"
                        :type="'primary-btn'"
                        @click="deleteAvailibillity"
                        >Delete availability</btn-vue
                    >

                    <div>
                        <label class="modal-content-row-label"
                            >Edit slot type</label
                        >
                        <select-vue
                            style="z-index: 1"
                            id="1a1dcd1b-d339-419c-b18c-4e871ed591e0"
                            :selectedDefault="editSlotType"
                            :options="[
                                'Edit this slot',
                                'Edit this and future slots',
                            ]"
                            :setSelectedAction="setEditSlotType"
                        ></select-vue>
                    </div>
                </div>
            </div>
            <modal-separator />
            <div class="btn-block">
                <btn-vue
                    id="e1409048-9cc2-4dc9-8f08-0e360cedd68b"
                    :type="'secondary-btn'"
                    @click="$emit('closeEditAvailibility')"
                    >Cancel</btn-vue
                >
                <btn-vue
                    id="c09497a2-8cef-465c-a3db-53ba8350e680"
                    :type="'primary-btn--blue'"
                    @click="sendEditAvailibillityRequest"
                    >Save</btn-vue
                >
            </div>
        </div>
    </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import DateVue from "../InputVue/DateVue.vue";
import InputVue from "../InputVue/InputVue.vue";
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import SelectVue from "../SelectVue/SelectVue.vue";
import SearchSelect from "./../SelectVue/SearchSelect.vue";
import moment from "moment";
import { toast } from "vue3-toastify";
import { onClickOutside } from "@vueuse/core";
import { API_DATE_LOCAL_FORMAT as API_DATE_FORMAT } from "../../pages/Clinicians/CliniciansSchedulePage.vue";
import CustomTimeVue from "../InputVue/CustomTimeVue.vue";
// import TimeVue from "../InputVue/TimeVue.vue";

export default {
    components: {
        ModalLayout,
        BtnVue,
        ModalSeparator,
        SelectVue,
        SearchSelect,
        DateVue,
        InputVue,
        CustomTimeVue,
    },
    props: {
        isOpen: Boolean,
        id: String,
        availibillityInfo: Object,
        payloadForDispatch: Object,
    },
    emits: {
        closeEditAvailibility: Function,
    },
    setup(props, context) {
        const store = useStore();
        const date = ref(moment().format("yyyy-MM-DD"));
        const endDate = ref();
        const { availibillityInfo, payloadForDispatch } = toRefs(props);
        const timeStartFormatted = ref();
        const timeEndFormatted = ref();
        const target = ref();
        const editSlotType = ref("Edit this slot");
        onClickOutside(target, () => context.emit("closeEditAvailibility"));

        const setEndDate = (date) => {
            timeEndFormatted.value = date;
        };

        const setStartDate = (date) => {
            timeStartFormatted.value = date;

            timeEndFormatted.value = moment()
                .set("hours", date?.split(":")?.[0])
                .set("minutes", date?.split(":")?.[1])
                .add(1, "hour")
                .format("HH:mm");
        };

        const recurrence = ref([
            "Does not repeat",
            "Repeats weekly on a " + moment(date).format("dddd"),
            "Repeats monthly",
        ]);
        const occurences = ref(null);
        const recurrenceType = ref([
            "Number of occurrences",
            "Repeats until date",
        ]);
        const selectedRecurrence = ref("Does not repeat");
        const selectedRecurrenceType = ref("Number of occurrences");
        const location = ref("Please choose a Clinic");
        const locations = ref([]);
        const timeEnd = ref(new Date());
        const clinics = computed(() => {
            return store.getters["clinicians/associatedClinics"];
        });
        watch(date, () => {
            recurrence.value = [
                "Does not repeat",
                "Repeats weekly on a " + moment(date.value).format("dddd"),
                "Repeats monthly",
            ];
        });
        watch([selectedRecurrenceType, selectedRecurrence], () => {
            if (selectedRecurrence.value === "Does not repeat") {
                occurences.value = null;
                endDate.value = "";
            }
            if (selectedRecurrenceType.value === "Repeats until date") {
                occurences.value = null;
            }
            if (selectedRecurrenceType.value === "Number of occurrences") {
                endDate.value = "";
            }
            console.log(endDate.value, "endDate.value");
        });
        watch([clinics, availibillityInfo], () => {
            locations.value = clinics.value?.map((data) => {
                if (availibillityInfo.value?.clinicId === data.codedId) {
                    location.value = data.name;
                }
                return data.name;
            });
            if (clinics.value.length === 1) {
                location.value = clinics.value[0]?.name;
            }
        });
        watch(
            availibillityInfo,
            () => {
                date.value = moment(availibillityInfo.value.startDate).format(
                    "yyyy-MM-DD"
                );
                timeStartFormatted.value = moment(
                    availibillityInfo.value.startDate
                ).format("HH:mm");
                timeEndFormatted.value = moment(
                    availibillityInfo.value.endDate
                ).format("HH:mm");
                if (
                    availibillityInfo.value?.recurrenceFrequency === "Monthly"
                ) {
                    selectedRecurrence.value = "Repeats monthly";
                }
                if (availibillityInfo.value?.recurrenceFrequency === "None") {
                    selectedRecurrence.value = "Does not repeat";
                }
                if (availibillityInfo.value?.recurrenceFrequency === "Weekly") {
                    selectedRecurrence.value =
                        "Repeats weekly on a " +
                        moment(availibillityInfo.value?.startDate).format(
                            "dddd"
                        );
                }
                if (availibillityInfo.value?.recurrenceCount) {
                    occurences.value = availibillityInfo.value?.recurrenceCount;
                    selectedRecurrenceType.value = "Number of occurrences";
                } else {
                    if (availibillityInfo.value.seriesEndDate) {
                        selectedRecurrenceType.value = "Repeats until date";
                        endDate.value = moment(
                            availibillityInfo.value.seriesEndDate
                        ).format("yyyy-MM-DD");
                    }
                }
            },
            {
                deep: true,
            }
        );

        onMounted(() => {
            store.dispatch("clinicians/setAssociatedClinics", props.id);
        });
        const setDate = (data) => {
            date.value = data;
        };
        const setEndRequestDate = (data) => {
            endDate.value = data;
        };
        const setLocationAction = (data) => {
            location.value = data;
        };
        const setRecurrenceAction = (data) => {
            selectedRecurrence.value = data;
        };
        const setRecurrenceType = (data) => {
            selectedRecurrenceType.value = data;
        };
        const setOccurences = (data) => {
            occurences.value = data;
        };

        const sendEditAvailibillityRequest = async () => {
            const timeStart = moment(timeStartFormatted.value, "HH:mm");
            const timeEnd = moment(timeEndFormatted.value, "HH:mm");

            if (timeStart.isAfter(timeEnd)) {
                toast.error("Time from can't be before time to");
                return;
            }
            if (timeStartFormatted.value == timeEndFormatted.value) {
                toast.error("Time from can't be the same as time to");
                return;
            }
            if (location.value === "Please choose a Clinic") {
                toast.error("You must specify a clinic");
                return;
            }

            let recurrenceFrequency = availibillityInfo.value;
            if (selectedRecurrence.value?.includes("monthly")) {
                recurrenceFrequency = "monthly";
            }

            if (selectedRecurrence.value?.includes("weekly")) {
                recurrenceFrequency = "weekly";
            }
            if (selectedRecurrence.value?.includes("not repeat")) {
                recurrenceFrequency = "none";
            }

            let payload = {
                previousStartDate: moment(availibillityInfo.value?.startDate),
                series: availibillityInfo.value.series,
                clinicianId: props.id,
                clinicId: clinics.value.find(
                    (data) => data.name === location.value
                )?.codedId,
                startDate: moment(date.value).format(API_DATE_FORMAT),
                startTime: moment(timeStartFormatted.value, "HH:mm").format(
                    "HH:mm"
                ),
                endTime: moment(timeEndFormatted.value, "HH:mm").format(
                    "HH:mm"
                ),
                recurrenceFrequency,
                recurrenceCount: null,
            };

            if (editSlotType.value === "Edit this slot") {
                payload = {
                    ...payload,
                    endDate: moment(date.value).format(API_DATE_FORMAT),
                    recurrenceCount: null,
                    recurrenceFrequency: "none",
                };
            } else {
                if (endDate.value) {
                    payload = {
                        ...payload,
                        seriesEndDate: moment(endDate.value).format(
                            API_DATE_FORMAT
                        ),
                        endDate: moment(endDate.value).format(API_DATE_FORMAT),
                    };
                } else {
                    payload = {
                        ...payload,
                        recurrenceCount: occurences.value,
                    };
                }
            }
            const resp = await store.dispatch("clinicians/editAvailibillity", {
                ...payload,
                payloadForDispatch: payloadForDispatch.value,
            });

            if (resp != 409 && resp != 400) {
                context.emit("closeEditAvailibility", true);
            }
        };
        const deleteAvailibillity = async () => {
            const status = await store.dispatch(
                "clinicians/deleteAvailibillity",
                {
                    ...availibillityInfo.value,
                    startTime: moment(timeStartFormatted.value, "HH:mm")
                        .utcOffset("+00:00")
                        .format("HH:mm"),
                    endTime: moment(timeEndFormatted.value, "HH:mm")
                        .utcOffset("+00:00")
                        .format("HH:mm"),
                    clinicianId: props.id,
                    recurrenceFrequency: "none",
                    payloadForDispatch: payloadForDispatch.value,
                }
            );
            if (status === 200) {
                context.emit("closeEditAvailibility", true);
            } else {
                if (status === 409) {
                    toast.error(
                        "Fail to delete Availability slot. There is a scheduled appointment during the availability you want to delete"
                    );
                }
            }
        };
        const setEditSlotType = (type) => {
            editSlotType.value = type;
        };
        const clearNumber = () => {
            occurences.value = Number(occurences.value);
        };
        return {
            target,
            date,
            timeEnd,
            setLocationAction,
            recurrence,
            setRecurrenceAction,
            selectedRecurrence,
            recurrenceType,
            setRecurrenceType,
            selectedRecurrenceType,
            occurences,
            setOccurences,
            clearNumber,
            locations,
            location,
            moment,
            timeStartFormatted,
            timeEndFormatted,
            setStartDate,
            setEndDate,
            endDate,
            setDate,
            sendEditAvailibillityRequest,
            setEndRequestDate,
            deleteAvailibillity,
            setEditSlotType,
            editSlotType,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

