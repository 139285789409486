<template>
  <div class="loading-entity" data-v-7722c177="">
    <div
      class="lds-ring"
      data-v-1fa3a520=""
      data-v-7722c177=""
      style="--26774109: #42b883"
    >
      <div data-v-1fa3a520=""></div>
      <div data-v-1fa3a520=""></div>
      <div data-v-1fa3a520=""></div>
      <div data-v-1fa3a520=""></div>
    </div>
  </div>
</template>