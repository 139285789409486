<template>
  <common-layout>
    <div class="report-wrapper">
      <div class="report-filter-wrapper">
        <div class="report-filter-block">
          <div>
            <h4 class="select-label">Search Report Groups</h4>
            <div class="input-container--wrapper">
              <input-vue
                id="a79d78eb-b58d-445d-a97a-d0c74643bb01"
                :placeholder="'enter here'"
                :value="searchReportGroup"
                @setText="setSearchReportGroup"
              />
            </div>
          </div>

          <div class="report-filter-btns">
            <btn-vue
              id="9dc2a09e-17b9-472e-8bb2-7449280ff223"
              :type="'secondary-btn--square'"
              @click="openUploadReport = true"
              >Upload Report Group List</btn-vue
            >
            <btn-vue
              id="77d7d793-af8c-40ba-a845-8328cb5e3385"
              @click="openAddReport = true"
              :type="'secondary-btn--square'"
              >Add Report group</btn-vue
            >
          </div>
        </div>
      </div>
      <table-vue
        class="report-table-wrapper--setup"
        @openEditModal="() => (openEditReport = true)"
        :searchName="searchReportGroup"
      />
    </div>

    <modal-add-report-group
      @closeAddReport="closeAddReport"
      :isOpen="openAddReport"
    />
    <modal-upload-report-group
      @closeuploadList="closeUploadReport"
      :isOpen="openUploadReport"
    />
  </common-layout>
</template>

<script>
import TableVue from "../../components/ReportTableVue.vue";
import CommonLayout from "../../views/CommonLayout.vue";
import InputVue from "../../components/InputVue/InputVue.vue";
import { ref } from "vue";
import BtnVue from "../../components/BtnVue.vue";
import ModalAddReportGroup from "../../components/ModalVue/ModalAddReportGroup.vue";
import ModalUploadReportGroup from "../../components/ModalVue/ModalUploadReportGroup.vue";
export default {
  components: {
    CommonLayout,
    TableVue,
    InputVue,
    BtnVue,
    ModalAddReportGroup,
    ModalUploadReportGroup,
  },
  setup() {
    const openAddReport = ref(false);
    const openEditReport = ref(false);
    const openUploadReport = ref(false);
    const searchReportGroup = ref("");
    const setSearchReportGroup = (name) => {
      searchReportGroup.value = name;
    };
    const closeAddReport = () => {
      openAddReport.value = false;
    };
    const closeUploadReport = () => {
      openUploadReport.value = false;
    };
    return {
      searchReportGroup,
      setSearchReportGroup,
      openAddReport,
      openEditReport,
      closeAddReport,
      openUploadReport,
      closeUploadReport,
    };
  },
};
</script>

<style lang="scss">
</style>
