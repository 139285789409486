<template>
  <!-- eslint-disable -->
  <div>
    <data-table
      v-model:server-options="serverOptions"
      :headers="headers"
      :items="clinicList?.items || []"
      :server-items-length="clinicList?.totalItems"
      :loading="loading"
      :rows-items="[10, 20, 30]"
    >
      <template #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }">
        <button
          class="icon-btn-containner icon-btn-prev"
          :disabled="isFirstPage"
          @click="prevPage"
          role="none"
          aria-label="previous page"
        ></button>
        <button
          class="icon-btn-containner"
          :disabled="isLastPage"
          @click="isFirstPage ? (serverOptions.page = 2) : nextPage()"
          role="none"
          aria-label="next page"
        ></button>
      </template>
      <template #item-name="{ name, codedId }">
        <div class="edit-container">
          <edit-btn
            title="Edit Profile"
            @click="
              router.push({
                name: 'clinic-profile',
                query: { id: codedId },
              })
            "
          />
          {{ name }}
        </div>
      </template>
      <template #item-emailAddress="{ emailAddress }">
        <div class="copy-cell">
          <div
            v-if="emailAddress"
            class="copy-btn"
            @click="copyText(emailAddress, 'Email')"
            title="Copy Email Address"
          >
            <img src="@/assets/icons/icon-copy.svg" alt="icon" />
          </div>
          <a :href="`mailto:${emailAddress}`" title="Send Email">{{
            emailAddress
          }}</a>
        </div>
      </template>
      <template #item-hcpType="{ hcpType: hcpTypeData }">
        {{ hcpType?.[hcpTypeData] }}
      </template>
      <template #item-isEmployed="{ isEmployed }">
        {{ isEmployed ? "Employed" : "Network" }}
      </template>
      <template #item-telephoneNumber="{ telephoneNumber }">
        <div class="copy-cell pr-130">
          <div
            v-if="telephoneNumber"
            class="copy-btn"
            @click="copyText(telephoneNumber, 'Telephone Number')"
            title="Copy Telephone Number"
          >
            <img src="@/assets/icons/icon-copy.svg" alt="icon" />
          </div>
          <a :href="`tel:${telephoneNumber}`" title="Call Telephone Number">{{
            telephoneNumber
          }}</a>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch, toRefs } from "vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import EditBtn from "../components/EditBtn.vue";

export default defineComponent({
  props: {
    searchValue: String,
    searchByTown: Object,
    searchByPostcode: String,
    includeSelected: String,
    statusSelected: Object,
  },
  components: {
    EditBtn,
  },
  setup(props) {
    const {
      searchValue,
      searchByTown,
      searchByPostcode,
      includeSelected,
      statusSelected,
    } = toRefs(props);
    const router = useRouter();
    const store = useStore();
    const clinicList = computed(() => {
      return store.getters["clinics/clinicList"];
    });
    const serverOptions = ref({
      page: 1,
      rowsPerPage: 10,
    });
    const loading = ref(false);
    const loadFromServer = async () => {
      loading.value = true;
      let sortBy = serverOptions?.value?.sortBy;
      if (serverOptions?.value?.sortBy === "emailAddress") {
        sortBy = "email";
      }
      if (serverOptions?.value?.sortBy === "telephoneNumber") {
        sortBy = "telephone";
      }
      await store.dispatch("clinics/setClinicList", {
        pageNumber: serverOptions.value?.page,
        pageSize: serverOptions.value?.rowsPerPage,
        status: statusSelected.value?.toLowerCase(),
        searchByName: searchValue.value,
        searchByTown: searchByTown.value,
        searchByPostcode: searchByPostcode.value,
        sortBy: sortBy || "name",
        sortDescending: serverOptions.value?.sortType === "desc",
        clinicTypeFilter: includeSelected.value
          ?.replace("-", "")
          ?.toLowerCase(),
      });

      loading.value = false;
    };

    watch(
      serverOptions,
      () => {
        loadFromServer();
      },
      { deep: true }
    );

    watch(
      [
        searchValue,
        searchByTown,
        searchByPostcode,
        includeSelected,
        statusSelected,
      ],
      () => {
        serverOptions.value = { ...serverOptions.value, page: 1 };
      },
      { deep: true }
    );

    onMounted(() => {
      loadFromServer();
    });

    const headers = [
      { text: "Clinic Name", value: "name", sortable: true },
      { text: "Clinic Town", value: "town", sortable: true },
      {
        text: "Clinic Postcode",
        value: "postcode",
        sortable: true,
      },
      { text: "Email", value: "emailAddress", sortable: true },
      { text: "Telephone", value: "telephoneNumber", sortable: true },
    ];

    const copyText = (text, type) => {
      navigator.clipboard.writeText(text);
      toast.success(type + " Copied to Clipboard");
    };

    const sortIcon = (column) => {
      if (!column.sortable) return "";
      return column.sortDirection === "asc" ? "fa-sort-up" : "fa-sort-down";
    };

    return {
      headers,
      serverOptions,
      loading,
      sortIcon,
      copyText,
      router,
      clinicList,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

