// import axios from "axios";

export default {
    namespaced: true,
    state() {
        return {
            token: "",
            homeAccountId: "",
            username: "",
            isSuperAdmin: false,
            isAdmin: false,
        };
    },
    mutations: {
        setUsername(state, username) {
            state.username = username;
        },
        setToken(state, token) {
            state.token = token;
        },
        setSuperAdmin(state, isSuperAdmin) {
            state.isSuperAdmin = isSuperAdmin;
        },
        setAdmin(state, isAdmin) {
            state.isAdmin = isAdmin;
        },
        setHomeAccountId(state, homeAccountId) {
            state.homeAccountId = homeAccountId;
        },
    },
    getters: {
        isSuperAdmin(state) {
            return state.isSuperAdmin;
        },
        isAdmin(state) {
            return state.isAdmin;
        },
        isAuthenticated(state) {
            return !!state.token;
        },
        username(state) {
            return state.username;
        },
        homeAccountId(state) {
            return state.homeAccountId;
        },
    },
    actions: {
        setToken({ commit }, payload) {
            commit("setToken", payload);
        },
        async setSuperAdmin({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                if (rootState.auth.token) {
                    myHeaders.append(
                        "Authorization",
                        "Bearer " + rootState.auth.token
                    );
                } else {
                    myHeaders.append("Authorization", "Bearer " + payload);
                }

                myHeaders.append("Content-Type", "application/json");

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const request = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "/api/Role/itsupport",
                    requestOptions
                );

                const response = await request.status;
                if (response == 200) {
                    commit("setSuperAdmin", true);
                    commit("setAdmin", true);
                } else {
                    try {
                        const request = await fetch(
                            process.env.VUE_APP_BACKEND_BASE_URL +
                                "/api/Role/operations",
                            requestOptions
                        );
                        const response = await request.status;
                        if (response == 200) {
                            commit("setSuperAdmin", true);
                            commit("setAdmin", true);
                        } else {
                            try {
                                const request = await fetch(
                                    process.env.VUE_APP_BACKEND_BASE_URL +
                                        "/api/Role/admin",
                                    requestOptions
                                );
                                const response = await request.status;
                                if (response == 200) {
                                    commit("setAdmin", true);
                                }
                            } catch (error) {
                                console.log(error, "error");
                            }
                        }
                    } catch (error) {
                        console.log(error, "error");
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        setUsername({ commit }, payload) {
            commit("setUsername", payload);
        },
    },
};

