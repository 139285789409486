import { toast } from "vue3-toastify";
export default {
    namespaced: true,
    state() {
        return {
            clinicList: { items: [] },
            clinicDetails: {},
            specialisations: [],
            associatedClinics: [],
            clinicsListDetails: [],
        };
    },
    mutations: {
        setClinicList(state, payload) {
            state.clinicList = payload;
        },
        setClinicDetails(state, payload) {
            state.clinicDetails = payload;
        },
        setClinicsListDetails(state, payload) {
            state.clinicsListDetails = payload;
        },
    },
    getters: {
        clinicList(state) {
            return state.clinicList;
        },
        clinicDetails(state) {
            return state.clinicDetails;
        },
        clinicsListDetails(state) {
            return state.clinicsListDetails;
        },
    },
    actions: {
        setToken({ commit }, payload) {
            commit("setToken", payload);
        },

        async setClinicList({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL + "api/clinics",
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? [] : response.json()
                );
                commit("setClinicList", response);
            } catch (error) {
                console.log(error);
            }
        },
        async setClinicDetails({ commit, rootState }, payload) {
            localStorage.setItem("clinicId", payload);
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinics/details/" +
                        payload,
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? {} : response.json()
                );
                if (response) {
                    try {
                        const responseNotes = await fetch(
                            process.env.VUE_APP_BACKEND_BASE_URL +
                                "api/clinics/notes/" +
                                payload,
                            requestOptions
                        ).then((response) =>
                            response.status === 204 ? [] : response.json()
                        );
                        if (responseNotes) {
                            try {
                                const responseClinicians = await fetch(
                                    process.env.VUE_APP_BACKEND_BASE_URL +
                                        "api/clinicians?clinicId=" +
                                        payload,
                                    requestOptions
                                ).then((response) =>
                                    response.status === 204
                                        ? []
                                        : response.json()
                                );
                                commit("setClinicDetails", {
                                    ...response,
                                    notes: responseNotes,
                                    clinicians: responseClinicians,
                                });
                            } catch (error) {
                                console.log(error, "error");
                            }
                        } else {
                            commit("setClinicDetails", response);
                        }
                    } catch (error) {
                        commit("setClinicDetails", response);
                        console.log(error, "error");
                    }
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        async updateClinicDetails({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: raw,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/clinics/profile",
                    requestOptions
                ).then(async (response) =>
                    response.status === 400
                        ? { error: await response.json() }
                        : response.json()
                );
                if (response.error) {
                    toast.error(response.error?.ClinicianType?.[0]);
                } else {
                    toast.success("Changes have been saved successfully");
                }
            } catch (error) {
                toast.error("Your changes have not been saved");
                console.log(error, "error", commit);
            }
        },
        async setClinicsListDetails({ commit, rootState }) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify({
                        maxNumberOfClinicians: 10,
                        searchByName: "",
                        sortDescendingByName: false,
                    }),
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL + "api/clinics/list",
                    requestOptions
                ).then(async (response) =>
                    response.status === 204 ? [] : response.json()
                );
                if (response) {
                    commit("setClinicsListDetails", response);
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
    },
};

