<template>
    <!-- eslint-disable -->
    <div v-if="totalItems && !loading">
        <data-table
            :headers="headers"
            :items="careRecords || []"
            :loading="loading"
            :rows-per-page="totalItems"
            :hide-footer="true"
            :sortBy="sortBy"
        >
            <template
                #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
            </template>
            <template #item-action="{ caseId }">
                <div class="btn-enable-wrapper" title="Restore access">
                    <div
                        class="btn-enable"
                        :style="totalItems > 15 ? 'margin-left: 16px' : ''"
                        @click="enableAccess(caseId)"
                    >
                        <img src="@/assets/icons/icon-enable.svg" alt="icon" />
                    </div>
                </div>
            </template>
            <template #item-accessDeniedOn="{ accessDeniedOn }">
                {{ moment(accessDeniedOn).format("DD/MM/YYYY") }}
            </template>
        </data-table>

        <div class="total-items">
            {{ totalItems }} record{{ totalItems > 1 ? "s" : "" }}
        </div>
    </div>
    <div v-else style="margin: 0 auto">
        <loader-vue v-if="loading" />
        <h3 v-else class="tab-access--no-data">There is no data</h3>
    </div>
</template>

<script>
import moment from "moment";
import { computed, defineComponent, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import LoaderVue from "./LoaderVue.vue";

export default defineComponent({
    props: { loading: Boolean, clinicianId: String, selectedDate: Array },
    components: { LoaderVue },
    setup(props) {
        const sortBy = ref("patientSurname");
        const { clinicianId, searchValue, selectedDate } = toRefs(props);
        const router = useRouter();
        const store = useStore();
        const careRecords = computed(() => {
            return store.getters["clinicians/careRecords"];
        });
        const totalItems = computed(() => careRecords.value?.length);

        const headers = [
            { text: "Forename", value: "patientForenames", sortable: true },
            { text: "Surname", value: "patientSurname", sortable: true },
            {
                text: "Care Record Number",
                value: "caseReference",
                sortable: true,
            },
            {
                text: "Removal date",
                value: "accessDeniedOn",
                sortable: true,
            },
            { text: "Action", value: "action" },
        ];

        const enableAccess = async (caseId) => {
            const status = await store.dispatch(
                "clinicians/grantCareRecordsAccess",
                {
                    clinicianId: clinicianId.value,
                    caseId,
                }
            );
            console.log(status);
            if (status !== 200) {
                toast.error("Access can't be granted for this Care record");
            } else {
                toast.success("Access was granted for the next 48 hours");
                await store.dispatch("clinicians/setCareRecords", {
                    clinicianId: clinicianId.value,
                    accessStatus: "blocked",
                    sortBy: "forename",
                    sortDescending: true,
                    searchedValue: searchValue?.value,
                    cancellationStartDate: moment(selectedDate.value[0])
                        .utcOffset(0, true)
                        .format(),
                    cancellationEndDate: moment(selectedDate.value[1])
                        .utcOffset(0, true)
                        .format(),
                });
            }
        };

        return {
            headers,
            router,
            careRecords,
            totalItems,
            moment,
            sortBy,
            enableAccess,
        };
    },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

