<template>
  <modal-layout v-if="isOpen">
    <div class="modal-block" ref="target">
      <div class="modal-closable-title-wrapper">
        <div class="modal-title-wrapper">
          <h3 class="modal-title">Add Report Group</h3>
        </div>

        <div class="modal-close-wrapper" @click="closeWithoutSaving()">
          <img src="@/assets/icons/icon-close.svg" alt="icon" />
        </div>
      </div>

      <modal-separator />

      <div class="add-report-group-modal-block">
        <label for="">Name</label>
        <input-vue
          @setText="(text) => (name = text)"
          :placeholder="'Enter name here'"
        />
      </div>
      <modal-separator />
      <div class="btn-block">
        <btn-vue
          id="708f1733-0986-4a2f-81ec-b4e18e8906a1"
          :type="'secondary-btn'"
          @click="closeWithoutSaving"
          >Cancel</btn-vue
        >
        <btn-vue
          id="b354bbaf-43f9-44ec-9629-221816a5d92e"
          :type="'primary-btn--blue'"
          @click="addReportGroup"
          >Add Group</btn-vue
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import InputVue from "../InputVue/InputVue.vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

export default {
  components: {
    ModalLayout,
    BtnVue,
    ModalSeparator,
    InputVue,
  },
  props: {
    isOpen: Boolean,
  },
  emits: {
    closeAddReport: Function,
  },
  setup(_, context) {
    const target = ref();
    const name = ref("");
    const store = useStore();
    const closeWithoutSaving = () => {
      toast.warning("Your changes haven't been saved");
      name.value = "";

      context.emit("closeAddReport");
    };
    onClickOutside(target, () => {
      closeWithoutSaving();
    });

    const addReportGroup = async () => {
      if (name.value?.length) {
        const status = await store.dispatch("report/addReportGroup", {
          name: name.value,
        });
        if (status === 200) {
          context.emit("closeAddReport");
        }
      }
    };

    return {
      closeWithoutSaving,
      addReportGroup,
      target,
      name,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

