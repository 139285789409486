<template>
  <section class="profile-wrapper clinic-profile-wrapper">
    <aside class="profile-sidebar">
      <div class="icon-container">
        <img src="@/assets/icons/icon-clinic-lg.svg" alt="icon" />
      </div>
      <div>
        <h3 class="profile-title">
          {{ profileDetails?.name }}
        </h3>
        <span class="profile-sub-title">{{
          profileDetails?.isRemote ? "Remote" : "In-Person"
        }}</span>
      </div>
      <div class="profile-btns-block">
        <btn-vue :type="'nav-main-btn'">Profile</btn-vue>
      </div>
      <a
        class="link-wrapper"
        :href="internalLink + '#/hcps/clinic/' + id"
        target="_blank"
        ><btn-vue :type="'secondary-btn--square'"
          >view clinic record</btn-vue
        ></a
      >
    </aside>
    <div class="layout-container">
      <schedule-breadcrumbs
        :type="'profile-clinic'"
        :cliniciansList="clinicsList"
        :profileName="profileDetails?.name"
        :isClinic="true"
        @setIsLoading="setIsLoading"
      />
      <div class="profile-container">
        <div
          class="profile-info-block"
          :class="{ 'profile-info-block__loading-mask': isLoading }"
        >
          <div v-if="isLoading">
            <loader-vue />
          </div>

          <template v-else>
            <h4 class="profile-container-title">Clinic Profile</h4>

            <div class="info-rows">
              <label for="fd804916-b3e9-4147-b6eb-6337d3fb16d3">Name</label>
              <input-vue
                id="fd804916-b3e9-4147-b6eb-6337d3fb16d3"
                :disabled="true"
                :placeholder="profileDetails?.name"
              />
              <label for="ab08b1fc-cc69-401b-8108-2966014cc4ff">Line 1</label>
              <input-vue
                id="ab08b1fc-cc69-401b-8108-2966014cc4ff"
                :disabled="true"
                :placeholder="profileDetails?.line1"
              />
              <label for="2ad13b6f-2d58-4406-bad8-bbf258df5575">Line 2</label>
              <input-vue
                id="2ad13b6f-2d58-4406-bad8-bbf258df5575"
                :disabled="true"
                :placeholder="profileDetails?.line2"
              />
              <label for="bf3d55e5-2ffa-46ee-9ada-d5c4b11c34e7">Town</label>
              <input-vue
                id="bf3d55e5-2ffa-46ee-9ada-d5c4b11c34e7"
                :disabled="true"
                :placeholder="profileDetails?.town"
              />
              <label for="9917f1cc-dc55-457d-bfe1-05038d0d0433">County</label>
              <input-vue
                id="9917f1cc-dc55-457d-bfe1-05038d0d0433"
                :disabled="true"
                :placeholder="profileDetails?.county"
              />
              <label for="bf3707f8-85d7-45f7-b279-2f2bffac68e3"
                >Postcode
              </label>
              <input-vue
                id="bf3707f8-85d7-45f7-b279-2f2bffac68e3"
                :disabled="true"
                :placeholder="profileDetails?.postcode"
              />

              <label for="30c8b5c1-0fe3-4b9b-85f5-cf45ed4bb2e7">Email</label>
              <div class="copy-input">
                <input-vue
                  id="30c8b5c1-0fe3-4b9b-85f5-cf45ed4bb2e7"
                  :disabled="true"
                  :placeholder="profileDetails?.emailAddress"
                />
                <div
                  class="copy-input-icon"
                  @click="copyText(profileDetails?.emailAddress, 'Email')"
                  title="Copy Email Address"
                >
                  <img src="@/assets/icons/icon-copy.svg" alt="icon" />
                </div>
              </div>
              <label for="2d13b9ab-65c6-42d5-8489-ec4f079a16dc">Tel</label>
              <div class="copy-input">
                <input-vue
                  id="2d13b9ab-65c6-42d5-8489-ec4f079a16dc"
                  :disabled="true"
                  :placeholder="profileDetails?.telephoneNumber"
                />
                <div
                  class="copy-input-icon"
                  @click="
                    copyText(
                      profileDetails?.telephoneNumber,
                      'Telephone Number'
                    )
                  "
                  title="Copy Telephone Number"
                >
                  <img src="@/assets/icons/icon-copy.svg" alt="icon" />
                </div>
              </div>
              <label for="8a4b6131-9cc6-4469-a6ee-04888729cfa8"
                >Reporting</label
              >
              <search-select
                v-if="isSuperAdmin"
                :id="'8a4b6131-9cc6-4469-a6ee-04888729cfa8'"
                :options="['None', ...reportGroups?.map((data) => data?.name)]"
                :selectedDefault="selectedGroup"
                @set-selected-action="setGroup"
              />
              <input-vue
              v-else
                id="a7253f83-3542-4323-99f9-5da77f02898d"
                :disabled="true"
                :placeholder="selectedGroup"
              />
              <label>Remote</label>
              <radio-btns
                :btns="[
                  {
                    title: 'Y',
                    checked: profileDetails?.isRemote,
                    id: '3da17adb-2f7d-423c-bafd-557e9a8d6971',
                  },
                  {
                    title: 'N',
                    checked: !profileDetails?.isRemote,
                    id: '73e632a6-1b68-4974-acd1-d166acd1d937',
                  },
                ]"
                :name="'isRemote'"
                :disabled="true"
                class="profile-radio-group"
              />
              <label style="white-space: nowrap">Accessible Clinic</label>
              <radio-btns
                :disabled="!isSuperAdmin"
                :btns="[
                  {
                    title: 'Y',
                    checked: profileDetails?.isAccessible,
                    id: '583cc351-ac80-466c-8f88-b3dd5acf30b1',
                  },
                  {
                    title: 'N',
                    checked: !profileDetails?.isAccessible,
                    id: '91037c45-2aaa-4b8e-8700-7c39a008f12b',
                  },
                ]"
                :name="'isAccessible'"
                class="profile-radio-group"
                @set-checked="setChecked"
              />
              <label for="aa24ad81-1afb-4296-896f-e7bf182bff70">Active</label>
              <input
                id="aa24ad81-1afb-4296-896f-e7bf182bff70"
                class="profile-checkbox"
                type="checkbox"
                :checked="profileDetails?.isActive"
                disabled
              />
            </div>
            <div class="profile-btn" v-if="isSuperAdmin">
              <btn-vue
                @click="updateDetails"
                :type="'primary-btn--blue-shadow '"
                id="b5eb6393-d306-4cc8-8982-d8857fab1573"
                >Save</btn-vue
              >
            </div>
          </template>
        </div>
        <div class="profile-notes-block">
          <h4 class="profile-notes-block-title">Clinic Notes</h4>
          <div class="scroll-notes-block">
            <div v-for="(note, index) in profileDetails?.notes" :key="index">
              <label class="notes-block-label">{{ note.noteText }}</label>
              <div class="notes-row">
                <span class="notes-text">{{ note.addedBy }}</span>
                <span class="notes-text">{{
                  moment(note.createdOn).format("DD/MM/YYYY HH:mm")
                }}</span>
              </div>
            </div>
            <div
              v-if="!profileDetails?.notes?.length"
              class="profile-empty-list"
            >
              <h3>No notes</h3>
              Sorry, this clinic doesn't have any notes yet
            </div>
          </div>
          <span class="notes-total"
            >{{ profileDetails?.notes?.length }} Notes</span
          >
        </div>
        <div class="profile-clinics-block">
          <h4 class="profile-notes-block-title">Associated Clinicians</h4>
          <div
            v-if="profileDetails?.clinicians?.length"
            class="clinics-rows-header"
          >
            <span> Clinician Name </span>
            <div class="clinics-rows-header-right">
              <span> Employed </span>
              <span> Network </span>
            </div>
          </div>
          <div class="scroll-notes-block">
            <div
              v-for="(clinic, index) in profileDetails?.clinicians"
              :key="index"
            >
              <div class="clinics-row">
                <edit-btn
                  @click="
                    router.push({
                      name: 'clinicians-profile',
                      query: { id: clinic?.id },
                    })
                  "
                />
                <label class="clinics-text">{{ clinic.name }}</label>
                <input
                  class="profile-checkbox"
                  type="checkbox"
                  :checked="clinic.isEmployed"
                  disabled
                  role="presentation"
                />
                <input
                  class="profile-checkbox"
                  type="checkbox"
                  :checked="!clinic.isEmployed"
                  disabled
                  role="presentation"
                />
              </div>
            </div>
            <div
              v-if="!profileDetails?.clinicians?.length"
              class="profile-empty-list"
            >
              <h3>No Clinicians</h3>
              Sorry, this clinic doesn't have any associated clinicians yet
            </div>
          </div>
          <span class="notes-total"
            >{{ profileDetails?.clinicians?.length }} Clinicians</span
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import BtnVue from "../../components/BtnVue.vue";
import InputVue from "../../components/InputVue/InputVue.vue";
import RadioBtns from "../../components/InputVue/RadioBtns.vue";
import EditBtn from "../../components/EditBtn.vue";
import ScheduleBreadcrumbs from "../../components/ScheduleBreadcrumbs.vue";
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import * as moment from "moment";
import LoaderVue from "@/components/LoaderVue.vue";
import { toast } from "vue3-toastify";
import SearchSelect from "../../components/SelectVue/SearchSelect.vue";

export default {
  components: {
    BtnVue,
    InputVue,
    RadioBtns,
    EditBtn,
    ScheduleBreadcrumbs,
    LoaderVue,
    SearchSelect,
  },
  setup() {
    const internalLink = process.env.VUE_APP_INTERNAL_PORTAL_URL;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const id = ref(route.query.id);
    const isLoading = ref(false);
    const selectedGroup = ref("None");
    const profileDetails = computed(() => {
      return store.getters["clinics/clinicDetails"];
    });
    const reportGroups = computed(() => {
      return store.getters["report/reportGroups"];
    });
    const clinicsList = computed(() => {
      return store.getters["clinics/clinicsListDetails"];
    });
    const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);

    const isAccessible = ref(profileDetails?.value?.isAccessible);
    watch(
      profileDetails,
      () => {
        isAccessible.value = profileDetails.value?.isAccessible;
        id.value = profileDetails.value?.codedId;
      },
      {
        deep: true,
      }
    );
    watch([reportGroups, profileDetails], () => {
      selectedGroup.value =
        reportGroups.value?.find((data) =>
          data.clinicNames?.includes(profileDetails.value?.name)
        )?.name || "None";
    });
    onMounted(async () => {
      isLoading.value = true;
      if (!id.value) {
        const localId = localStorage.getItem("clinicId");
        id.value = localId;
        await store.dispatch("clinics/setClinicDetails", localId);
      } else {
        localStorage.setItem("clinicId", id.value);
        await store.dispatch("clinics/setClinicDetails", id.value);
      }

      store.dispatch("clinics/setClinicsListDetails");
      store.dispatch("report/setReportGroups");
      router.replace({
        query: {
          id: undefined,
        },
      });
      isLoading.value = false;
    });

    onUnmounted(() => {
      const groupName =
        reportGroups.value?.find((data) =>
          data.clinicNames?.includes(profileDetails.value?.name)
        )?.name || "None";
      if (
        isAccessible.value !== profileDetails.value?.isAccessible ||
        selectedGroup.value !== groupName
      ) {
        toast.warning("Your changes have not been saved");
      }
      store.commit("clinics/setClinicDetails", {});
    });

    const copyText = (text, type) => {
      navigator.clipboard.writeText(text);
      toast.success(type + " Copied to Clipboard");
    };

    const setChecked = (title) => {
      isAccessible.value = title === "Y";
    };

    const setGroup = (group) => {
      selectedGroup.value = group;
    };
    const updateDetails = async () => {
      await store.dispatch("clinics/updateClinicDetails", {
        clinicId: localStorage.getItem("clinicId"),
        accessibleType: isAccessible.value,
        reportGroupId:
          selectedGroup.value === "None"
            ? null
            : reportGroups.value?.find(
                (data) => data.name === selectedGroup.value
              )?.id,
      });
      store.commit("clinics/setClinicDetails", {
        ...profileDetails?.value,
        isAccessible: isAccessible.value,
      });
      await store.dispatch("report/setReportGroups");
    };
    const setIsLoading = async (id) => {
      isLoading.value = true;
      await store.dispatch("clinics/setClinicDetails", id);
      isLoading.value = false;
    };
    return {
      setIsLoading,
      copyText,
      profileDetails,
      updateDetails,
      setChecked,
      moment,
      isLoading,
      router,
      id: id || localStorage.getItem("clinicId"),
      clinicsList,
      selectedGroup,
      setGroup,
      reportGroups,
      internalLink,
      isSuperAdmin
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/ClinitianProfile.scss";
.profile-empty-list {
  @include typography(16, 16, 400);
  color: #486d72;
  text-align: center;
  margin-top: 70px;

  h3 {
    margin-bottom: 16px;
  }
}
.link-wrapper {
  text-decoration: none;
}
</style>

