<template>
  <div>
    <div v-for="(btn, index) in btns" :key="index" class="radio-container">
      <input
        :id="btn.id"
        :name="name"
        class="radio-input"
        :disabled="disabled"
        :checked="btn.checked"
        @change="$emit('setChecked', btn.title)"
        type="radio"
      />
      <label :for="btn.id">{{ btn.title }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    btns: Array,
    disabled: Boolean,
  },
  emits: {
    setChecked: Function,
  },
  setup() {
    return {};
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/input.scss";
</style>