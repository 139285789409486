<template>
    <common-layout>
        <div class="clinitians-wrapper">
            <div class="clinitians-filter-wrapper">
                <h2 class="clinitians-title">Healthcare Professionals</h2>

                <div class="clinitians-filter-block">
                    <div>
                        <h4 class="select-label">Status</h4>
                        <select-vue
                            id="61c5f1ca-f94f-4aff-b9ca-2adee37c4c2a"
                            :selectedDefault="statusSelected"
                            :options="status"
                            :setSelectedAction="setStatus"
                        ></select-vue>
                    </div>

                    <div class="hcp-type-filter">
                        <h4 class="select-label">HCP Type</h4>
                        <multi-select
                            id="44b6103b-f814-4ead-87de-cf5204fc777c"
                            :selectedDefault="hcpType"
                            :options="hcpType"
                            :setSelectedAction="setHcpType"
                        ></multi-select>
                    </div>
                    <div class="g4p-status-filter">
                        <h4 class="select-label">G4P Status</h4>
                        <multi-select
                            id="0e95ea37-7c93-40d4-960e-aee7d6ee537c"
                            :selectedDefault="g4pStatusSelected"
                            :options="g4pStatus"
                            :setSelectedAction="setG4PStatus"
                            :style="'min-width: 232px;'"
                            :g4pMultiSelect="true"
                        ></multi-select>
                    </div>
                    <div>
                        <h4 class="select-label">Specialisms</h4>
                        <multi-select
                            class="spesialisms-select-container"
                            id="11aadb33-9d47-435f-8e64-a265767990a1"
                            :selectedDefault="[]"
                            :options="specialisms"
                            :withSearch="true"
                            :setSelectedAction="setSpecialisms"
                        ></multi-select>
                    </div>
                    <div>
                        <h4 class="select-label">Search by Name</h4>
                        <input-vue
                            id="c37e79e9-d86f-44cf-96de-2982e158c452"
                            role="search"
                            :placeholder="'enter here'"
                            :value="searchValue"
                            @setText="setSearchValue"
                            v-model="searchValue"
                        />
                    </div>
                    <div>
                        <h4 class="select-label">Employed type</h4>
                        <tabs-vue
                            id="c9fe0ab5-b6c4-4789-851a-8ba886a3ed82"
                            :options="include"
                            :activeOption="include[0]"
                            :setSelectedAction="setInclude"
                        ></tabs-vue>
                    </div>
                </div>
            </div>

            <table-vue
                :statusSelected="statusSelected"
                :includeSelected="includeSelected"
                :g4pStatusSelected="g4pStatusSelected"
                :specialismsSelected="specialismsSelected"
                :searchValue="searchValue"
                :hcpType="hcpType"
                :hcpTypeSelected="hcpTypeSelected"
            />
        </div>
    </common-layout>
</template>

<script>
import TableVue from "../../components/CliniсiansTableVue.vue";
import CommonLayout from "../../views/CommonLayout.vue";
import SelectVue from "../../components/SelectVue/SelectVue.vue";
import MultiSelect from "../../components/SelectVue/MultiSelect.vue";
import InputVue from "../../components/InputVue/InputVue.vue";
import TabsVue from "../../components/TabsVue/TabsVue.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
    components: {
        CommonLayout,
        TableVue,
        SelectVue,
        MultiSelect,
        InputVue,
        TabsVue,
    },
    setup() {
        const g4pStatus = [
            "Active",
            "Disabled",
            "Expired Link",
            "Pending Registration",
            'Not Registered'
        ];
        const searchValue = ref("");
        const statusSelected = ref("Active");
        const g4pStatusSelected = ref([ "Active",
            "Disabled",
            "Expired Link",
            "Pending Registration"]);
        const includeSelected = ref("All");
        const store = useStore();
        const specialisms = computed(() => {
            return store.getters["clinicians/specialisations"];
        });

        const hcpType = [
            "CBT Therapist",
            "Psychologist",
            "Counsellor",
            "Not set",
        ];
        const specialismsSelected = ref([]);
        const hcpTypeSelected = ref(hcpType);

        const include = ["All", "Employed", "Network"];
        const status = ["Active", "Disabled"];

        const setStatus = (status) => {
            statusSelected.value = status;
        };

        const setG4PStatus = (status) => {
            g4pStatusSelected.value = status;
        };
        const setHcpType = (status) => {
            hcpTypeSelected.value = status;
        };

        const setInclude = (status) => {
            includeSelected.value = status;
        };

        const setSpecialisms = (data) => {
            specialismsSelected.value = data;
        };

        const setSearchValue = (data) => {
            searchValue.value = data;
        };

        onMounted(() => {
            store.dispatch("clinicians/setSpecialisations");
        });

        return {
            status,
            include,
            specialisms,
            hcpType,
            setStatus,
            statusSelected,
            setInclude,
            includeSelected,
            setSpecialisms,
            specialismsSelected,
            searchValue,
            setSearchValue,
            hcpTypeSelected,
            setHcpType,
            g4pStatus,
            g4pStatusSelected,
            setG4PStatus,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Clinitians.scss";
</style>

