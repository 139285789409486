import { createApp } from "vue";
import App from "./App.vue";
import { msalInstance } from "./auth";
import { msalPlugin } from "./plugins/msal";
import router from "./router";
import store from "./store";
import Vue3EasyDataTable from "vue3-easy-data-table";
import Vue3Toastify from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import VueDatePicker from "@vuepic/vue-datepicker";

createApp(App)
    .use(router)
    .use(store)
    .use(msalPlugin, msalInstance)
    .use(Vue3Toastify, {
        autoClose: 5000,
        theme: "colored",
    })
    .component("DataTable", Vue3EasyDataTable)
    .component("VueDatePicker", VueDatePicker)
    .mount("#app");

