<template>
  <div class="card-container">
    <div class="image-container"></div>
    <span class="card-title">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/components/card.scss";
</style>