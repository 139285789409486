<template>
  <div class="schedule-filter">
    <div class="schedule-filter-title">Key/Filter:</div>
    <div class="schedule-filter-item">
      <input
        :id="'show-all' + id"
        name="schedule-filter"
        type="radio"
        value="showAll"
        @change="setData({ filter: 'showAll' })"
        checked
        role="presentation"
      />
      <label
        class="schedule-filter-label schedule-filter--show-all"
        :for="'show-all' + id"
        >Show All</label
      >
    </div>
    <div
      v-for="filter in items"
      class="schedule-filter-item"
      :key="filter?.key"
    >
      <input
        :id="filter.title + id"
        name="schedule-filter"
        type="radio"
        @change="setData(filter)"
        role="presentation"
      />
      <label
        :for="filter.title + id"
        class="schedule-filter-label schedule-filter--unconfirmed"
        :class="`schedule-filter--${filter?.filter}`"
        >{{ filter?.title }}({{ filter?.value }})</label
      >
    </div>
    <div class="schedule-filter-item">
      <input
        :id="'new-availability' + id"
        name="schedule-filter"
        type="radio"
        value="newPatientAvailability"
        @change="setData({ filter: 'newPatientAvailability' })"
        role="presentation"
      />
      <label
        class="schedule-filter-label schedule-filter--new-availability"
        :for="'new-availability' + id"
        >New Patient Availability</label
      >
    </div>
    <div class="schedule-filter-item" v-if="withOutlookEvents">
      <input
        :id="'outlook-events' + id"
        name="schedule-filter"
        type="radio"
        value="outlookEvents"
        @change="setData({ filter: 'outlookEvents' })"
        role="presentation"
      />
      <label
        class="schedule-filter-label schedule-filter--outlook-events"
        :for="'outlook-events' + id"
        >Outlook Events</label
      >
    </div>
  </div>
</template>

<script>
import { toRefs } from "vue";

export default {
  props: {
    data: Array,
    setData: Function,
    id: String,
    withOutlookEvents: Boolean,
  },
  setup(props) {
    const { data: items } = toRefs(props);
    return { items };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/scheduleFilter.scss";
</style>