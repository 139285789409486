<template>
  <div :class="[type, {'disabled': isDisabled}]">
    <span class="btn-text">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      requiered: true,
    },
    isDisabled: Boolean,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/btn.scss";
</style>