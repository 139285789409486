import { createStore } from "vuex";
import auth from "./modules/auth.module";
import clinicians from "./modules/clinicians.module";
import clinics from "./modules/clinics.module";
import availability from "./modules/availability.module";
import report from "./modules/report.module";

export const clinicDetailsMockData = {
    codedId: "e6847dba-ce3a-4f8d-bba6-536d05a09418",
    name: "23.01 External Clinic 1",
    emailAddress: null,
    telephoneNumber: null,
    faxNumber: null,
    isActive: true,
    isAccessible: true,
    isRemote: false,
    line1: "1",
    line2: "1",
    county: "1",
    postcode: "CV1 1QW",
    town: "1",
};
export const careRecordsMockData = [
    {
        caseId: "325bd0b4-d26f-4844-abf7-b08795eb82e4",
        caseReference: "CMMA/MR/23/287",
        patientTitle: "",
        patientForenames: "Willie",
        patientSurname: "Leffler",
        accessDeniedOn: null,
    },
    {
        caseId: "55f15b14-0708-4375-9f40-9bb7191d028a",
        caseReference: "RBUT/MR/22/1",
        patientTitle: "",
        patientForenames: "Wil",
        patientSurname: "Buck",
        accessDeniedOn: null,
    },
    {
        caseId: "2cc0a2d7-4647-4e6f-9369-1ff92994342b",
        caseReference: "CMMA/MR/23/83",
        patientTitle: "",
        patientForenames: "Vivienne",
        patientSurname: "Marvin",
        accessDeniedOn: null,
    },
];

export const clinicianListDetails = {
    id: "8dbe41a7-7312-4d48-8ec5-8622c0669708",
    title: "Mr",
    forename: "Alan",
    surname: "Davis",
    clinicianType: 1,
    isEmployed: true,
    email: "alandavisg4p@mailinator.com",
    telephone: "07985152650",
    hasSchedule: true,
    selfManagedAvailability: true,
    isActive: true,
    professionalRegistrationNumber: "567567",
    accountStatus: 0,
    inviteExpiryDate: "2023-10-20T04:52:14.123+00:00",
    specialisations: [
        "IMA/IHR",
        "Home Visits",
        "Blood Samples",
        "Driver medicals incl D4/Grp 2",
        "Audiometry",
        "Spirometry",
        "HAVS 4 \u0026 5",
        "Travel Health \u0026 Vacinations",
        "WSA/Car/Ergonomic",
        "WPA (OT assessments)",
        "Psychologist/Therapy/CBT",
        "Dyslexia/Dyspraxia/ADHD",
        "Rail/PTS/Trackside/LUL",
        "Skin",
        "Asbestos",
        "Ionising radiation/IRR",
        "Lead",
        "Oil and Gas/Offshore",
        "ENG 1",
        "Diving",
        "Fitness medicals",
        "Working at heights/RUK/Renewables",
        "Psychiatrist",
        "Physiotherapy",
        "D\u0026A testing",
        "Pilot/Crew medicals",
        "JLR TIMA",
        "HMRC TIMA",
        "Allergy and immunology",
    ],
    deactivationReasons: [],
};
export const clinicListMockData = {
    pageNumber: 1,
    pageSize: 10,
    totalItems: 5,
    totalPages: 1,
    items: [
        {
            codedId: "e6847dba-ce3a-4f8d-bba6-536d05a09418",
            systemId: 182,
            name: "23.01 External Clinic 1",
            emailAddress: null,
            telephoneNumber: null,
            faxNumber: null,
            isActive: true,
            isRemote: false,
            postcode: "CV1 1QW",
            town: "1",
        },
        {
            codedId: "2999b6c5-ccbf-45ba-8247-3f8b53e18aa5",
            systemId: 183,
            name: "23.01 External Clinic 2",
            emailAddress: null,
            telephoneNumber: null,
            faxNumber: null,
            isActive: true,
            isRemote: false,
            postcode: "CV2 2QW",
            town: "2",
        },
        {
            codedId: "2df6088a-49b7-42f9-adee-0b7a13a77124",
            systemId: 184,
            name: "23.01 External Clinic 3",
            emailAddress: null,
            telephoneNumber: null,
            faxNumber: null,
            isActive: true,
            isRemote: false,
            postcode: "CV3 3QW",
            town: "3",
        },
        {
            codedId: "fcc5a604-23b8-4de9-ab59-759becd48740",
            systemId: 179,
            name: "23.01 Remote Clinic 1",
            emailAddress: null,
            telephoneNumber: null,
            faxNumber: null,
            isActive: true,
            isRemote: true,
            postcode: null,
            town: null,
        },
        {
            codedId: "11997a87-1b6e-455c-8d3c-77c71713bbd9",
            systemId: 180,
            name: "23.01 Remote Clinic 2",
            emailAddress: null,
            telephoneNumber: null,
            faxNumber: null,
            isActive: true,
            isRemote: true,
            postcode: null,
            town: null,
        },
    ],
};
export const clinicianListMockData = {
    pageNumber: 1,
    pageSize: 10,
    totalItems: 6,
    totalPages: 1,
    items: [
        {
            id: "2d2f8111-404a-4189-8eb0-cf2454149887",
            name: "abc]cd\\~#?,\u003C\u003E!\u0022\u00A3$%\u0026^*()ghd cbz",
            hcpType: "CBT Therapist",
            isEmployed: false,
            isActive: true,
            email: "vjexternal2@mailinator.com",
            telephone: "07495698555",
        },
        {
            id: "85f1b733-fe5b-40f5-b520-a50bc065452c",
            name: "abc]cd\\~#?,\u003C\u003E!\u0022\u00A3$%\u0026^*()ghd cbz",
            hcpType: "Not Set",
            isEmployed: false,
            isActive: true,
            email: "vjexternal2@mailinator.com",
            telephone: "07495698555",
        },
        {
            id: "9b7873a5-f3f4-4fff-9038-5c4ca402c03b",
            name: "abc]cd\\~#?,\u003C\u003E!\u0022\u00A3$%\u0026^*()ghd cbz",
            hcpType: "CBT Therapist",
            isEmployed: false,
            isActive: true,
            email: "vjexternal2@mailinator.com",
            telephone: "07495698555",
        },
        {
            id: "886322d6-3f56-4851-855f-d5fa0410e4f9",
            name: "Aidan Minton",
            hcpType: "Counsellor",
            isEmployed: false,
            isActive: true,
            email: "aidan.minton@razor.co.uk",
            telephone: "",
        },
        {
            id: "8dbe41a7-7312-4d48-8ec5-8622c0669708",
            name: "Alan Davis",
            hcpType: "Psychologist",
            isEmployed: true,
            isActive: true,
            email: "alandavisg4p@mailinator.com",
            telephone: "07985152650",
        },
        {
            id: "ec24bcb4-a83e-4db6-b57d-388f58fbd2f8",
            name: "Andrew Barnett",
            hcpType: "Not Set",
            isEmployed: false,
            isActive: true,
            email: "andrew.barnett@healthpartners.uk.com",
            telephone: "12345",
        },
    ],
};

const reportGroupsMockData = [
    {
        id: "dcb2eddf-14d0-44d9-9111-7462cdf37573",
        name: "Aberdeen Postcodes",
        clinicNames: ["23.01 External Clinic 1", "23.01 Remote Clinic 1"],
    },
    {
        id: "a2216d69-73b5-49cf-b0a6-075fa0213500",
        name: "Aberdeen Postcodes.0.1",
        clinicNames: ["23.01 Remote Clinic 3"],
    },
    {
        id: "6317267b-98f4-4bf6-b305-1c24c08bc3ae",
        name: "Aberdeen Postcodes.0.2",
        clinicNames: [],
    },
    {
        id: "cf467973-fc62-4cef-af92-b3ac7f30e60f",
        name: "Autotest",
        clinicNames: ["MyGateway External clinic"],
    },
    {
        id: "eefee434-265c-44b5-99ca-08c7e9505daf",
        name: "Birmingham Wards",
        clinicNames: ["Ana f2f newURL int", "bulk ext reg cl"],
    },
    {
        id: "51c585e9-57f8-46c2-a1d4-cb7604ffdf2f",
        name: "Birmingham Wards.0.1",
        clinicNames: [],
    },
    {
        id: "4d48073c-5bf9-47f6-81e4-6e82c84b47f6",
        name: "Birmingham Wards.0.2",
        clinicNames: ["ER HP 1"],
    },
    {
        id: "1fb1a912-585f-45da-b01b-89af377e81d4",
        name: "Brighton and Hove Districts",
        clinicNames: [],
    },
    {
        id: "ae421baf-c59e-4fa3-92b7-ec0abde7e04e",
        name: "Brighton and Hove Districts.0.1",
        clinicNames: [],
    },
];

export const reportMockData = {
    dates: [
        "2023-06-20T00:00:00",
        "2023-06-21T00:00:00",
        "2023-06-22T00:00:00",
        "2023-06-23T00:00:00",
        "2023-06-24T00:00:00",
        "2023-06-25T00:00:00",
        "2023-06-26T00:00:00",
    ],
    totalUsagePercentage: 0,
    availabilities: [60, null, 60, 600, null, null, null],
    utilizedAppoinments: [0, 0, 0, 0, 0, 0, 0],
    unutilizedAppoinments: [30, 0, 0, 0, 0, 0, 0],
    utilizationPercentages: [50, null, 0, 0, null, null, null],
    reportGroupAvailabilityUtilization: [
        {
            reportGroupId: "dcb2eddf-14d0-44d9-9111-7462cdf37573",
            reportGroupName: "Aberdeen Postcodes",
            totalUtilizationPercentage: 0,
            utilizationPercentages: [null, null, null, 0, null, null, null],
            clinicAvailabilityUtilization: [
                {
                    clinicId: 179,
                    clinicName: "23.01 Remote Clinic 1",
                    totalUtilizationPercentage: 0,
                    utilizationPercentages: [
                        null,
                        null,
                        null,
                        0,
                        null,
                        null,
                        null,
                    ],
                    clinicianAvailabilityUtilization: [
                        {
                            clinicianId: 17,
                            clinicianName: "Tony Coates",
                            totalUtilizationPercentage: 0,
                            utilizationPercentages: [
                                null,
                                null,
                                null,
                                0,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                null,
                                600,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            clinicianId: 193,
                            clinicianName: "Lawrence1 Gee",
                            totalUtilizationPercentage: null,
                            utilizationPercentages: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            clinicianId: 117,
                            clinicianName: "Luke testDoctor",
                            totalUtilizationPercentage: null,
                            utilizationPercentages: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                    ],
                },
            ],
        },
        {
            reportGroupId: "4d48073c-5bf9-47f6-81e4-6e82c84b47f6",
            reportGroupName: "Birmingham Wards.0.2",
            totalUtilizationPercentage: 0,
            utilizationPercentages: [0, null, 0, null, null, null, null],
            clinicAvailabilityUtilization: [
                {
                    clinicId: 81,
                    clinicName: "ER HP 1",
                    totalUtilizationPercentage: 0,
                    utilizationPercentages: [
                        0,
                        null,
                        0,
                        null,
                        null,
                        null,
                        null,
                    ],
                    clinicianAvailabilityUtilization: [
                        {
                            clinicianId: 17,
                            clinicianName: "Tony Coates",
                            totalUtilizationPercentage: 0,
                            utilizationPercentages: [
                                null,
                                null,
                                0,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                60,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            clinicianId: 16,
                            clinicianName: "Jane Test",
                            totalUtilizationPercentage: null,
                            utilizationPercentages: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            clinicianId: 193,
                            clinicianName: "Lawrence1 Gee",
                            totalUtilizationPercentage: 0,
                            utilizationPercentages: [
                                0,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                60,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                        {
                            clinicianId: 196,
                            clinicianName: "Matt Christie",
                            totalUtilizationPercentage: null,
                            utilizationPercentages: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                    ],
                },
            ],
        },
        {
            reportGroupId: "c88be909-a96f-430b-9ec7-bf985f4ba4d9",
            reportGroupName: "Bristol Quarters",
            totalUtilizationPercentage: -2147483648,
            utilizationPercentages: [null, null, null, null, null, null, null],
            clinicAvailabilityUtilization: [
                {
                    clinicId: 185,
                    clinicName: "Ana Clinic",
                    totalUtilizationPercentage: -2147483648,
                    utilizationPercentages: [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ],
                    clinicianAvailabilityUtilization: [
                        {
                            clinicianId: 382,
                            clinicianName: "Diana Webster",
                            totalUtilizationPercentage: null,
                            utilizationPercentages: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            availabilities: [
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                                null,
                            ],
                            utilizedAppointments: [30, 0, 0, 0, 0, 0, 0],
                            unutilizedAppointments: [0, 0, 0, 0, 0, 0, 0],
                        },
                    ],
                },
            ],
        },
    ],
};

export default createStore({
    modules: {
        auth,
        clinicians,
        clinics,
        availability,
        report,
    },
});

export const unitTestStore = createStore({
    modules: {
        report: {
            namespaced: true,
            state() {
                return {
                    reportGroups: reportGroupsMockData,
                    report: {},
                };
            },
            mutations: {
                setReportGroups(state, payload) {
                    state.reportGroups = payload;
                },
                setReport(state, payload) {
                    state.report = payload;
                },
            },
            getters: {
                reportGroups(state) {
                    return state.reportGroups;
                },
                report(state) {
                    return state.report;
                },
            },
            actions: {
                setReport({ commit }) {
                    commit("setReport", reportMockData);
                },
                setReportGroups({ commit }) {
                    commit("setReportGroups", reportGroupsMockData);
                },
                addReportGroup() {
                    return 200;
                },
                deleteReportGroup({ commit }) {
                    commit(
                        "setReportGroups",
                        reportGroupsMockData.slice(0, -1)
                    );
                },
            },
        },
        clinics: {
            namespaced: true,
            state() {
                return {
                    clinicList: { items: [] },
                    clinicDetails: {},
                    specialisations: [],
                    associatedClinics: [],
                    clinicsListDetails: [],
                };
            },
            getters: {
                clinicList(state) {
                    return state.clinicList;
                },
                clinicDetails(state) {
                    return state.clinicDetails;
                },
                clinicsListDetails(state) {
                    return state.clinicsListDetails;
                },
            },
            mutations: {
                setClinicList(state, payload) {
                    state.clinicList = payload;
                },
                setClinicDetails(state, payload) {
                    state.clinicDetails = payload;
                },
                setClinicsListDetails(state, payload) {
                    state.clinicsListDetails = payload;
                },
            },
            actions: {
                setClinicList({ commit }, payload) {
                    let filteredPayload = clinicListMockData;
                    if (payload.searchByName) {
                        filteredPayload = {
                            ...clinicListMockData,
                            items: clinicListMockData.items.filter((data) =>
                                data.name.includes(payload.searchByName)
                            ),
                        };
                    }
                    if (payload.searchByPostcode) {
                        filteredPayload = {
                            ...clinicListMockData,
                            items: clinicListMockData.items.filter((data) =>
                                data?.postcode?.includes(
                                    payload.searchByPostcode
                                )
                            ),
                        };
                    }
                    if (payload.clinicTypeFilter === "remote") {
                        filteredPayload = {
                            ...clinicListMockData,
                            items: clinicListMockData.items.filter(
                                (data) => data.isRemote
                            ),
                        };
                    }

                    commit("setClinicList", filteredPayload);
                },
                setClinicDetails({ commit }, payload) {
                    if (payload?.codedId) {
                        commit("setClinicDetails", payload);
                    } else {
                        commit("setClinicDetails", clinicDetailsMockData);
                    }
                },
                setClinicsListDetails({ commit }) {
                    commit("setClinicsListDetails", clinicListMockData.items);
                },
                updateClinicDetails({ commit }, payload) {
                    if (Object.hasOwn(payload, "accessibleType")) {
                        commit("setClinicDetails", {
                            ...clinicDetailsMockData,
                            isAccessible: payload.accessibleType,
                        });
                    }
                },
            },
        },
        clinicians: {
            namespaced: true,
            state() {
                return {
                    careRecords: careRecordsMockData,
                    clinicianList: { items: [] },
                    clinicianDetails: {},
                    accountStatus: 0
                };
            },
            getters: {
                clinicianList(state) {
                    return state.clinicianList;
                },
                clinicianDetails(state) {
                    return state.clinicianDetails;
                },
                careRecords(state) {
                    return state.careRecords;
                },
                accountStatus(state) {
                    return state.accountStatus;
                },
            },
            mutations: {
                setStatus(state, payload) {
                    state.accountStatus = payload;
                },
                setClinicianList(state, payload) {
                    state.clinicianList = payload;
                },
                setClinicianListDetails(state, payload) {
                    state.clinicianDetails = payload;
                },
                setClinician(state, payload) {
                    state.clinicianDetails = payload;
                },
                setCareRecords(state, payload) {
                    state.careRecords = payload;
                },
            },
            actions: {
                updateClinicianDetails({ commit }) {
                    commit("setClinicianListDetails", clinicianListDetails);
                },
                cliniciansListDetails({ commit }) {
                    commit("setClinicianListDetails", clinicianListDetails);
                },
                updateProfileDetails({ commit }, payload) {
                    if (Object.hasOwn(payload, "isEmployed")) {
                        commit("setClinicianListDetails", {
                            ...clinicianListDetails,
                            isEmployed: payload.isEmployed,
                        });
                    }
                },
                setClinicianList({ commit }, payload) {
                    let filteredPayload = clinicianListMockData;
                    if (payload.searchByName) {
                        filteredPayload = {
                            ...clinicianListMockData,
                            items: clinicianListMockData.items.filter((data) =>
                                data.name.includes(payload.searchByName)
                            ),
                        };
                    }
                    if (payload.employmentStatus === "employed") {
                        filteredPayload = {
                            ...clinicianListMockData,
                            items: clinicianListMockData.items.filter(
                                (data) => data.isEmployed
                            ),
                        };
                    }

                    commit("setClinicianList", filteredPayload);
                },
                setCareRecords({ commit }) {
                    commit("setCareRecords", careRecordsMockData);
                },
            },
        },
        auth: {
            namespaced: true,
            state() {
                return {};
            },
            getters: {
                isSuperAdmin() {
                    return true;
                },
            },
        },
    },
});

