<template>
  <div><router-view></router-view> <modal-session-timeout /></div>
</template>

<script>
import ModalSessionTimeout from "../../components/ModalVue/ModalSessionTimeout.vue";
export const CLINICIAN_STATUSES_LIST = [
  "AwaitingRegistration",
  "Active",
  "ExpiredLink",
  "Disabled",
];
export default {
  components: {
    ModalSessionTimeout,
  },
  setup() {},
};
</script>

<style lang="scss">
// @import "@/assets/scss/pages/Clinitians.scss";
</style>
