<template>
  <div class="report-collapse-row-subwrapper">
    <div class="report-table-collapse-row-right">
      <div
        class="report-table-cell"
        v-for="(clinicianData, index) in clinicianList?.availabilities"
        :key="clinicianData + index"
        :style="{
          width:
            clinicianList?.availabilities.length < 8
              ? 100 / clinicianList?.availabilities.length + '%'
              : '100px',
          height: '41px',
        }"
      >
        {{ clinicianData }}
      </div>
    </div>
    <div class="report-table-collapse-row-right">
      <div
        class="report-table-cell"
        v-for="(clinicianData, index) in clinicianList?.attendedAppointments"
        :key="clinicianData + index"
        :style="{
          width:
            clinicianList?.availabilities.length < 8
              ? 100 / clinicianList?.availabilities.length + '%'
              : '100px',
          height: '41px',
        }"
      >
        {{ clinicianData }}
      </div>
    </div>
    <div class="report-table-collapse-row-right">
      <div
        class="report-table-cell"
        v-for="(
          clinicianData, index
        ) in clinicianList?.cancelledAfter48HoursAppointments"
        :key="clinicianData + index"
        :style="{
          width:
            clinicianList?.availabilities.length < 8
              ? 100 / clinicianList?.availabilities.length + '%'
              : '100px',
          height: '41px',
        }"
      >
        {{ clinicianData }}
      </div>
    </div>
    <div class="report-table-collapse-row-right">
      <div
        class="report-table-cell"
        v-for="(
          clinicianData, index
        ) in clinicianList?.cancelledWithin48HoursAppointments"
        :key="clinicianData + index"
        :style="{
          width:
            clinicianList?.availabilities.length < 8
              ? 100 / clinicianList?.availabilities.length + '%'
              : '100px',
          height: '41px',
        }"
      >
        {{ clinicianData }}
      </div>
    </div>
    <div class="report-table-collapse-row-right">
      <div
        class="report-table-cell"
        v-for="(clinicianData, index) in clinicianList?.dnaAppointments"
        :key="clinicianData + index"
        :style="{
          width:
            clinicianList?.availabilities.length < 8
              ? 100 / clinicianList?.availabilities.length + '%'
              : '100px',
          height: '41px',
        }"
      >
        {{ clinicianData }}
      </div>
    </div>
    <div class="report-table-collapse-row-right">
      <div
        class="report-table-cell"
        v-for="(clinicianData, index) in clinicianList?.otherMeetings"
        :key="clinicianData + index"
        :style="{
          width:
            clinicianList?.availabilities.length < 8
              ? 100 / clinicianList?.availabilities.length + '%'
              : '100px',
          height: '41px',
        }"
      >
        {{ clinicianData }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { clinicianList: Array },
};
</script>