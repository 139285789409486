<template>
  <input
    :placeholder="placeholder"
    class="input-container"
    :class="withSearchIcon ? 'input-container--search' : ''"
    :disabled="disabled"
    @input="$emit('set-text', $event.target.value)"
    @blur="$emit('blur-action', $event.target.value)"
    :value="searchValue"
    :type="type || 'text'"
    @keydown="keyDownFunc"
  />
</template>

<script>
export default {
  props: {
    width: String,
    placeholder: String,
    disabled: Boolean,
    searchValue: String,
    type: String,
    withSearchIcon: Boolean,
  },
  emits: {
    blurAction: Function,
    setText: Function,
  },
  setup(props) {
    const keyDownFunc = (evt) => {
      if (props.type === "number") {
        ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
      }
    };
    return {
      keyDownFunc,
    };
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/input.scss";
</style>