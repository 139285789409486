import { toast } from "vue3-toastify";
export default {
    namespaced: true,
    state() {
        return {
            reportGroups: [],
            report: {},
        };
    },
    mutations: {
        setReportGroups(state, payload) {
            state.reportGroups = payload;
        },
        setReport(state, payload) {
            state.report = payload;
        },
    },
    getters: {
        reportGroups(state) {
            return state.reportGroups;
        },
        report(state) {
            return state.report;
        },
    },
    actions: {
        async setReport({ commit, rootState }, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: JSON.stringify(payload),
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/reportgroups/report",
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? {} : response.json()
                );
                commit("setReport", response);
            } catch (error) {
                console.log(error);
            }
        },
        async setReportGroups({ commit, rootState }) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + rootState.auth.token
                );

                const requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL + "api/reportgroups",
                    requestOptions
                ).then((response) =>
                    response.status === 204 ? [] : response.json()
                );
                commit("setReportGroups", response);
            } catch (error) {
                console.log(error);
            }
        },
        async downloadReportGroups(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: raw,
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/reportgroups/report/download",
                    requestOptions
                ).then(async (response) => {
                    if (response.ok) {
                        const fileBlob = await response.blob();
                        const fileUrl = URL.createObjectURL(fileBlob);

                        const link = document.createElement("a");
                        link.href = fileUrl;
                        link.download = "report.csv";

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    return response.status;
                });
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async uploadReportGroups(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const formData = new FormData();
                formData.append("File", payload.file);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: formData,
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/reportgroups/upload",
                    requestOptions
                ).then(async (response) => {
                    if (response.status === 409) {
                        return { status: 409, data: await response.text() };
                    }
                    return response;
                });
                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async addReportGroup(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    redirect: "follow",
                    body: raw,
                };

                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/reportgroups/add",
                    requestOptions
                ).then((response) => {
                    if (response.status === 200) {
                        toast.success("Report group successfully added");
                        props.dispatch("setReportGroups");
                    } else {
                        if (response.status === 409) {
                            toast.error(
                                "The Report Group already exists, please change the name"
                            );
                        }
                    }
                    return response.status;
                });

                return response;
            } catch (error) {
                console.log(error);
            }
        },
        async editReportGroup(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const raw = JSON.stringify(payload);
                const requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    body: raw,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/reportgroups/edit",
                    requestOptions
                ).then(async (response) => {
                    if (response.status === 200) {
                        toast.success("Report group name successfully updated");
                        props.dispatch("setReportGroups");
                    } else {
                        if (response.status === 409) {
                            toast.error(
                                "The Report Group already exists, please change the name"
                            );
                        }
                    }
                    return response.status;
                });
                return response;
            } catch (error) {
                toast.error("Your changes have not been saved");
                console.log(error, "error");
            }
        },
        async deleteReportGroup(props, payload) {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                myHeaders.append(
                    "Authorization",
                    "Bearer " + props.rootState.auth.token
                );
                const requestOptions = {
                    method: "DELETE",
                    headers: myHeaders,
                };
                const response = await fetch(
                    process.env.VUE_APP_BACKEND_BASE_URL +
                        "api/reportgroups/" +
                        payload,
                    requestOptions
                ).then(async (response) => {
                    if (response.status === 200) {
                        toast.success(
                            "The Report Group successfully deleted from the list"
                        );
                        props.dispatch("setReportGroups");
                    } else {
                        toast.error(
                            "The Report Group can't be deleted. There are clinics assigned to it"
                        );
                    }
                    return response.status;
                });
                return response;
            } catch (error) {
                toast.error("Your changes have not been saved");
                console.log(error, "error");
            }
        },
    },
};

