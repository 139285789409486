<template>
  <modal-layout v-if="isOpen">
    <div class="modal-block" ref="target">
      <div class="modal-closable-title-wrapper">
        <div class="modal-title-wrapper">
          <h3 class="modal-title">Edit Report Group</h3>
        </div>

        <div class="modal-close-wrapper" @click="closeWithoutSaving()">
          <img src="@/assets/icons/icon-close.svg" alt="icon" />
        </div>
      </div>

      <modal-separator />

      <div class="add-report-group-modal-block">
        <label for="">Name</label>
        <input-vue
          @setText="(text) => (name = text)"
          :searchValue="info.name"
          :placeholder="'Enter name here'"
        />
        <div class="clinic-block">
          <h4 class="clinic-block-title">Associated Clinics</h4>
          <div class="clinic-block-list">
            {{ info?.clinicNames?.join(", ") || "None" }}
          </div>
        </div>
      </div>

      <modal-separator />

      <div class="btn-block">
        <btn-vue
          id="d428b3e3-4bb2-49b2-8ec7-01beece35ad9"
          :type="'secondary-btn'"
          @click="closeWithoutSaving()"
          >Cancel</btn-vue
        >
        <btn-vue
          id="13d61f26-b3ec-4972-a95a-dfb09a80f4c9"
          :type="'primary-btn--blue'"
          @click="editReportGroup"
          >Edit Group</btn-vue
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import InputVue from "../InputVue/InputVue.vue";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";

export default {
  components: {
    ModalLayout,
    BtnVue,
    ModalSeparator,
    InputVue,
  },
  props: {
    isOpen: Boolean,
    info: Object,
  },
  emits: {
    closeAddReport: Function,
  },
  setup(props, context) {
    const target = ref();
    const name = ref(props.info.name);
    const store = useStore();
    const closeWithoutSaving = () => {
      toast.warning("Your changes haven't been saved");
      name.value = "";

      context.emit("closeAddReport");
    };
    onClickOutside(target, () => closeWithoutSaving());

    const editReportGroup = async () => {
      const status = await store.dispatch("report/editReportGroup", {
        id: props.info.id,
        name: name.value,
      });
      if (status === 200) {
        context.emit("closeAddReport");
      }
    };

    return {
      editReportGroup,
      closeWithoutSaving,
      target,
      name,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

