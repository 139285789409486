<template>
    <div
        class="schedule-page"
        :class="'schedule-page--filter-' + filter?.filter"
    >
        <clinician-sidebar
            :id="id"
            :profileDetails="profileDetails"
            :activeTab="'schedule'"
        />
        <common-layout>
            <template #breadcrumbs>
                <schedule-breadcrumbs
                    :type="'schedule'"
                    :info="{
                        title:
                            (profileDetails?.forename || '') +
                            ' ' +
                            (profileDetails?.surname || ''),
                        id: profileDetails.id,
                    }"
                />
            </template>
            <template #title>
                <h1 class="schedule-page-title">
                    {{
                        (profileDetails?.forename || "") +
                        " " +
                        (profileDetails?.surname || "")
                    }}
                </h1>
            </template>
            <template #button>
                <btn-vue
                    @click="
                        (isOpenAddAvailibility = true),
                            (addAvailibillityDate = new Date())
                    "
                    :type="'primary-btn'"
                    :isDisabled="
                        !profileDetails.isActive ||
                        !isSuperAdmin
                    "
                    >Add Availability</btn-vue
                >
            </template>
            <div class="clinitians-wrapper">
                <div :style="{ display: mode === 'day' ? 'block' : 'none' }">
                    <div
                        class="calendar-header"
                        :class="
                            profileDetails?.isEmployed
                                ? 'calendar-header--withOutlookEvents'
                                : ''
                        "
                    >
                        <div class="period-label--day">
                            {{ moment(showDate).format("MMMM Do, YYYY") }}
                        </div>
                        <schedule-filter
                            :data="filterStructure"
                            :setData="setFilter"
                            :id="'day'"
                            :withOutlookEvents="profileDetails?.isEmployed"
                        />
                        <select-vue
                            id="5c5b80a6-c559-434a-961d-99fae4dcb18d"
                            :selectedDefault="mode"
                            :options="modeValues"
                            :setSelectedAction="setModeValue"
                            :width="'200px'"
                        ></select-vue>
                        <div class="period-switch">
                            <div
                                id="23396528-2d61-4da7-b008-c35ed24381f4"
                                class="prev-period-btn"
                                @click="
                                    setShowDate(
                                        moment(showDate).subtract(1, 'day')
                                    )
                                "
                            >
                                &#60;
                            </div>
                            <div class="mode-title">Current day</div>
                            <div
                                id="333273b6-0b43-4205-9342-edd642f1711d"
                                class="next-period-btn"
                                @click="
                                    setShowDate(moment(showDate).add(1, 'day'))
                                "
                            >
                                >
                            </div>
                        </div>
                    </div>
                    <div class="day-wrapper" ref="timelineDayRef">
                        <div class="day-container">
                            <ul class="timeline-block" v-if="mode === 'day'">
                                <li
                                    v-for="hour in 24"
                                    :key="hour"
                                    class="cv-hour"
                                    @click="clickDate(hour - 1, null, $event)"
                                >
                                    <span>
                                        {{
                                            moment({ hour: hour - 1 }).format(
                                                "HH:mm"
                                            )
                                        }}</span
                                    >
                                </li>
                                <div
                                    class="curTime"
                                    :style="{
                                        top:
                                            80 * moment().hour() +
                                            (8 / 6) * moment().minute() +
                                            'px',
                                    }"
                                >
                                    <span id="currentTime">{{
                                        moment().format("HH:mm")
                                    }}</span>
                                </div>
                            </ul>
                            <div v-for="date in dates" :key="date">
                                <div
                                    v-if="date?.outlookId"
                                    :class="'day-item__outlook'"
                                    :title="`${date.subject} ${moment(date.startDate).format(
                                        'HH:mm'
                                    )}-${moment(date.endDate).format('HH:mm')}`"
                                    :style="{
                                        top:
                                            80 *
                                                moment(date.startDate).hours() +
                                            17 +
                                            (moment(date.startDate).minutes() /
                                                60) *
                                                80 +
                                            'px',
                                        height:
                                            (moment(date.endDate).diff(
                                                date.startDate,
                                                'minutes'
                                            ) /
                                                60) *
                                                80 +
                                            'px',
                                        paddingTop:
                                            moment(date.startDate).diff(
                                                date.endDate,
                                                'minutes'
                                            ) < 16
                                                ? '2px'
                                                : '4px',
                                    }"
                                >
                                    <img
                                        src="@/assets/icons/icon-file-type-outlook.svg"
                                        alt="icon"
                                    />
                                    <span>
                                        {{  date.subject }}
                                    </span>
                                    <span></span>
                                    <span>
                                        {{
                                            `${moment(date.startDate).format(
                                                "HH:mm"
                                            )}-${moment(date.endDate).format(
                                                "HH:mm"
                                            )}`
                                        }}</span
                                    >
                                </div>
                                <div
                                    v-if="date.appointmentId"
                                    class="day-item__apointment"
                                    :class="{
                                        'cv-item_dna': date.isDna,
                                        'cv-item_cancelled': date?.isCancelled,
                                        'cv-item_attented': date.isAttended,
                                        'cv-item_unconfirmed': date?.isOpen,
                                    }"
                                    :style="{
                                        top:
                                            80 *
                                                moment(date.startDate).hours() +
                                            17 +
                                            (moment(date.startDate).minutes() /
                                                60) *
                                                80 +
                                            'px',
                                        height:
                                            (moment(date.endDate).diff(
                                                date.startDate,
                                                'minutes'
                                            ) /
                                                60) *
                                                80 +
                                            'px',
                                    }"
                                    :title="`${date?.patientName} - ${
                                        date?.appointmentType
                                    } - ${
                                        date?.isDna
                                            ? 'DNA'
                                            : date?.isCancelled
                                            ? 'Cancelled'
                                            : date?.isAttended
                                            ? 'Attended'
                                            : 'Unconfirmed'
                                    } | Case: ${date?.referenceCode}${
                                        date?.appointmentNumber
                                            ? ' | Session: '
                                            : ''
                                    }${date?.appointmentNumber}`"
                                >
                                    <span class="day-item_title"
                                        >{{ date?.patientName }}
                                    </span>
                                    <span class="day-item_time">{{
                                        `${moment(date.startDate).format(
                                            "HH:mm"
                                        )}-${moment(date.endDate).format(
                                            "HH:mm"
                                        )}`
                                    }}</span>
                                    <span class="cv-item_title">
                                        Case:
                                        {{ date?.referenceCode }}
                                        <span v-if="date?.appointmentNumber">
                                            <br />
                                            Session:
                                            {{ date?.appointmentNumber }}
                                        </span>
                                    </span>
                                </div>
                                <div
                                    v-if="date.seriesStartDate"
                                    class="day-item__availibility"
                                    :style="{
                                        top:
                                            80 *
                                                moment(date.startDate).hours() +
                                            17 +
                                            (moment(date.startDate).minutes() /
                                                60) *
                                                80 +
                                            'px',
                                        height:
                                            (moment(date.endDate).diff(
                                                date.startDate,
                                                'minutes'
                                            ) /
                                                60) *
                                                80 +
                                            'px',
                                    }"
                                    :title="`${date?.clinicName} ${moment(
                                        date?.startDate
                                    ).format('HH:mm')}-${moment(
                                        date?.endDate
                                    ).format('HH:mm')}`"
                                    @click="openEditAction(date)"
                                >
                                    <span class="day-item_title"
                                        >{{ date?.clinicName }}
                                    </span>
                                    <span class="day-item_time">{{
                                        `${moment(date.startDate).format(
                                            "HH:mm"
                                        )}-${moment(date.endDate).format(
                                            "HH:mm"
                                        )}`
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="calendar-container" :class="mode">
                    <div ref="timelineRef" class="calendar-wrapper">
                        <calendar-view
                            :items="dates"
                            :show-date="showDate"
                            :displayPeriodUom="
                                mode === 'week' ? 'week' : 'month'
                            "
                            class="bg-white rounded-lg shadow overflow-hidden"
                            :time-format-options="timeOptions"
                            tabindex="0"
                            itemTop="2rem"
                            @click-date="clickDate"
                        >
                            <template #header="{ headerProps }">
                                <div class="calendar-header">
                                    <div
                                        class="period-label"
                                        @click="headerProps.previousPeiod()"
                                    >
                                        {{ headerProps.periodLabel }}
                                    </div>
                                    <schedule-filter
                                        :data="filterStructure"
                                        :setData="setFilter"
                                        :id="'week'"
                                        :withOutlookEvents="
                                            profileDetails?.isEmployed
                                        "
                                    />
                                    <select-vue
                                        id="1ffd07f9-77d9-4acc-a337-0a1beb93ebd0"
                                        :selectedDefault="mode"
                                        :options="modeValues"
                                        :setSelectedAction="setModeValue"
                                        :width="'200px'"
                                    ></select-vue>
                                    <div class="period-switch">
                                        <div
                                            id="b7c2ce1a-5432-411a-9e4b-465b1389d499"
                                            class="prev-period-btn"
                                            @click="
                                                setShowDate(
                                                    headerProps.previousPeriod
                                                )
                                            "
                                        >
                                            &#60;
                                        </div>
                                        <div class="mode-title">
                                            {{
                                                mode === "week"
                                                    ? "This week"
                                                    : "Month"
                                            }}
                                        </div>
                                        <div
                                            id="f4b400a0-6311-4602-b122-d2491bd1330f"
                                            class="next-period-btn"
                                            @click="
                                                setShowDate(
                                                    headerProps.nextPeriod
                                                )
                                            "
                                        >
                                            >
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="mode === 'week'" #item="{ value }">
                                <div
                                    v-if="value?.originalItem?.outlookId"
                                    :class="
                                        value.classes.join(' ') +
                                        ' cv-item cv-item_outlook'
                                    "
                                    :style="{
                                        top:
                                            80 *
                                                moment(
                                                    value.originalItem.startDate
                                                ).hours() +
                                            17 +
                                            (moment(
                                                value.originalItem.startDate
                                            ).minutes() /
                                                60) *
                                                80 +
                                            'px',
                                        height:
                                            (moment(
                                                value.originalItem.endDate
                                            ).diff(
                                                value.originalItem.startDate,
                                                'minutes'
                                            ) /
                                                60) *
                                                80 +
                                            'px',
                                        paddingTop:
                                            moment(
                                                value.originalItem.endDate
                                            ).diff(
                                                value.originalItem.startDate,
                                                'minutes'
                                            ) < 16
                                                ? '2px'
                                                : '4px',
                                    }"
                                    :title="`${value.originalItem.subject} ${moment(
                                        value.originalItem.startDate
                                    ).format('HH:mm')}-${moment(
                                        value.originalItem.endDate
                                    ).format('HH:mm')}`"
                                >
                                    <img
                                        src="@/assets/icons/icon-file-type-outlook.svg"
                                        alt="icon"
                                    />
                                    <span>
                                        {{ value.originalItem.subject }}
                                    </span> 
                                    <span></span>
                                    <span>
                                        {{
                                            `${moment(
                                                value.originalItem?.startDate
                                            ).format("HH:mm")}-${moment(
                                                value.originalItem?.endDate
                                            ).format("HH:mm")}`
                                        }}</span
                                    >
                                </div>
                                <div
                                    v-if="value?.originalItem?.appointmentId"
                                    :class="[
                                        value.classes.join(' ') +
                                            ' cv-item cv-item_apointment',
                                        {
                                            'cv-item_dna':
                                                value?.originalItem?.isDna,
                                            'cv-item_cancelled':
                                                value?.originalItem
                                                    ?.isCancelled,
                                            'cv-item_attented':
                                                value?.originalItem?.isAttended,
                                            'cv-item_unconfirmed':
                                                value?.originalItem?.isOpen,
                                        },
                                    ]"
                                    :style="{
                                        width: `calc(100% / 7 - ${
                                            value?.originalItem?.shift % 2
                                        }*(100% / 14)${
                                            value?.originalItem?.shift % 2
                                                ? ''
                                                : ' - 24px)'
                                        }`,
                                        top:
                                            80 *
                                                moment(
                                                    value?.originalItem
                                                        .appointmentStart
                                                ).hours() +
                                            17 +
                                            (moment(
                                                value?.originalItem
                                                    .appointmentStart
                                            ).minutes() /
                                                60) *
                                                80 +
                                            'px',
                                        height:
                                            (moment(
                                                value?.originalItem.endDate
                                            ).diff(
                                                value?.originalItem
                                                    .appointmentStart,
                                                'minutes'
                                            ) /
                                                60) *
                                                80 +
                                            'px',
                                        marginLeft: `calc(${
                                            value?.originalItem?.shift % 2
                                        }*(100% / 14)${
                                            value?.originalItem?.shift % 2
                                                ? ''
                                                : ' + 24px)'
                                        }`,
                                    }"
                                    :title="`${
                                        value?.originalItem?.patientName
                                    } - ${
                                        value?.originalItem?.appointmentType
                                    } - ${
                                        value?.originalItem?.isDna
                                            ? 'DNA'
                                            : value?.originalItem?.isCancelled
                                            ? 'Cancelled'
                                            : value?.originalItem?.isAttended
                                            ? 'Attended'
                                            : 'Unconfirmed'
                                    } | Case: ${
                                        value?.originalItem?.referenceCode
                                    }${
                                        value?.originalItem?.appointmentNumber
                                            ? ' | Session: '
                                            : ''
                                    }${
                                        value?.originalItem
                                            ?.appointmentNumber || ''
                                    }`"
                                >
                                    <span class="cv-item_title">
                                        {{
                                            value?.originalItem?.patientName
                                        }}</span
                                    >
                                    <span class="cv-item_time">{{
                                        `${moment(
                                            value?.originalItem.appointmentStart
                                        ).format("HH:mm")}-${moment(
                                            value?.originalItem.endDate
                                        ).format("HH:mm")}`
                                    }}</span>
                                    <span class="cv-item_title">
                                        Case:
                                        {{ value?.originalItem?.referenceCode }}
                                        <span
                                            v-if="
                                                value?.originalItem
                                                    ?.appointmentNumber
                                            "
                                        >
                                            <br />
                                            Session:
                                            {{
                                                value?.originalItem
                                                    ?.appointmentNumber
                                            }}
                                        </span>
                                    </span>
                                </div>
                                <div
                                    v-if="value?.originalItem?.seriesStartDate"
                                    :class="
                                        value.classes.join(' ') +
                                        ' cv-item cv-item_availibility'
                                    "
                                    :style="{
                                        top:
                                            80 *
                                                moment(
                                                    value?.originalItem
                                                        .startDate
                                                ).hours() +
                                            17 +
                                            (moment(
                                                value?.originalItem.startDate
                                            ).minutes() /
                                                60) *
                                                80 +
                                            'px',
                                        height:
                                            (moment(
                                                value?.originalItem.endDate
                                            ).diff(
                                                value?.originalItem.startDate,
                                                'minutes'
                                            ) /
                                                60) *
                                                80 +
                                            'px',
                                    }"
                                    :title="`${
                                        value?.originalItem?.clinicName
                                    } ${moment(
                                        value?.originalItem.startDate
                                    ).format('HH:mm')}-${moment(
                                        value?.originalItem.endDate
                                    ).format('HH:mm')}`"
                                    @click="openEditAction(value?.originalItem)"
                                >
                                    <img
                                        src="@/assets/icons/icon-watch.svg"
                                        alt="icon"
                                    />
                                </div>
                            </template>
                            <template v-else #item="{ value }">
                                <div
                                    v-if="value?.originalItem?.outlookId"
                                    :class="
                                        value.classes.join(' ') +
                                        ' cv-item cv-item_outlook'
                                    "
                                    :title="`${value.originalItem.subject} ${moment(
                                        value?.originalItem.startDate
                                    ).format('HH:mm')}-${moment(
                                        value?.originalItem.endDate
                                    ).format('HH:mm')}`"
                                >
                                    <img
                                        src="@/assets/icons/icon-file-type-outlook.svg"
                                        alt="icon"
                                    />
                                    <span>
                                        {{  value.originalItem.subject }}
                                    </span>
                                    <span></span>
                                    <span>{{
                                        `${moment(
                                            value?.originalItem?.startDate
                                        ).format("HH:mm")}-${moment(
                                            value?.originalItem?.endDate
                                        ).format("HH:mm")}`
                                    }}</span>
                                </div>
                                <div
                                    v-if="value?.originalItem?.appointmentId"
                                    :class="[
                                        value.classes.join(' ') +
                                            ' cv-item cv-item_apointment',
                                        {
                                            'cv-item_dna':
                                                value?.originalItem?.isDna,
                                            'cv-item_cancelled':
                                                value?.originalItem
                                                    ?.isCancelled,
                                            'cv-item_attented':
                                                value?.originalItem?.isAttended,
                                            'cv-item_unconfirmed':
                                                value?.originalItem?.isOpen,
                                        },
                                    ]"
                                    :title="`${moment(
                                        value?.originalItem.appointmentStart
                                    ).format('HH:mm')}-${moment(
                                        value.endDate
                                    ).format('HH:mm')} ${
                                        value?.originalItem?.patientName
                                    } - ${
                                        value?.originalItem?.isDna
                                            ? 'DNA'
                                            : value?.originalItem?.isCancelled
                                            ? 'Cancelled'
                                            : value?.originalItem?.isAttended
                                            ? 'Attended'
                                            : 'Unconfirmed'
                                    } | Case: ${
                                        value?.originalItem?.referenceCode
                                    }${
                                        value?.originalItem?.appointmentNumber
                                            ? ' | Session: '
                                            : ''
                                    }${value?.originalItem?.appointmentNumber}`"
                                >
                                    <span class="cv-item_time">{{
                                        `${moment(
                                            value?.originalItem.appointmentStart
                                        ).format("HH:mm")}-${moment(
                                            value?.originalItem.endDate
                                        ).format("HH:mm")}`
                                    }}</span>
                                    <span class="cv-item_title">{{
                                        value?.originalItem?.patientName
                                    }}</span>
                                </div>
                                <div
                                    v-if="value?.originalItem?.seriesStartDate"
                                    :class="
                                        value.classes.join(' ') +
                                        ' cv-item cv-item_availibility'
                                    "
                                    :title="`${
                                        value?.originalItem?.clinicName
                                    } ${moment(
                                        value?.originalItem.startDate
                                    ).format('HH:mm')}-${moment(
                                        value?.originalItem.endDate
                                    ).format('HH:mm')}`"
                                    @click="openEditAction(value?.originalItem)"
                                >
                                    Available
                                    {{
                                        moment(
                                            value?.originalItem.startDate
                                        ).format("HH:mm")
                                    }}
                                    -
                                    {{
                                        moment(
                                            value?.originalItem.endDate
                                        ).format("HH:mm")
                                    }}
                                </div>
                            </template>
                            <template #dayContent="{ day }">
                                <div class="cv-day-item">
                                    {{
                                        mode !== "week"
                                            ? moment(day).format("D")
                                            : ""
                                    }}
                                </div>
                            </template>
                            <template
                                v-if="mode === 'week'"
                                #dayHeader="{ index }"
                            >
                                <div :class="'cv-header-day ' + index">
                                    {{
                                        moment(showDate)
                                            .add(index?.[3], "days")
                                            .format("ddd") +
                                        " " +
                                        moment(showDate)
                                            .add(index?.[3], "days")
                                            .format("DD")
                                    }}
                                </div>
                            </template>
                        </calendar-view>
                    </div>
                </div>
            </div>
        </common-layout>
        <modal-add-availibility
            :isOpen="isOpenAddAvailibility"
            @closeAddAvailibility="closeAddAvailibility"
            :id="id"
            :dateFromClick="addAvailibillityDate"
            :payloadForDispatch="payloadForDispatch"
        />
        <modal-edit-availibility
            :isOpen="isOpenEditAvailibility"
            @closeEditAvailibility="closeEditAvailibility"
            :id="id"
            :availibillityInfo="availibillityInfo"
            :payloadForDispatch="payloadForDispatch"
        />
    </div>
</template>

<script>
import CommonLayout from "../../views/CommonLayout.vue";
import SelectVue from "../../components/SelectVue/SelectVue.vue";
import { CalendarView } from "vue-simple-calendar";
import moment from "moment";
import "@/../node_modules/vue-simple-calendar/dist/css/default.css";
import "@/../node_modules/vue-simple-calendar/dist/style.css";
import "@/../node_modules/vue-simple-calendar/dist/css/holidays-us.css";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeUnmount, onMounted, watch } from "@vue/runtime-core";
import { useIntervalFn } from "@vueuse/shared";
import ScheduleBreadcrumbs from "../../components/ScheduleBreadcrumbs.vue";
import ScheduleFilter from "../../components/ScheduleFilter.vue";
import BtnVue from "../../components/BtnVue.vue";
import ModalAddAvailibility from "../../components/ModalVue/ModalAddAvailibility.vue";
import ModalEditAvailibility from "../../components/ModalVue/ModalEditAvailibility.vue";
import { toast } from "vue3-toastify";
import ClinicianSidebar from "../../components/ClinicianSidebar.vue";

export const API_DATE_LOCAL_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";

export default {
    components: {
        CommonLayout,
        SelectVue,
        CalendarView,
        ScheduleBreadcrumbs,
        ScheduleFilter,
        BtnVue,
        ModalAddAvailibility,
        ModalEditAvailibility,
        ClinicianSidebar,
    },
    setup() {
        const internalLink = process.env.VUE_APP_INTERNAL_PORTAL_URL;
        const showDate = ref(moment().startOf("week"));
        const availibillityInfo = ref();
        const isOpenAddAvailibility = ref(false);
        const isOpenEditAvailibility = ref(false);
        const mode = ref("week");
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const timelineRef = ref(null);
        const timelineDayRef = ref(null);
        const payloadForDispatch = ref(null);
        const filter = ref({ filter: "showAll" });
        const filterStructure = ref([]);
        const addAvailibillityDate = ref(new Date());
        const profileDetails = computed(() => {
            return store.getters["clinicians/clinicianDetails"];
        });
        const { pause, resume } = useIntervalFn(() => {
            const currentTime = document.querySelector("#currentTime");
            const currentTimeLine = document.querySelector(".curTime");
            if (currentTime && currentTimeLine) {
                currentTime.innerText = moment().format("HH:mm");
                currentTimeLine.style.top =
                    80 * moment().hour() + (8 / 6) * moment().minute() + "px";
            }
        }, 10000);
        const modeValues = ["week", "month", "day"];
        const id = route.query.id;

        const availibility = computed(() => {
            return store.getters["clinicians/availibility"];
        });
        const appointments = computed(() => {
            return store.getters["clinicians/appointments"];
        });
        const outlookEvents = computed(() => {
            return store.getters["clinicians/outlookEvents"];
        });
        const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);
        const dates = ref([]);
        watch(profileDetails, () => {
            if(profileDetails.value?.g4PAccountStatus === 'NotRegistered') {
                router.push({name: 'clinicians-account'})
            }
        })
        watch([availibility, appointments, filter, outlookEvents], () => {
            let appoint;
            let avail;
            let shift = 0;
            if (
                filter.value?.filter !== "newPatientAvailability" &&
                filter.value?.filter !== "showAll"
            ) {
                avail = [];
            }
            if (filter.value?.filter === "newPatientAvailability") {
                appoint = [];
                avail = availibility.value;
            } else {
                if (filter.value?.filter === "showAll") {
                    appoint = appointments.value;
                    avail = availibility.value;
                } else {
                    appoint = appointments.value.filter(
                        (data) => data?.[filter.value?.key]
                    );
                }
            }

            dates.value = [
                ...outlookEvents.value?.map((data) => ({
                    ...data,
                    outlookId: data?.startDate + data?.eventDuration,
                })),
                ...avail.map((data) => {
                    return {
                        ...data,
                    };
                }),
                ...appoint?.map((data, index) => {
                    const endDate = moment(data.appointmentStart).add(
                        data.appointmentDuration,
                        "minutes"
                    );

                    if (index) {
                        const diff =
                            moment(data.appointmentStart).diff(
                                appoint[index - 1].appointmentStart,
                                "minutes"
                            ) - appoint[index - 1].appointmentDuration;
                        if (diff < 0) {
                            shift = shift + 1;
                        } else {
                            shift = 0;
                        }
                    }
                    return {
                        ...data,
                        startDate: moment(data.appointmentStart),
                        endDate,
                        shift,
                    };
                }),
            ];
        });
        const setFilterStructure = () => {
            const defaultFilterValue = [
                {
                    title: "Attended",
                    key: "isAttended",
                    filter: "attended",
                    value: 0,
                },
                {
                    title: "Cancelled",
                    key: "isCancelled",
                    filter: "cancelled",
                    value: 0,
                },
                { title: "DNA", key: "isDna", filter: "dna", value: 0 },
                {
                    title: "Unconfirmed",
                    key: "isOpen",
                    filter: "unconfirmed",
                    value: 0,
                },
            ];
            appointments.value?.map((itemAppoint) => {
                ["isAttended", "isCancelled", "isDna", "isOpen"].forEach(
                    (item) => {
                        const keyFilter = defaultFilterValue?.find(
                            (data) => data.key === item
                        );

                        if (itemAppoint[keyFilter.key]) {
                            keyFilter.value++;
                        }
                    }
                );
            });
            filterStructure.value = defaultFilterValue;
        };
        watch(appointments, () => {
            setFilterStructure();
        });
        const updateScheduleData = (date) => {
            const localId = localStorage.getItem("clinicianId");
            const period = {
                startDate: moment(date)
                    .startOf("day")
                    .format(API_DATE_LOCAL_FORMAT),
                endDate:
                    mode.value === "day"
                        ? moment(date)
                              .endOf("day")
                              .format(API_DATE_LOCAL_FORMAT)
                        : mode.value === "week"
                        ? moment(date)
                              .add(7, "days")
                              .startOf("day")
                              .format(API_DATE_LOCAL_FORMAT)
                        : moment(date)
                              .add(1, "month")
                              .endOf("month")
                              .endOf("week")
                              .format(API_DATE_LOCAL_FORMAT),
            };
            payloadForDispatch.value = {
                clinicianId: localId,
                ...period,
            };
            store.dispatch("clinicians/setAvailibility", {
                clinicianId: localId,
                ...period,
            });
        };
        watch(showDate, () => {
            updateScheduleData(showDate.value);
        });
        watch(mode, () => {
            if (mode.value === "day") {
                showDate.value = new Date();
                setTimeout(() => {
                    timelineDayRef.value.scrollTo(0, 80 * moment().hour());
                }, 500);
            }
            if (mode.value === "week") {
                showDate.value = moment(showDate.value).startOf("week");
            }
            if (mode.value === "month") {
                addAvailibillityDate.value = new Date();
                updateScheduleData(
                    moment(moment(showDate.value).startOf("month")).startOf(
                        "week"
                    )
                );
            }
        });
        onMounted(async () => {
            setFilterStructure();
            if (id) {
                localStorage.setItem("clinicianId", id);
            }
            resume();
            const timeLine = document.createElement("ul");
            timeLine.classList.add("timeline-block");
            new Array(24).fill("1").map((_, hour) => {
                const cvHourLi = document.createElement("li");
                cvHourLi.classList.add("cv-hour");
                cvHourLi.insertAdjacentHTML(
                    "beforeend",
                    `<span> ${moment({ hour: hour }).format("HH:mm")}</span>`
                );

                timeLine.appendChild(cvHourLi);
            });
            const curTime = document.createElement("div");
            curTime.classList.add("curTime");
            curTime.style.top =
                80 * moment().hour() + (8 / 6) * moment().minute() + "px";
            curTime.insertAdjacentHTML(
                "afterbegin",
                `
          <span id="currentTime">${moment().format("HH:mm")}</span>
          `
            );
            const weekdays = timelineRef.value.querySelector(".cv-weekdays");
            weekdays.appendChild(timeLine);
            weekdays.appendChild(curTime);
            weekdays.scrollTo(0, 80 * moment().hour());
            const weekCalendar = document.querySelector(".cv-weeks");
            weekCalendar.removeAttribute("aria-multiselectable");
            if (route.query.startDate) {
                showDate.value = route.query.startDate;
            } else {
                updateScheduleData(showDate.value);
            }

            router.replace({
                query: {
                    id: undefined,
                },
            });
        });
        onBeforeUnmount(() => {
            pause();
        });
        const setModeValue = (value) => {
            mode.value = value;
        };

        const setShowDate = (d) => {
            showDate.value = d;
        };
        const setFilter = (data) => {
            filter.value = data;
        };
        const timeOptions = { hour: "2-digit", minute: "2-digit" };

        const closeAddAvailibility = () => {
            isOpenAddAvailibility.value = false;
        };
        const closeEditAvailibility = () => {
            isOpenEditAvailibility.value = false;
        };

        const openEditAction = (value) => {
            if (
                isSuperAdmin.value &&
                profileDetails.value?.isActive
            ) {
                if (moment(value?.startDate).isAfter(moment())) {
                    availibillityInfo.value = value;

                    isOpenEditAvailibility.value = true;
                } else {
                    toast.warning("Cannot edit Availability for a past date");
                }
            }
        };

        const clickDate = (date, _, windowEvent) => {
            if (
                isSuperAdmin.value &&
                profileDetails.value?.isActive
            ) {
                let formattedAvailibillityDate;
                if (mode.value === "week") {
                    const weekCalendar = document.querySelector(".cv-weekdays");
                    const clickedHour =
                        (windowEvent?.offsetY +
                            weekCalendar?.offsetTop +
                            weekCalendar?.scrollTop) /
                            80 -
                        0.19;
                    const clickedMinutes =
                        (clickedHour.toFixed(2) + "").split(".")?.[1] * 0.6;

                    formattedAvailibillityDate = moment(date)
                        .set("hours", clickedHour)
                        .set("minutes", clickedMinutes);
                }

                if (mode.value === "month") {
                    formattedAvailibillityDate = date;
                    addAvailibillityDate.value = new Date();
                }
                if (mode.value === "day") {
                    const clickedHour = date;

                    const clickedMinutes =
                        (windowEvent?.offsetY / 80) * 60 + "";
                    formattedAvailibillityDate = moment(showDate.value)
                        .set("hours", clickedHour)
                        .set("minutes", clickedMinutes);
                }

                if (moment(formattedAvailibillityDate).isAfter(moment())) {
                    addAvailibillityDate.value = formattedAvailibillityDate;
                    isOpenAddAvailibility.value = true;
                } else {
                    toast.warning("Cannot create Availability for a past date");
                }
            }
        };

        return {
            showDate,
            setShowDate,
            mode,
            modeValues,
            setModeValue,
            timeOptions,
            log: console.log,
            moment,
            availibility,
            timelineRef,
            timelineDayRef,
            setFilter,
            dates,
            profileDetails,
            filterStructure,
            filter,
            isOpenAddAvailibility,
            closeAddAvailibility,
            isOpenEditAvailibility,
            closeEditAvailibility,
            id: id || localStorage.getItem("clinicianId"),
            clickDate,
            addAvailibillityDate,
            router,
            availibillityInfo,
            openEditAction,
            payloadForDispatch,
            internalLink,
            isSuperAdmin,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Clinitians.scss";
@import "@/assets/scss/pages/ClinicianSchedule.scss";
@import "@/assets/scss/pages/ClinitianProfile.scss";
</style>

