<template>
  <modal-layout v-if="isOpen">
    <div class="modal-block" ref="target">
      <div class="modal-closable-title-wrapper">
        <div class="modal-title-wrapper">
          <img
            src="@/assets/icons/icon-info.svg"
            alt="icon"
            title="Acceptable file format – txt, csv"
          />
          <h3 v-if="isListExist" class="modal-title">
            Uploaded Report Group List
          </h3>
          <h3 v-else class="modal-title">Upload Report Group List</h3>
        </div>

        <div class="modal-close-wrapper" @click="close">
          <img src="@/assets/icons/icon-close.svg" alt="icon" />
        </div>
      </div>

      <modal-separator />
      <div v-if="isListExist" class="modal-content modal-content--photo">
        <div class="modal-content-row">
          <label class="modal-content-row-label">Current file</label>
          <div class="row-current-photo">
            <img :src="file" alt="clinician photo" />
          </div>
        </div>
      </div>
      <div v-else class="modal-content modal-content--photo">
        <div class="modal-content-row">
          <label class="modal-content-row-label"
            >Add a CSV containing the Report Group names to be created</label
          >
        </div>
        <div class="modal-content-row" v-if="selectedFile">
          <div class="selected-file-name">
            {{ selectedFile }}
          </div>
          <div @click="selectedFile = ''" class="remove-file-link">
            Clear file
          </div>
        </div>
        <div class="modal-content-row" v-else>
          <label class="modal-content-row-label">Browse here</label>
          <div class="row-browse-photo">
            <form :action="onFileChange" enctype="multipart/form-data">
              <input type="file" @change="onFileChange" />
            </form>
            <img src="@/assets/icons/icon-upload.svg" alt="browse icon" />
          </div>
        </div>
      </div>
      <modal-separator />
      <div class="btn-block">
        <btn-vue
          id="11ebe460-bb3e-4914-9041-262ceba9aebf"
          :type="'secondary-btn'"
          @click="close"
          >Cancel</btn-vue
        >
        <btn-vue
          v-if="isListExist"
          id="171c8c3c-3249-4cd3-8344-2a66b769a9d8"
          :type="'primary-btn--blue'"
          @click="clear"
          >Replace list</btn-vue
        >
        <btn-vue
          v-else
          id="171c8c3c-3249-4cd3-8344-2a66b769a9d8"
          :type="'primary-btn--blue'"
          @click="uploadList"
          >Upload CSV</btn-vue
        >
      </div>
    </div>
  </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import { toast } from "vue3-toastify";

export default {
  components: {
    ModalLayout,
    BtnVue,
    ModalSeparator,
  },
  props: {
    isOpen: Boolean,
    listFile: String,
    id: String,
  },
  emits: {
    closeuploadList: Function,
  },
  setup(props, context) {
    const store = useStore();
    const selectedFile = ref("");
    const selectedFileFormData = ref();
    const { listFile } = toRefs(props);
    const isListExist = ref(false);
    const target = ref();
    onClickOutside(target, () => context.emit("closeuploadList"));
    watch(listFile, () => {
      if (listFile.value) {
        isListExist.value = true;
      }
    });
    const onFileChange = (event) => {
      const file = event.target.files[0];
      selectedFileFormData.value = event.target.files[0];
      selectedFile.value = file?.name;
    };
    const close = () => {
      if (listFile.value) {
        isListExist.value = true;
      }
      selectedFile.value = "";
      context.emit("closeuploadList");
    };
    const clear = () => {
      isListExist.value = false;
    };
    const uploadList = async () => {
      const objFile = selectedFileFormData.value;
      if (objFile) {
        const resp = await store.dispatch("report/uploadReportGroups", {
          file: objFile,
        });
        if (resp?.status === 409) {
          toast.error(
            `Failed to upload the Report Group list - ${resp.data
              ?.split(",")
              .join(", ")} Name already exists`
          );
        } else {
          if (resp?.status === 200) {
            toast.success("Report groups successfully uploaded");
            context.emit("closeuploadList", true);
            selectedFile.value = "";
            store.dispatch("report/setReportGroups");
          } else {
            toast.error("Failed to upload the Report Group list");
          }
        }
      }
    };

    return {
      uploadList,
      selectedFile,
      onFileChange,
      file: listFile,
      clear,
      isListExist,
      close,
      target,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

