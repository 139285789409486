<template>
  <div class="schedule-bread-crumbs" :class="disabled ? 'disabled' : ''">
    <template v-if="type === 'profile-clinic'">
      <img
        src="@/assets/icons/icon-calendar-check.svg"
        alt="calendar check icon"
      />
      <span class="bread-crumbs-title">Clinic Details / </span>

      <div class="bread-crumbs-select">
        <div
          :style="{
            display: isShow ? 'block' : 'none',
          }"
        >
          <search-select
            style="z-index: 6"
            :options="cliniciansList"
            :withSearch="true"
            @switchProfile="switchProfile"
            :isClinic="isClinic"
          />
        </div>
        <span
          :style="{
            display: !isShow ? 'block' : 'none',
          }"
          class="bread-crumbs-selected-title"
          @click="isShow = true"
        >
          {{ profileName }}
        </span>
      </div>
    </template>
    <template v-if="type === 'profile'">
      <img
        src="@/assets/icons/icon-calendar-check.svg"
        alt="calendar check icon"
      />
      <span class="bread-crumbs-title">Clinician Details / </span>
      <div v-if="isLoading">
        <loader-vue />
      </div>
      <div v-else class="bread-crumbs-select">
        <div
          :style="{
            display: isShow ? 'block' : 'none',
          }"
        >
          <search-select
            style="z-index: 6"
            :options="cliniciansList"
            :withSearch="true"
            @switchProfile="switchProfile"
          />
        </div>
        <span
          :style="{
            display: !isShow ? 'block' : 'none',
          }"
          class="bread-crumbs-selected-title"
          @click="isShow = true"
        >
          {{ profileName }}
        </span>
      </div>
    </template>
    <template v-if="type === 'schedule'">
      <img
        src="@/assets/icons/icon-home-lg-alt.svg"
        alt="calendar check icon"
      />
      <span
        class="bread-crumbs-title"
        @click="
          router.push({
            name: 'home',
          })
        "
        >Home</span
      >
      <span>/</span>

      <img
        src="@/assets/icons/icon-calendar-alt.svg"
        alt="calendar check icon"
      />
      <span class="bread-crumbs-title" @click="router.go(-1)">Clinician</span>
      <span>/</span>

      <img
        src="@/assets/icons/icon-calendar-check-color.svg"
        alt="calendar check icon"
      />
      <span class="bread-crumbs-title--color">{{ info.title }} </span>
    </template>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";
import SearchSelect from "./SelectVue/SearchSelectSwitch.vue";
import LoaderVue from "./LoaderVue.vue";

export default {
  props: {
    disabled: Boolean,
    isClinic: Boolean,
    type: String,
    profileName: String,
    info: Object,
    cliniciansList: Array,
  },
  components: {
    SearchSelect,
    LoaderVue,
  },
  emits: {
    setIsLoading: Function,
  },
  setup(_, context) {
    const router = useRouter();
    const isShow = ref(false);
    const switchProfile = async (id) => {
      isShow.value = false;
      if (id) {
        context.emit("set-is-loading", id);
      }
    };
    return { router, switchProfile, isShow };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/breadcrumbs.scss";
</style>

