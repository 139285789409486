<template>
    <modal-layout v-if="isOpen">
        <div class="modal-block" ref="target">
            <div class="modal-closable-title-wrapper">
                <div class="modal-title-wrapper">
                    <img src="@/assets/icons/icon-add.svg" alt="icon" />
                    <h3 class="modal-title">Add Availability Slot</h3>
                </div>
                <div
                    class="modal-close-wrapper"
                    @click="$emit('closeAddAvailibility')"
                >
                    <img src="@/assets/icons/icon-close.svg" alt="icon" />
                </div>
            </div>

            <modal-separator />
            <div class="modal-content">
                <div class="modal-content-row">
                    <label class="modal-content-row-label">Location</label>
                    <search-select
                        id="eff696f7-df50-4445-8a95-f4870b312ada"
                        :selectedDefault="location"
                        :options="locations"
                        @setSelectedAction="setLocationAction"
                        :style="{ zIndex: 6 }"
                    ></search-select>
                </div>
                <div class="modal-content-row modal-content-row--datetime">
                    <div>
                        <label class="modal-content-row-label">Date</label>
                        <date-vue
                            id="7f3bd661-963a-439f-8c1d-5a3d07b6d13a"
                            :date="date"
                            :setDate="setDate"
                            :minDate="moment().format('yyyy-MM-DD')"
                        />
                    </div>
                    <div>
                        <label class="modal-content-row-label">From</label>
                        <custom-time-vue
                            id="2e22b9a5-198a-46e3-b84b-255977c30bc8"
                            :time="timeStartFormatted"
                            @setTime="setStartDate"
                        />
                    </div>
                    <div>
                        <label class="modal-content-row-label">To</label>
                        <custom-time-vue
                            id="8eeeaa0f-d046-4199-8014-add97c4a85f3"
                            :time="timeEndFormatted"
                            @setTime="setEndDate"
                            :minDate="timeStartFormatted"
                        />
                    </div>
                </div>
                <div class="modal-content-row">
                    <label class="modal-content-row-label">Recurrence</label>
                    <select-vue
                        id="9cff775c-d536-467e-bfb9-320a0928dd0a"
                        :selectedDefault="selectedRecurrence"
                        :options="recurrence"
                        :setSelectedAction="setRecurrenceAction"
                    ></select-vue>
                </div>
                <div
                    v-if="selectedRecurrence !== 'Does not repeat'"
                    class="modal-content-row modal-content-row--recurrence"
                >
                    <div>
                        <label class="modal-content-row-label"
                            >Recurrence type</label
                        >
                        <select-vue
                            id="85624c6e-02f2-4509-8751-efd3c683db53"
                            :selectedDefault="selectedRecurrenceType"
                            :options="recurrenceType"
                            :setSelectedAction="setRecurrenceType"
                        ></select-vue>
                    </div>
                    <div v-if="selectedRecurrenceType !== 'Repeats until date'">
                        <label class="modal-content-row-label"
                            >Occurrences</label
                        >
                        <input-vue
                            id="19f05915-b74f-4fd2-85b6-9a6ab484bbf4"
                            @setText="setOccurences"
                            :value="occurences"
                            :type="'number'"
                        />
                    </div>
                    <div v-else>
                        <label class="modal-content-row-label">End date</label>
                        <date-vue
                            id="9ae6c3eb-8868-43ce-9d7c-d0c1cc2e2a96"
                            :date="endDate"
                            :setDate="setEndRequestDate"
                            :minDate="moment(date).format('yyyy-MM-DD')"
                        />
                    </div>
                </div>
            </div>
            <modal-separator />
            <div class="btn-block">
                <btn-vue
                    id="e1409048-9cc2-4dc9-8f08-0e360cedd68b"
                    :type="'secondary-btn'"
                    @click="$emit('closeAddAvailibility')"
                    >Cancel</btn-vue
                >
                <btn-vue
                    id="c09497a2-8cef-465c-a3db-53ba8350e680"
                    :type="'primary-btn--blue'"
                    @click="sendAddAvailibillityRequest"
                    >Add</btn-vue
                >
            </div>
        </div>
    </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import DateVue from "./../InputVue/DateVue.vue";
import InputVue from "./../InputVue/InputVue.vue";
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { useStore } from "vuex";
import SelectVue from "./../SelectVue/SelectVue.vue";
import SearchSelect from "./../SelectVue/SearchSelect.vue";
import moment from "moment";
import { toast } from "vue3-toastify";
import { onClickOutside } from "@vueuse/core";
import { API_DATE_LOCAL_FORMAT as API_DATE_FORMAT } from "../../pages/Clinicians/CliniciansSchedulePage.vue";
// import CustomTimeVue from "../InputVue/CustomTimeVue.vue";
// import TimeVue from "../InputVue/TimeVue.vue";
import CustomTimeVue from "../InputVue/CustomTimeVue.vue";

export default {
    components: {
        ModalLayout,
        BtnVue,
        ModalSeparator,
        SelectVue,
        SearchSelect,
        DateVue,
        InputVue,
        CustomTimeVue,
    },
    props: {
        isOpen: Boolean,
        id: String,
        dateFromClick: Date,
        payloadForDispatch: Object,
    },
    emits: {
        closeAddAvailibility: Function,
    },
    setup(props, context) {
        const store = useStore();
        const date = ref(moment().format("yyyy-MM-DD"));
        const endDate = ref();
        const { dateFromClick, payloadForDispatch } = toRefs(props);
        const timeStart = ref(moment());
        const target = ref();
        onClickOutside(target, () => context.emit("closeAddAvailibility"));
        const timeStartFormatted = ref(
            moment().format("HH:mm").split(":")?.[0] +
                ":" +
                (Math.round(moment().minutes() / 5) * 5 || "00")
        );
        const timeEndFormatted = ref(
            moment().add(1, "hour").format("HH:mm").split(":")?.[0] +
                ":" +
                (Math.round(moment().minutes() / 5) * 5 || "00")
        );
        // (Math.round(moment().minutes() / 15) * 15 || "00") --- remove
        const setStartDate = (date) => {
            timeStartFormatted.value = date;
            timeEndFormatted.value = moment()
                .set("hours", date?.split(":")?.[0])
                .set("minutes", date?.split(":")?.[1])
                .add(1, "hour")
                .format("HH:mm");
        };
        const setEndDate = (date) => {
            timeEndFormatted.value = date;
        };

        const recurrence = ref([
            "Does not repeat",
            "Repeats weekly on a " + moment(date.value).format("dddd"),
            "Repeats monthly",
        ]);
        const occurences = ref(0);
        const recurrenceType = ref([
            "Number of occurrences",
            "Repeats until date",
        ]);
        const selectedRecurrence = ref("Does not repeat");
        const selectedRecurrenceType = ref("Repeats until date");
        const location = ref("Please choose a Clinic");
        const timeEnd = ref(new Date());
        const locations = computed(() => {
            return store.getters["clinicians/associatedClinics"]?.map(
                (data) => data.name
            );
        });
        watch(date, () => {
            recurrence.value = [
                "Does not repeat",
                "Repeats weekly on a " + moment(date.value).format("dddd"),
                "Repeats monthly",
            ];
        });
        watch(
            dateFromClick,
            () => {
                date.value = moment(dateFromClick.value).format("yyyy-MM-DD");
                const minutes =
                    Math.round(moment(dateFromClick.value).minutes() / 5) * 5;
                timeStart.value = moment(dateFromClick.value).set(
                    "minutes",
                    minutes
                );
                timeStartFormatted.value = moment(dateFromClick.value)
                    .set("minutes", minutes)
                    .format("HH:mm");
                timeEndFormatted.value = moment(dateFromClick.value)
                    .add(1, "hour")
                    .set("minutes", minutes)
                    .format("HH:mm");
            },
            {
                deep: true,
            }
        );
        watch(
            locations,
            () => {
                if (locations.value.length === 1) {
                    location.value = locations.value[0];
                }
            },
            {
                deep: true,
            }
        );

        onMounted(() => {
            store.dispatch("clinicians/setAssociatedClinics", props.id);
        });
        const setDate = (data) => {
            date.value = data;
        };
        const setEndRequestDate = (data) => {
            endDate.value = data;
        };
        const setLocationAction = (data) => {
            location.value = data;
        };
        const setRecurrenceAction = (data) => {
            selectedRecurrence.value = data;
        };
        const setRecurrenceType = (data) => {
            selectedRecurrenceType.value = data;
        };
        const setOccurences = (data) => {
            occurences.value = data;
        };

        const sendAddAvailibillityRequest = async () => {
            const timeStart = moment(timeStartFormatted.value, "HH:mm");
            const timeEnd = moment(timeEndFormatted.value, "HH:mm");
            if (
                moment()
                    .startOf("day")
                    .utcOffset("+00:00")
                    .isAfter(moment(date.value).utcOffset("+00:00"))
            ) {
                toast.warning("Cannot create Availability for a past date");
                return;
            }
            if (timeStart.isAfter(timeEnd)) {
                toast.error("Time from can't be before time to");
                return;
            }
            if (timeStartFormatted.value == timeEndFormatted.value) {
                toast.error("Time from can't be the same as time to");
                return;
            }
            if (location.value === "Please choose a Clinic") {
                toast.error("You must specify a clinic");
                return;
            }
            let recurrenceFrequency = "none";
            if (selectedRecurrence.value?.includes("monthly")) {
                recurrenceFrequency = "monthly";
            }
            if (selectedRecurrence.value?.includes("weekly")) {
                recurrenceFrequency = "weekly";
            }
            let payload = {
                clinicianId: props.id,
                clinicId: location.value,
                startDate: moment(date.value).format(API_DATE_FORMAT),
                startTime:
                    moment(timeStartFormatted.value, "HH:mm").format("HH:mm") +
                    ":00",
                endTime:
                    moment(timeEndFormatted.value, "HH:mm").format("HH:mm") +
                    ":00",
                recurrenceFrequency,
            };
            if (occurences.value) {
                payload = { ...payload, recurrenceCount: occurences.value };
            }
            if (endDate.value) {
                payload = {
                    ...payload,
                    endDate: moment(endDate.value).format(API_DATE_FORMAT),
                };
            }
            const status = await store.dispatch("clinicians/addAvailibillity", {
                ...payload,
                payloadForDispatch: payloadForDispatch.value,
            });
            if (status === 200) {
                context.emit("closeAddAvailibility", true);
            }
        };
        return {
            target,
            date,
            timeStart,
            timeEnd,
            setLocationAction,
            recurrence,
            setRecurrenceAction,
            selectedRecurrence,
            recurrenceType,
            setRecurrenceType,
            selectedRecurrenceType,
            occurences,
            setOccurences,
            locations,
            location,
            moment,
            timeStartFormatted,
            timeEndFormatted,
            setStartDate,
            setEndDate,
            setDate,
            sendAddAvailibillityRequest,
            setEndRequestDate,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

