<template>
  <!-- eslint-disable -->
  <div class="availability-table">
    <div
      class="filter-warning-message"
      @click="itemsSelected?.length ? goToSearchReservation(true) : undefined"
    >
      <h2>
        Next Available Slot:&nbsp;
        <span v-if="nextAvailaibleSlot">{{
          moment(nextAvailaibleSlot).format("HH:mm&nbsp; DD/MM/YYYY")
        }}</span>
      </h2>
    </div>
    <div class="availability-table-header">
      <div class="availability-table-header-row">
        <label for="1cd4cb90-e337-4485-bc36-e0c6c1552e8e" class="select-label"
          >Search By Name</label
        >
        <input-vue
          role="search"
          :searchValue="searchName"
          @setText="setSearchName"
          :placeholder="'enter here'"
          id="1cd4cb90-e337-4485-bc36-e0c6c1552e8e"
        />
      </div>

      <div class="availability-table-header-row">
        <label for="b9925479-7dc3-48e9-bbc1-b5d236c4257e" class="select-label"
          >Select All</label
        >
        <div @click="selectAllItems()">
          <input
            id="b9925479-7dc3-48e9-bbc1-b5d236c4257e"
            type="checkbox"
            class="select-checkbox"
            :data-checked="
              Boolean(
                itemsSelected?.length === clinicList?.length &&
                  itemsSelected?.length
              )
            "
            :checked="
              itemsSelected?.length === clinicList?.length &&
              itemsSelected?.length
            "
          />
        </div>
      </div>
    </div>

    <data-table
      v-if="!loadingFilters"
      :headers="headers"
      :items="clinicList || []"
      :loading="loading"
      :search-field="['clinicianName']"
      :rows-items="[10, 20, 30]"
      :rows-per-page="10"
      :sort-by="sortBy"
      :sort-type="sortType"
      :current-page="currentPage"
    >
      <template #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }">
        <button
          class="icon-btn-containner icon-btn-prev"
          :disabled="isFirstPage"
          @click="prevPage"
          role="none"
          aria-label="previous page"
        ></button>
        <button
          class="icon-btn-containner"
          :disabled="isLastPage"
          @click="nextPage"
          role="none"
          aria-label="next page"
        ></button>
      </template>
      <template #item-clinicianName="{ clinicianName }">
        <div style="padding-left: 18px">
          {{ clinicianName }}
        </div>
      </template>
      <template #item-distanceInMiles="{ distanceInMiles }">
        {{ distanceInMiles || "-" }}
      </template>
      <template #item-nextSlotTime="{ nextSlotTime }">
        {{ moment(nextSlotTime).format("HH:mm&nbsp;&nbsp;&nbsp;DD/MM/YYYY") }}
      </template>
      <template #item-select="item">
        <div @click="selectItem(item)" class="select-td">
          <input
            aria-label="checkbox"
            role="presentation"
            type="checkbox"
            class="select-checkbox"
            :data-checked="
              Boolean(
                itemsSelected?.find((data) => data.slotId === item.slotId)
              )
            "
            :checked="
              Boolean(
                itemsSelected?.find((data) => data.slotId === item.slotId)
              )
            "
          />
        </div>
      </template>
    </data-table>
    <data-table
      v-else
      :headers="headers"
      :items="[]"
      :loading="true"
      :search-field="['clinicianName']"
      :rows-items="[10, 20, 30]"
      :rows-per-page="10"
    />
    <div class="table-btn-wrapper">
      <btn-vue
        :isDisabled="!itemsSelected?.length"
        @click="goToSearchReservation()"
        :type="'primary-btn primary-btn-shadow'"
        >Next</btn-vue
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { computed, defineComponent, ref, watch, toRefs, onMounted } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useStore } from "vuex";
import BtnVue from "./BtnVue.vue";
import InputVue from "./InputVue/InputVue.vue";

export default defineComponent({
  props: {
    durationValue: Number,
    includeSelected: String,
    clinicTypeSelected: String,
    appointmentsSelected: String,
    specialismsSelected: Array,
    clinicianTypeSelected: Object,
    searchPostcodeValue: String,
    searchDistanceValue: Number,
    clinicsSelected: Object,
  },
  emits: {
    setFilterToStorage: Function,
  },
  components: {
    InputVue,
    BtnVue,
  },
  setup(props, context) {
    const {
      durationValue,
      includeSelected,
      clinicTypeSelected,
      appointmentsSelected,
      specialismsSelected,
      clinicianTypeSelected,
      searchPostcodeValue,
      searchDistanceValue,
      clinicsSelected,
    } = toRefs(props);
    const router = useRouter();
    const store = useStore();

    const cliniciansServerList = computed(
      () => store.getters["availability/cliniciansList"]
    );

    const clinics = computed(() => store.getters["availability/clinicsList"]);
    const cliniciansSelectedList = computed(
      () => store.getters["availability/cliniciansSelectedList"]
    );
    const clinicList = ref(cliniciansServerList.value || []);
    const sortBy = ref("employmentStatus");
    const sortType = ref("asc");
    const itemsSelected = ref([]);
    const currentPage = ref(1);
    const loadingFilters = ref(false);

    const loading = ref(false);
    const nextAvailaibleSlot = ref("");
    const searchName = ref("");
    const setSearchName = (text) => {
      loadingFilters.value = true;
      searchName.value = text;
      clinicList.value = cliniciansServerList.value.filter((value) =>
        value.clinicianName
          ?.toLocaleLowerCase()
          ?.includes(text.toLocaleLowerCase())
      );
      itemsSelected.value = clinicList.value;
      setTimeout(() => {
        loadingFilters.value = false;
      }, 300);
    };

    const loadFromServer = async () => {
      if (clinicianTypeSelected?.value?.length && appointmentsSelected?.value) {
        const startDate = moment().format("YYYY-MM-DD");
        const endDate = moment().add(1, "month").format("YYYY-MM-DD");
        let clinicSystemIds = [];
        if (clinics.value?.length !== clinicsSelected.value?.length) {
          clinicSystemIds = clinics.value
            ?.filter((data) => clinicsSelected.value?.includes(data.name))
            ?.map((data) => data?.systemId);
        }
        let appointmentType = appointmentsSelected.value.toLocaleLowerCase();

        let clinicFilter = clinicTypeSelected.value.toLocaleLowerCase();
        let clinicianTypes = clinicianTypeSelected.value?.map((data) => {
          let apiType = data?.replace(" ", "")?.toLocaleLowerCase();
          if (apiType === "cbttherapist") {
            apiType = "cbtTherapist";
          }
          if (apiType === "notset") {
            apiType = "notSet";
          }
          return apiType;
        });
        if (clinicTypeSelected.value === "In-person") {
          clinicFilter = "inPerson";
        }
        if (appointmentsSelected.value === "Face to Face") {
          appointmentType = "f2f";
        }
        if (appointmentsSelected.value === "Telephone & Video") {
          appointmentType = "telephoneAndVideo";
        }

        loadingFilters.value = true;
        currentPage.value = 1;
        loading.value = true;
        const responseStatus = await store.dispatch(
          "availability/setCliniciansList",
          {
            duration: durationValue.value,
            clinicianSlotListFilter: {
              employmentStatusFilter:
                includeSelected.value?.toLocaleLowerCase(),
              clinicFilter,
              clinicianTypes,
              appointmentFilter: appointmentType,
              specialisms: specialismsSelected.value,
              searchByClinicianName: searchName.value,
              searchByClinicName: "",
              postCode: searchPostcodeValue.value,
              clinicDistance: searchDistanceValue.value,
            },
            useAllClinics: !clinicSystemIds.length,
            startDate,
            sortBy: "slot",
            endDate,
            clinicSystemIds,
          }
        );
        if (responseStatus) {
          loading.value = false;
        }
        loadingFilters.value = false;
      }
    };

    watch(
      searchPostcodeValue,
      () => {
        sortBy.value = "distanceInMiles";
        sortType.value = "asc";
      },
      { deep: true }
    );

    watch(
      cliniciansServerList,
      () => {
        clinicList.value = cliniciansServerList.value;
      },
      { deep: true }
    );

    watch(
      clinicsSelected,
      () => {
        if (clinics.value.length !== clinicsSelected.value.length) {
          loadFromServer();
        }
      },
      { deep: true }
    );

    watch(
      [
        durationValue,
        includeSelected,
        clinicTypeSelected,
        appointmentsSelected,
        specialismsSelected,
        clinicianTypeSelected,
        searchDistanceValue,
        searchPostcodeValue,
      ],
      () => {
        loadFromServer();
      },
      { deep: true }
    );

    watch(
      cliniciansServerList,
      () => {
        itemsSelected.value = cliniciansServerList?.value;
        const item = cliniciansServerList.value?.[0];
        nextAvailaibleSlot.value = item?.nextSlotTime;
      },
      { deep: true }
    );

    const headers = [
      { text: "Clinician Name", value: "clinicianName", sortable: true },
      { text: "Employed Type", value: "employmentStatus", sortable: true },
      {
        text: "Clinician Type",
        value: "hcpType",
        sortable: true,
      },
      { text: "Location", value: "clinicLocation", sortable: true },
      { text: "Distance", value: "distanceInMiles", sortable: true },
      { text: "Next Slot", value: "nextSlotTime", sortable: true },
      { text: "Select", value: "select" },
    ];

    const sortIcon = (column) => {
      if (!column.sortable) return "";
      return column.sortDirection === "asc" ? "fa-sort-up" : "fa-sort-down";
    };

    const selectItem = (item) => {
      if (itemsSelected.value?.find((data) => item.slotId === data.slotId)) {
        itemsSelected.value = itemsSelected.value?.filter(
          (data) => item.slotId !== data.slotId
        );
      } else {
        itemsSelected.value = [...itemsSelected.value, item];
      }
    };

    const selectAllItems = () => {
      if (itemsSelected.value?.length !== clinicList?.value?.length) {
        itemsSelected.value = clinicList?.value;
      } else {
        itemsSelected.value = [];
      }
    };

    onMounted(() => {
      window.onbeforeunload = function () {
        sessionStorage.removeItem("searchName");
      };
      const filter = sessionStorage.getItem("filter");

      if (!filter) {
        loadFromServer();
      } else {
        itemsSelected.value = cliniciansSelectedList.value;
        const savedSearchName = sessionStorage.getItem("searchName");
        if (savedSearchName) {
          searchName.value = savedSearchName;
          clinicList.value = cliniciansServerList.value.filter((value) =>
            value.clinicianName
              ?.toLocaleLowerCase()
              ?.includes(savedSearchName.toLocaleLowerCase())
          );
        }

        const item = clinicList.value?.[0];
        nextAvailaibleSlot.value = item?.nextSlotTime;
      }
    });
    onBeforeRouteLeave((to) => {
      if (to.path === "/availability/reservation") {
        store.commit(
          "availability/setCliniciansSelectedList",
          itemsSelected.value
        );
        sessionStorage.setItem("searchName", searchName.value);
      }
      if (to.path !== "/availability/reservation") {
        sessionStorage.removeItem("searchName");
      }
    });
    const goToSearchReservation = (nextSlot) => {
      context.emit("set-filter-to-storage");
      router.push({
        name: "availability-reservation",
        query: { nextSlot },
      });
    };

    return {
      goToSearchReservation,
      selectAllItems,
      setSearchName,
      searchName,
      headers,
      loading,
      sortIcon,
      router,
      clinicList,
      itemsSelected,
      selectItem,
      clinics,
      moment,
      nextAvailaibleSlot,
      sortBy,
      sortType,
      currentPage,
      loadingFilters,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

