<template>
  <div class="select-wrapper">
    <div ref="target" class="select-container">
      <div
        @click="this.isOpen = !this.isOpen"
        class="selected-option"
        :class="{ 'selected-option--open': this.isOpen }"
      >
        <span
          :class="withIcons ? selected?.replace(/[ |&]/g, '_') : ''"
          :style="placeholderText && !selected ? { color: '#9E9E9E' } : null"
        >
          {{ this.selected || placeholderText }}
        </span>
        <img
          src="@/assets/icons/select-icon.svg"
          alt="icon"
          class="select-arrow-icon"
          :class="{ 'select-arrow-icon--inverted': this.isOpen }"
        />
      </div>
      <ul v-if="isOpen">
        <li
          v-for="(option, index) in optionsFiltered"
          :key="index"
          @click="this.setSelected(option)"
          class="option"
          :class="withIcons ? option?.replace(/[ |&]/g, '_') : ''"
        >
          <span>{{ option }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onClickOutside } from "@vueuse/core";
import { toRefs } from "vue";

export default {
  props: {
    options: Array,
    selectedDefault: String,
    placeholderText: String,
    setSelectedAction: Function,
    width: String,
    withIcons: Boolean,
    icons: Array,
  },
  setup(props) {
    const { selectedDefault: selected } = toRefs(props);
    const optionsFiltered = computed(() =>
      props.options.filter((item) => item !== selected.value)
    );
    const isOpen = ref(false);
    const target = ref(null);
    onClickOutside(target, () => (isOpen.value = false));
    const setSelected = (text) => {
      props.setSelectedAction(text);
      selected.value = text;
      isOpen.value = false;
    };
    return { selected, setSelected, isOpen, optionsFiltered, target };
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/select.scss";
</style>