<template>
  <!-- eslint-disable -->
  <div class="report-data-table">
    <data-table
      :headers="headers"
      :items="reportGroups || []"
      :loading="loading"
      :rows-items="[10, 20, 30]"
      :rows-per-page="10"
      :search-value="searchName"
      :search-field="['name']"
      :sort-by="sortBy"
      :sort-type="sortType"
    >
      <template #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }">
        <button
          class="icon-btn-containner icon-btn-prev"
          :disabled="isFirstPage"
          @click="prevPage"
          role="none"
          aria-label="previous page"
        ></button>
        <button
          class="icon-btn-containner"
          :disabled="isLastPage"
          @click="nextPage"
          role="none"
          aria-label="next page"
        ></button>
      </template>
      <template #item-name="{ name, id, clinicNames }">
        <div class="edit-container">
          <edit-btn
            title="Edit Profile"
            @click="setEditGroup(name, id, clinicNames)"
          />
          {{ name }}
        </div>
      </template>
      <template #item-delete="{ name, id, clinicNames }">
        <div class="btn-remove-wrapper">
          <div
            class="btn-remove"
            @click="setDeleteGroup(name, id, clinicNames)"
          >
            <img src="@/assets/icons/icon-delete.svg" alt="icon" />
          </div>
        </div>
      </template>
    </data-table>
    <modal-delete-report-group
      @closeAddReport="() => (openDeleteReport = false)"
      :isOpen="openDeleteReport"
      :info="editGroup"
    />
    <modal-edit-report-group
      @closeAddReport="() => (openEditReport = false)"
      :isOpen="openEditReport"
      :info="editGroup"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import EditBtn from "./EditBtn.vue";
import ModalDeleteReportGroup from "./ModalVue/ModalDeleteReportGroup.vue";
import ModalEditReportGroup from "./ModalVue/ModalEditReportGroup.vue";

export default defineComponent({
  props: {
    searchName: String,
  },
  emits: { openEditModal: Function },
  components: {
    EditBtn,
    ModalEditReportGroup,
    ModalDeleteReportGroup,
  },
  setup() {
    const store = useStore();
    const reportGroups = computed(() => {
      return store.getters["report/reportGroups"];
    });
    const sortBy = ref("name");
    const sortType = ref("ask");
    const loading = ref(false);
    const openEditReport = ref(false);
    const openDeleteReport = ref(false);
    const editGroup = ref({});
    const loadFromServer = async () => {
      loading.value = true;

      await store.dispatch("report/setReportGroups");

      loading.value = false;
    };

    onMounted(() => {
      loadFromServer();
    });

    const headers = [
      { text: "Report Groups", value: "name", sortable: true },
      { text: "", value: "delete", sortable: false },
    ];

    const setEditGroup = (name, id, clinicNames) => {
      editGroup.value = { name, id, clinicNames };
      openEditReport.value = true;
    };

    const setDeleteGroup = (name, id, clinicNames) => {
      editGroup.value = { name, id, clinicNames };
      openDeleteReport.value = true;
    };

    return {
      headers,
      loading,
      reportGroups,
      openEditReport,
      editGroup,
      setEditGroup,
      setDeleteGroup,
      openDeleteReport,
      sortBy,
      sortType,
    };
  },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

