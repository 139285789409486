<template>
    <common-layout>
        <div class="clinic-wrapper">
            <div class="clinitians-filter-wrapper">
                <h2 class="clinitians-title">Healthcare Clinics</h2>

                <div class="clinitians-filter-block">
                    <div>
                        <h4 class="select-label">Status</h4>
                        <select-vue
                            id="61c5f1ca-f94f-4aff-b9ca-2adee37c4c2a"
                            :selectedDefault="statusSelected"
                            :options="status"
                            :setSelectedAction="setStatus"
                        ></select-vue>
                    </div>

                    <div>
                        <h4 class="select-label">Search by Clinic Name</h4>
                        <input-vue
                            id="aba8f58d-5078-4ace-a9b9-909e4d027311"
                            :placeholder="'enter here'"
                            :value="searchNameValue"
                            @setText="setSearchNameValue"
                            v-model="searchNameValue"
                            :withSearchIcon="true"
                            role="search"
                        />
                    </div>
                    <div>
                        <h4 class="select-label">Search by Clinic Town</h4>
                        <input-vue
                            id="808bc813-2a29-49a1-bc1b-d8d566f2ebaa"
                            :placeholder="'enter here'"
                            :value="searchTownValue"
                            @setText="setSearchTownValue"
                            v-model="searchTownValue"
                            :withSearchIcon="true"
                            role="search"
                        />
                    </div>
                    <div>
                        <h4 class="select-label">Search by Clinic Postcode</h4>
                        <input-vue
                            id="b1774372-183b-443b-aef6-0caf68277200"
                            :placeholder="'enter here'"
                            :value="searchPostcodeValue"
                            @setText="setSearchPostcodeValue"
                            v-model="searchPostcodeValue"
                            :withSearchIcon="true"
                            role="search"
                        />
                    </div>

                    <div>
                        <h4 class="select-label">Include</h4>
                        <tabs-vue
                            id="c9fe0ab5-b6c4-4789-851a-8ba886a3ed82"
                            :options="include"
                            :activeOption="include[0]"
                            :setSelectedAction="setInclude"
                        ></tabs-vue>
                    </div>
                </div>
            </div>
            <table-vue
                :statusSelected="statusSelected"
                :includeSelected="includeSelected"
                :searchValue="searchNameValue"
                :searchByTown="searchTownValue"
                :searchByPostcode="searchPostcodeValue"
            />
        </div>
    </common-layout>
</template>

<script>
import TableVue from "../../components/ClinicsTableVue.vue";
import CommonLayout from "../../views/CommonLayout.vue";
import SelectVue from "../../components/SelectVue/SelectVue.vue";
import InputVue from "../../components/InputVue/InputVue.vue";
import TabsVue from "../../components/TabsVue/TabsVue.vue";
import { ref } from "vue";
export default {
    components: {
        CommonLayout,
        TableVue,
        SelectVue,
        InputVue,
        TabsVue,
    },
    setup() {
        const searchNameValue = ref("");
        const searchTownValue = ref("");
        const searchPostcodeValue = ref("");
        const statusSelected = ref("Active");
        const includeSelected = ref("All");

        const include = ["All", "Remote", "In-person"];
        const status = ["Active", "Disabled"];

        const setStatus = (status) => {
            statusSelected.value = status;
        };

        const setInclude = (status) => {
            includeSelected.value = status;
        };

        const setSearchNameValue = (data) => {
            searchNameValue.value = data;
        };

        const setSearchTownValue = (data) => {
            searchTownValue.value = data;
        };

        const setSearchPostcodeValue = (data) => {
            searchPostcodeValue.value = data;
        };

        return {
            status,
            include,
            setStatus,
            statusSelected,
            setInclude,
            includeSelected,
            searchNameValue,
            setSearchNameValue,
            setSearchTownValue,
            setSearchPostcodeValue,
            searchTownValue,
            searchPostcodeValue,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Clinitians.scss";
</style>

