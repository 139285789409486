<template>
    <div class="tab-access-wrapper">
        <ul class="tab-access-navigation">
            <li
                class="navigation-item openRecords"
                :class="
                    activeTab === 'granted' ? 'navigation-item--active' : ''
                "
                @click="activeTab = 'granted'"
            >
                Open Care Records
            </li>
            <li
                class="navigation-item removedRecords"
                :class="
                    activeTab === 'blocked' ? 'navigation-item--active' : ''
                "
                @click="activeTab = 'blocked'"
            >
                Removed Care Records
            </li>
        </ul>
        <section class="tab-access-container" v-if="activeTab === 'granted'">
            <input-vue
                :withSearchIcon="true"
                :searchValue="searchValue"
                @setText="setSearchValue"
                :placeholder="'Search'"
            />
            <open-records-table
                :loading="loading"
                :clinicianId="clinicianId"
                :searchValue="searchValue"
            />
        </section>
        <section class="tab-access-container" v-if="activeTab === 'blocked'">
            <div class="tab-access-filter-wrapper">
                <input-vue
                    :withSearchIcon="true"
                    :searchValue="searchValue"
                    @setText="setSearchValue"
                    :placeholder="'Search'"
                />
                <div class="datepicker-wrapper">
                    <div class="date-container" @click="isCalendarOpen = true">
                        {{ selectedDatesTitle }}
                    </div>
                    <div v-if="isCalendarOpen" ref="datepickerRef">
                        <date-range
                            v-model="selectedDate"
                            @onSelect="isCalendarOpen = false"
                            :next-prev-icon="true"
                            :last-month="todayDt"
                            :first-month="firstDt"
                            :enableSecondCalendar="true"
                        />
                    </div>
                </div>
            </div>
            <removed-records-table
                :loading="loading"
                :clinicianId="clinicianId"
                :searchValue="searchValue"
                :selectedDate="selectedDate"
            />
        </section>
    </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import OpenRecordsTable from "./OpenRecordsTable.vue";
import InputVue from "./InputVue/InputVue.vue";
import RemovedRecordsTable from "./RemovedRecordsTable.vue";
import DateRange from "./InputVue/DateRange.vue";
import moment from "moment";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
export default {
    components: { OpenRecordsTable, InputVue, RemovedRecordsTable, DateRange },

    setup() {
        const store = useStore();
        const activeTab = ref("granted");
        const searchValue = ref("");
        const isCalendarOpen = ref(false);
        const loading = ref(true);
        const todayDt = ref(moment());
        const datepickerRef = ref(null);
        const firstDt = ref(moment().subtract(8, "M"));
        const clinicianId = computed(() => {
            return store.getters["clinicians/clinicianDetails"]?.id;
        });
        const selectedDatesTitle = computed(() => {
            return (
                selectedDate.value?.[0].format("DD.MM.yyyy") +
                " - " +
                selectedDate.value?.[1].format("DD.MM.yyyy")
            );
        });
        const selectedDate = ref([
            moment().subtract(1, "month").startOf("day"),
            moment().endOf("day"),
        ]);
        const disabledFromTo = ref({
            from: moment("2022-07-01"),
            to: moment("2022-07-21"),
        });
        onClickOutside(datepickerRef, () => {
            isCalendarOpen.value = false;
        });
        watch(activeTab, () => {
            searchValue.value = "";
        });
        const setSearchValue = (text) => {
            searchValue.value = text;
        };
        const showCalendar = () => {
            isCalendarOpen.value = true;
        };

        watch(
            [clinicianId, searchValue, activeTab, selectedDatesTitle],
            async () => {
                loading.value = true;
                if (clinicianId.value) {
                    let payload = {
                        clinicianId: clinicianId.value,
                        accessStatus: activeTab.value,
                        sortBy: "forename",
                        sortDescending: true,
                        searchedValue: searchValue.value,
                    };
                    if (activeTab.value === "blocked") {
                        payload = {
                            ...payload,
                            cancellationStartDate: moment(selectedDate.value[0])
                                .utcOffset(0, true)
                                .format(),
                            cancellationEndDate: moment(selectedDate.value[1])
                                .utcOffset(0, true)
                                .format(),
                        };
                    }
                    await store.dispatch("clinicians/setCareRecords", payload);
                }
                loading.value = false;
            },
            {
                deep: true,
            }
        );

        return {
            activeTab,
            searchValue,
            setSearchValue,
            showCalendar,
            isCalendarOpen,
            moment,
            todayDt,
            firstDt,
            selectedDate,
            disabledFromTo,
            datepickerRef,
            loading,
            selectedDatesTitle,
            clinicianId,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/tabAccess.scss";
</style>

