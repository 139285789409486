<template>
    <modal-layout v-if="isOpen">
        <div class="modal-block" ref="target">
            <div class="modal-closable-title-wrapper">
                <div class="modal-title-wrapper">
                    <h3 class="modal-title">
                        Disable G4P User Account {{ isEmployed }}
                    </h3>
                </div>

                <div class="modal-close-wrapper" @click="closeWithoutSaving()">
                    <img src="@/assets/icons/icon-close.svg" alt="icon" />
                </div>
            </div>

            <modal-separator />

            <div class="disable-acc-descriptopn">
                Are you sure you want to disable {{ name?.forename }}
                {{ name?.surname }} Gateway for Professionals account? Access to
                all cases on the G4P portal will be immediately denied. Please
                enter a reason for disabling this account
            </div>

            <div class="disable-acc-reeason-dropdown">
                <multi-select
                    :options="reasons"
                    :setSelectedAction="setSelected"
                    :placeholderText="'Please choose the reason'"
                    :selectedDefault="selected"
                    :withInputOption="true"
                />
            </div>

            <modal-separator />
            <div class="btn-block">
                <btn-vue
                    id="708f1733-0986-4a2f-81ec-b4e18e8906a1"
                    :type="'secondary-btn'"
                    @click="closeWithoutSaving"
                    >Cancel</btn-vue
                >
                <btn-vue
                    id="b354bbaf-43f9-44ec-9629-221816a5d92e"
                    :type="'primary-btn'"
                    @click="$emit('disable-account', selected)"
                    :isDisabled="!selected?.length"
                    >Disable account</btn-vue
                >
            </div>
        </div>
    </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { onMounted, ref, toRefs, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import MultiSelect from "../SelectVue/MultiSelect.vue";
// import { useStore } from "vuex";
// import { toast } from "vue3-toastify";

export default {
    components: {
        ModalLayout,
        BtnVue,
        ModalSeparator,
        MultiSelect,
    },
    props: {
        isOpen: Boolean,
        name: Object,
    },
    emits: {
        closeDisableAcc: Function,
        disableAccount: Function,
    },
    setup(props, context) {
        const { isOpen } = toRefs(props);
        const target = ref();
        const reasons = ref([
            "Removal from Psych Health Network",
            "No longer working for Psych Health (Employed Clinicians)",
        ]);

        const selected = ref([]);

        const closeWithoutSaving = () => {
            // toast.warning("Your changes haven't been saved");

            context.emit("closeDisableAcc");
            selected.value = [];
        };
        onClickOutside(target, () => {
            closeWithoutSaving();
        });

        const setSelected = async (data) => {
            selected.value = data;
        };

        onMounted(() => {
            console.log("onMounted");
        });

        watch(isOpen, () => {
            if (isOpen.value) {
                selected.value = [];
            }
        });

        return {
            closeWithoutSaving,
            target,
            reasons,
            selected,
            setSelected,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

