<template>
  <header class="header-container">
    <div class="menu-wrapper">
      <img src="@/assets/icons/logo.svg" alt="my-logo" />
      <ul class="menu-container" role="list">
        <template v-if="isAuthenticated">
          <li class="menu-item-container">
            <router-link to="/" class="menu-item">Home</router-link>
          </li>
          <li class="menu-item-container">
            <router-link
              to="/availability"
              class="menu-item"
              :class="{
                'menu-item--active': route.meta.menuActive === 'availability',
              }"
              >Availability</router-link
            >
          </li>
          <template v-if="isAdmin">
            <li class="menu-item-container">
              <router-link
                to="/clinicians"
                class="menu-item"
                :class="{
                  'menu-item--active': route.meta.menuActive === 'clinicians',
                }"
                >Clinicians</router-link
              >
            </li>
            <li class="menu-item-container">
              <router-link
                to="/clinics"
                class="menu-item"
                :class="{
                  'menu-item--active': route.meta.menuActive === 'clinics',
                }"
                >Clinics</router-link
              >
            </li>
            <li class="menu-item-container"  v-if="isSuperAdmin">
              <router-link to="/report" class="menu-item">Reports</router-link>
            </li>
          </template>
        </template>
      </ul>
    </div>

    <div
      ref="target"
      class="login-block"
      @click="switchOpen"
      id="e69e369d-e1ee-4f54-bf11-fd29aa4383d9"
    >
      <div class="img-profile"></div>
      <span v-if="isAuthenticated" id="98a23663-d3a9-41cd-9727-d3ad2b6c8316">{{
        username
      }}</span>
      <span v-else @click="login">Login</span>
      <template v-if="isAuthenticated">
        <div class="img-triangle-container">
          <div class="img-arrow"></div>
        </div>
        <dropdown-menu v-if="isOpen"></dropdown-menu>
      </template>
    </div>
  </header>
</template>

<script>
import { ref } from "@vue/reactivity";
import DropdownMenu from "./DropdownMenu.vue";
import { onClickOutside } from "@vueuse/core";
import { computed } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { loginRequest } from "@/auth";
import { useMsal } from "@/hooks/useMsal";
export default {
  components: {
    DropdownMenu,
  },
  setup() {
    const { instance } = useMsal();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isOpen = ref(false);
    const login = async () => {
      const resp = await instance.loginRedirect(loginRequest);

      store.commit("auth/setToken", resp?.accessToken);
      store.commit("auth/setUsername", resp?.account?.name);
      store.commit("auth/setHomeAccountId", resp?.account?.homeAccountId);
      router.push({
        name: "home",
      });
    };
    const username = computed(() => store.getters["auth/username"]);
    const isAuthenticated = computed(
      () => store.getters["auth/isAuthenticated"]
    );
    const isSuperAdmin = computed(() => store.getters["auth/isSuperAdmin"]);
    const isAdmin = computed(() => store.getters["auth/isAdmin"]);
    const target = ref(null);
    onClickOutside(target, () => {
      isOpen.value = false;
    });

    const switchOpen = () => {
      if (isOpen.value) {
        isOpen.value = false;
      } else {
        isOpen.value = true;
      }
    };
    return {
      isOpen,
      target,
      username,
      isAuthenticated,
      login,
      switchOpen,
      route,
      isSuperAdmin,
      isAdmin
    };
  },
  created() {},
};
</script>

<style lang="scss">
@import "./../assets/scss/components/header.scss";
</style>