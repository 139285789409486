<template>
  <common-layout>
    <div class="container">
      <h1 class="title">Clinician Management Module</h1>
      <card-vue class="card-container--login" @click="login"
        >Click here to Login</card-vue
      >
    </div>
  </common-layout>
</template>

<script>
import { loginRequest } from "@/auth";
import CardVue from "../components/CardVue.vue";
import CommonLayout from "../views/CommonLayout.vue";
import { useMsal } from "../hooks/useMsal";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { CardVue, CommonLayout },
  setup() {
    const { instance } = useMsal();
    const router = useRouter();
    const store = useStore();

    const login = async () => {
      const resp = await instance.loginRedirect(loginRequest);
      store.commit("auth/setToken", resp?.accessToken);
      store.commit("auth/setUsername", resp?.account?.name);
      store.commit("auth/setHomeAccountId", resp?.account?.homeAccountId);
      store.dispatch("auth/setSuperAdmin", resp?.accessToken);
      router.push({
        name: "home",
      });
    };

    return { login };
  },
};
</script>

<style lang="scss">
@import "./../assets/scss/pages/Login.scss";
</style>