<template>
  <div class="report-collapse-row-subwrapper" ref="wrapper">
    <div class="report-collapse-row-title">
      {{ clinician?.clinicianName }}
    </div>
    <div
      class="left-border-inner-row"
      @click="
        router.push({
          name: 'clinicians-schedule',
          query: {
            id: clinician?.clinicianDomainId,
            startDate: moment(startDate)
              .startOf('week')
              .format(API_DATE_LOCAL_FORMAT),
          },
        })
      "
    >
      <div class="report-table-collapse-row--left">
        <div class="report-table-cell">Available</div>
      </div>
      <div class="report-table-collapse-row--left">
        <div class="report-table-cell">Utilised</div>
      </div>
      <div class="report-table-collapse-row--left">
        <div class="report-table-cell">Cancelled >48hrs</div>
      </div>
      <div class="report-table-collapse-row--left">
        <div class="report-table-cell">Cancelled &lt;48hrs</div>
      </div>
      <div class="report-table-collapse-row--left">
        <div class="report-table-cell">DNA</div>
      </div>
      <div class="report-table-collapse-row--left">
        <div class="report-table-cell">Other Meetings</div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";

export default {
  props: { clinician: Object, subExpandedId: String, startDate: String },
  emits: {
    "set-element-height": Function,
  },
  setup(_, context) {
    const wrapper = ref(null);
    const router = useRouter();
    onMounted(() => {
      context.emit("set-element-height", wrapper.value.offsetHeight);
    });
    return { wrapper, router, moment };
  },
};
</script>

<style lang="scss">
// @import "@/assets/scss/components/btn.scss";
</style>