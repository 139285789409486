<template>
  <div class="select-wrapper select-wrapper--multi">
    <div
      ref="target"
      class="select-container"
      :class="selected?.length ? 'select-container--active' : ''"
    >
      <div
        class="selected-option"
        :class="{ 'selected-option--open': this.isOpen }"
      >
        <span class="multiselect-text">
          <img src="@/assets/icons/icon-search.svg" alt="icon" />
          <input
            :ref="inputRef"
            v-if="this.isOpen"
            type="text"
            v-model="searchText"
            autofocus
          />
          <span v-else @click="this.isOpen = true" style="margin-left: 8px">{{
            selectedDefault
          }}</span>
        </span>

        <div class="select-right-icons">
          <div v-if="withInfo" @click="this.showHintText = !this.showHintText">
            <img src="@/assets/icons/icon-info-input.svg" alt="icon" />
          </div>
          <div @click="this.isOpen = !this.isOpen">
            <img
              class="select-arrow-icon"
              src="@/assets/icons/select-icon.svg"
              alt="icon"
            />
          </div>
        </div>
      </div>
      <ul v-if="isOpen">
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          @click="setSelected(option)"
          class="option"
          :class="withDots ? 'withDots' : ''"
        >
          <span>{{ option }}</span>
        </li>
      </ul>
    </div>

    <div v-if="showHintText && withInfo" class="hint-text-wrapper">
      <span class="hint-text">Info text</span>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { toRefs } from "vue";
import { onClickOutside } from "@vueuse/core";
import { watch } from "@vue/runtime-core";
import { useField } from "vee-validate";
import * as yup from "yup";

export default {
  props: {
    options: Array,
    selectedDefault: String,
    withInfo: {
      require: false,
    },
    withDots: {
      require: false,
    },
  },
  setup(props, context) {
    const { value: inputRef } = useField("search", yup.string().trim());
    const selected = ref(props.selectedDefault);
    const { options } = toRefs(props);
    const filteredOptions = ref(options.value);
    const searchText = ref(null);
    const isOpen = ref(false);
    const showHintText = ref(false);
    const target = ref(null);
    onClickOutside(target, () => {
      if (isOpen.value) {
        context.emit("set-selected-action", selected.value);
        isOpen.value = false;
      }
    });

    const setSelected = (text) => {
      context.emit("set-selected-action", text);
      selected.value = text;
      isOpen.value = false;
    };

    watch(searchText, (value) => {
      filteredOptions.value = options?.value?.filter((data) =>
        data.toLowerCase().includes(value.toLowerCase())
      );
    });

    watch(
      props,
      () => {
        filteredOptions.value = options.value;
      },
      {
        deep: true,
      }
    );

    return {
      selected,
      setSelected,
      isOpen,
      target,
      showHintText,
      searchText,
      filteredOptions,
      inputRef,
    };
  },
};
</script>

<style lang="scss">
@import "./../../assets/scss/components/select.scss";
</style>