<template>
    <!-- eslint-disable -->
    <div v-if="totalItems && !loading">
        <data-table
            :headers="headers"
            :items="careRecords || []"
            :loading="loading"
            :hide-footer="true"
            :sortBy="sortBy"
            :rows-per-page="totalItems"
        >
            <template
                #pagination="{ prevPage, nextPage, isFirstPage, isLastPage }"
            >
            </template>
            <template #item-action="{ caseReference, caseId }">
                <div class="btn-remove-wrapper">
                    <div
                        class="btn-remove"
                        :style="totalItems > 15 ? 'margin-left: 16px' : ''"
                        @click="openBlockAccessModal(caseReference, caseId)"
                    >
                        <img src="@/assets/icons/icon-disable.svg" alt="icon" />
                    </div>
                </div>
            </template>
        </data-table>
        <div class="total-items">
            {{ totalItems }} record{{ totalItems > 1 ? "s" : "" }}
        </div>
    </div>
    <div v-else style="margin: 0 auto">
        <loader-vue v-if="loading" />
        <h3 v-else class="tab-access--no-data">There is no data</h3>
    </div>
    <modal-block-account
        :isOpen="isBlockAccessOpen"
        :recordNumber="recordNumber"
        @disableAccount="blockAccess"
        @closeDisableAcc="isBlockAccessOpen = false"
    />
</template>

<script>
import { computed, defineComponent, ref, toRefs } from "vue";
import { toast } from "vue3-toastify";
import { useStore } from "vuex";
import LoaderVue from "./LoaderVue.vue";
import ModalBlockAccount from "./ModalVue/ModalBlockAccount.vue";

export default defineComponent({
    props: { loading: Boolean, clinicianId: String, searchValue: String },
    components: { ModalBlockAccount, LoaderVue },
    setup(props) {
        const isBlockAccessOpen = ref(false);
        const { clinicianId, searchValue } = toRefs(props);
        const recordNumber = ref();
        const caseId = ref();
        const store = useStore();
        const careRecords = computed(() => {
            return store.getters["clinicians/careRecords"];
        });
        const totalItems = computed(() => careRecords.value?.length);

        const sortBy = ref("patientSurname");
        const headers = [
            { text: "Forename", value: "patientForenames", sortable: true },
            { text: "Surname", value: "patientSurname", sortable: true },
            {
                text: "Care Record Number",
                value: "caseReference",
                sortable: true,
            },
            { text: "Action", value: "action" },
        ];

        const blockAccess = async (blockingReason) => {
            const result = await store.dispatch(
                "clinicians/blockCareRecordsAccess",
                {
                    clinicianId: clinicianId.value,
                    blockingReason,
                    caseId: caseId.value,
                }
            );
            if (result === 200) {
                toast.success(
                    `Access to the Care record ${recordNumber.value} is blocked successfully`
                );
                isBlockAccessOpen.value = false;
                store.dispatch("clinicians/setCareRecords", {
                    clinicianId: clinicianId.value,
                    accessStatus: "granted",
                    sortBy: "forename",
                    sortDescending: true,
                    searchedValue: searchValue.value,
                });
            } else {
                toast.error("Account wasn't blocked");
            }
        };

        const openBlockAccessModal = (caseReference, caseIdValue) => {
            isBlockAccessOpen.value = true;
            recordNumber.value = caseReference;
            caseId.value = caseIdValue;
        };
        return {
            headers,
            careRecords,
            isBlockAccessOpen,
            blockAccess,
            totalItems,
            recordNumber,
            sortBy,
            openBlockAccessModal,
        };
    },
});
</script>

<style lang="scss">
@import "@/assets/scss/components/table.scss";
</style>

