<template>
    <div class="appoint-container">
        <div v-for="(item, index) in appointmentList" :key="index" class="appoint-block">
            <span class="appoint-title">{{ item.title }}</span>
            <span class="appoint-desc">{{ item.desc }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        appointmentList: []
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/appointments.scss';
</style>