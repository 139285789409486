<template>
  <div class="report-page">
    <common-layout>
      <div class="report-header-filter-wrapper">
        <div class="date-wrapper">
          <label for="795b6699-0792-48a4-9cd5-2de2edf69d36" class="select-label"
            >Start Date</label
          >
          <date-vue
            id="795b6699-0792-48a4-9cd5-2de2edf69d36"
            :date="moment(startDate).format('yyyy-MM-DD')"
            :setDate="setStartDate"
            :maxDate="moment(endDate).format('yyyy-MM-DD')"
          />
        </div>
        <div class="date-wrapper">
          <label for="f2257f50-e10b-44c3-ad5f-4efe97ab016c" class="select-label"
            >End Date</label
          >
          <date-vue
            id="f2257f50-e10b-44c3-ad5f-4efe97ab016c"
            :date="moment(endDate).format('yyyy-MM-DD')"
            :setDate="setEndDate"
            :minDate="moment(startDate).format('yyyy-MM-DD')"
          />
        </div>
        <div>
          <h4 class="select-label">Report Group</h4>
          <multi-select
            style="width: 350px"
            :withSearch="true"
            :options="selectedGroupObjects.map((data) => data.name)"
            :selectedDefault="selectedGroupNames"
            :setSelectedAction="selectGroupNames"
          />
        </div>
        <div>
          <h4 class="select-label">Group by</h4>
          <tabs-vue
            id="c9fe0ab5-b6c4-4789-851a-8ba886a3ed82"
            :options="groups"
            :activeOption="groups[2]"
            :setSelectedAction="setGroup"
          ></tabs-vue>
        </div>
        <img
          id="bbba4013-ee03-4505-a0ce-8d1ef37009c5"
          src="@/assets/icons/icon-database.svg"
          alt="icon"
          title="Download report group"
          @click="downloadReportGroups"
        />
      </div>
      <div class="report-chart-wrapper">
        <div class="report-chart">
          <div
            class="pie"
            :style="{
              '--p':
                report.totalUsagePercentage > 100
                  ? 100
                  : report.totalUsagePercentage < 0
                  ? 0
                  : report.totalUsagePercentage,
            }"
          >
            <div class="pie-title">Total</div>
            {{
              report.totalUsagePercentage > 100
                ? 100
                : report.totalUsagePercentage < 0
                ? 0
                : report.totalUsagePercentage
            }}%
          </div>
        </div>
        <div class="report-chart-container">
          <div
            class="report-chart-column-legend"
            v-if="report.utilizedAppointments"
          >
            <div class="report-chart-column-utilised">Utilised</div>
            <div class="report-chart-column-available">Available</div>
          </div>
          <div class="report-chart-column-wrapper">
            <div
              class="report-chart-column"
              :style="{
                'background-position-y':
                  report.utilizedAppointments?.length > 7 ? '-37px' : '-33px',
              }"
            >
              <template v-if="!isLoading">
                <div
                  class="column-wrapper"
                  v-for="(column, index) in report.utilizationPercentages"
                  :key="column + index"
                >
                  <div
                    class="column-availaible"
                    :style="{
                      height:
                        report.unutilizedAvailabilityPercentages?.[index] +
                        'px',
                    }"
                  >
                    <div
                      class="column-appointment"
                      :style="{
                        height: column + 'px',
                      }"
                    ></div>
                  </div>
                  <div v-if="!isLoading" class="column-label">
                    <template v-if="selectedGroup === 'month'">
                      {{ moment(report.dates?.[index]).format("MMM") }}
                    </template>
                    <template v-if="selectedGroup === 'day'">
                      {{ moment(report.dates?.[index]).format("DD.MM") }}
                    </template>
                    <template v-if="selectedGroup === 'year'">
                      {{ moment(report.dates?.[index]).format("YYYY") }}
                    </template>
                  </div>
                </div></template
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading">
        <report-main-table
          :report="report"
          :selectedGroup="selectedGroup"
          :startDate="startDate"
        />
      </div>
      <div class="report-table-loader" v-else>
        <loader-vue />
      </div>

      <div class="report-table-btn-grooup-wrapper">
        <btn-vue
          @click="router.push({ name: 'report-groups' })"
          :type="'secondary-btn--square'"
          >View Report Groups</btn-vue
        >
      </div>

      <modal-session-timeout
    /></common-layout>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import ModalSessionTimeout from "../../components/ModalVue/ModalSessionTimeout.vue";
import TabsVue from "../../components/TabsVue/TabsVue.vue";
import DateVue from "../../components/InputVue/DateVue.vue";
import CommonLayout from "../../views/CommonLayout.vue";
import BtnVue from "../../components/BtnVue.vue";
import { useRouter } from "vue-router";
import MultiSelect from "../../components/SelectVue/MultiSelect.vue";
import { useStore } from "vuex";
import moment from "moment";
import LoaderVue from "../../components/LoaderVue.vue";
import ReportMainTable from "../../components/ReportMainTable.vue";
import { toast } from "vue3-toastify";
export default {
  components: {
    ModalSessionTimeout,
    TabsVue,
    DateVue,
    CommonLayout,
    BtnVue,
    MultiSelect,
    LoaderVue,
    ReportMainTable,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const selectedGroup = ref("day");
    const isLoading = ref(true);

    const endDate = ref(moment());
    const startDate = ref(moment().subtract(6, "days"));

    const selectedGroupObjects = computed(
      () => store.getters["report/reportGroups"]
    );
    const selectedGroupNames = ref([]);
    const report = computed(() => store.getters["report/report"]);
    const groups = ["year", "month", "day"];
    const setGroup = (group) => {
      selectedGroup.value = group;
    };

    watch(selectedGroupObjects, () => {
      selectedGroupNames.value = selectedGroupObjects.value.map(
        (data) => data.name
      );
    });

    const selectGroupNames = (groups) => {
      selectedGroupNames.value = groups;
    };
    const setStartDate = (date) => {
      if (moment(endDate.value).isBefore(moment(date))) {
        toast.error("Start date must be prior to end date");
      } else {
        startDate.value = date;
      }
    };
    const setEndDate = (date) => {
      if (moment(startDate.value).isAfter(moment(date))) {
        toast.error("Start date must be prior to end date");
      } else {
        endDate.value = date;
      }
    };

    watch(selectedGroup, () => {
      if (selectedGroup.value === "month") {
        setStartDate(moment(endDate.value).subtract(5, "months"));
      }
      if (selectedGroup.value === "day") {
        setStartDate(moment(endDate.value).subtract(6, "days"));
      }
      if (selectedGroup.value === "year") {
        setStartDate(moment(endDate.value).subtract(5, "years"));
      }
    });
    watch([startDate, endDate, selectedGroupNames], async () => {
      isLoading.value = true;
      let reportGroupIds = [];
      if (
        selectedGroupObjects.value?.length !== selectedGroupNames.value?.length
      ) {
        reportGroupIds = selectedGroupObjects.value
          ?.map((item) => {
            if (selectedGroupNames.value?.includes(item.name)) {
              return item.id;
            }
          })
          ?.filter((id) => id);
      }
      const payload = {
        startDate: startDate.value,
        endDate: moment(endDate.value).endOf("day"),
        useAllReportGroups: !reportGroupIds.length,
        reportGroupIds,
        groupBy: selectedGroup.value + "s",
      };
      await store.dispatch("report/setReport", payload);
      isLoading.value = false;
    });

    onMounted(() => {
      store.dispatch("report/setReportGroups");
    });

    const downloadReportGroups = async () => {
      let reportGroupIds = [];
      if (
        selectedGroupObjects.value?.length !== selectedGroupNames.value?.length
      ) {
        reportGroupIds = selectedGroupObjects.value
          ?.map((item) => {
            if (selectedGroupNames.value?.includes(item.name)) {
              return item.id;
            }
          })
          ?.filter((id) => id);
      }
      const payload = {
        startDate: startDate.value,
        endDate: moment(endDate.value).endOf("day"),
        useAllReportGroups: !reportGroupIds.length,
        reportGroupIds,
        groupBy: selectedGroup.value + "s",
      };
      const status = await store.dispatch(
        "report/downloadReportGroups",
        payload
      );
      if (status === 200) {
        toast.success("Report successfully downloaded");
      } else {
        toast.error("Failed to download the report");
      }
    };

    return {
      isLoading,
      setGroup,
      groups,
      router,
      selectGroupNames,
      selectedGroupNames,
      selectedGroupObjects,
      report,
      endDate,
      startDate,
      setStartDate,
      setEndDate,
      moment,
      selectedGroup,
      downloadReportGroups,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Report.scss";
</style>
