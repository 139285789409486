<template>
    <modal-layout v-if="isOpen">
        <div class="modal-block" ref="target" style="min-width: 560px">
            <div class="modal-closable-title-wrapper">
                <div class="modal-title-wrapper">
                    <h3 class="modal-title">
                        Block access to Care Record {{ recordNumber }}
                    </h3>
                </div>

                <div class="modal-close-wrapper" @click="closeWithoutSaving()">
                    <img src="@/assets/icons/icon-close.svg" alt="icon" />
                </div>
            </div>

            <modal-separator />

            <div class="disable-acc-descriptopn">
                Reason for blocking access
            </div>

            <div class="disable-acc-reeason-input">
                <input-vue
                    @setText="setReason"
                    :searchValue="reason"
                    :placeholder="'Enter reason here'"
                />
            </div>

            <modal-separator />
            <div class="btn-block">
                <btn-vue
                    id="708f1733-0986-4a2f-81ec-b4e18e8906a1"
                    :type="'secondary-btn'"
                    @click="closeWithoutSaving"
                    >Cancel</btn-vue
                >
                <btn-vue
                    id="b354bbaf-43f9-44ec-9629-221816a5d92e"
                    :type="'primary-btn'"
                    @click="$emit('disable-account', reason)"
                    :isDisabled="!reason?.length"
                    >Block access</btn-vue
                >
            </div>
        </div>
    </modal-layout>
</template>
<script>
import ModalLayout from "./ModalLayout.vue";
import BtnVue from "../BtnVue.vue";
import ModalSeparator from "./ModalSeparator.vue";
import { ref, toRefs, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import InputVue from "../InputVue/InputVue.vue";
import { toast } from "vue3-toastify";
// import { useStore } from "vuex";

export default {
    components: {
        ModalLayout,
        BtnVue,
        ModalSeparator,
        InputVue,
    },
    props: {
        isOpen: Boolean,
        recordNumber: Number,
    },
    emits: {
        closeDisableAcc: Function,
        disableAccount: Function,
    },
    setup(props, context) {
        const { isOpen } = toRefs(props);
        const target = ref();
        const reason = ref("");

        const closeWithoutSaving = () => {
            toast.warning("Your changes haven't been saved");

            context.emit("closeDisableAcc");
            reason.value = "";
        };
        onClickOutside(target, () => {
            closeWithoutSaving();
        });

        const setReason = async (data) => {
            reason.value = data;
        };

        watch(isOpen, () => {
            if (isOpen.value) {
                reason.value = "";
            }
        });

        return {
            closeWithoutSaving,
            target,
            reason,
            setReason,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/modal.scss";
</style>

