<template>
    <common-layout>
        <div class="availability-wrapper">
            <div class="clinitians-filter-wrapper">
                <h2 class="clinitians-title">Filter Clinician Availability</h2>
                <div class="clinitians-filter-block">
                    <div>
                        <h4 class="select-label">Employed type</h4>
                        <tabs-vue id="c9fe0ab5-b6c4-4789-851a-8ba886a3ed82" :options="include"
                            :activeOption="includeSelected" :setSelectedAction="setInclude"></tabs-vue>
                    </div>
                    <div>
                        <h4 class="select-label">Appointment Type</h4>
                        <select-vue class="appointment-select-container" style="z-index: 6"
                            id="0aa75f31-d0ea-4bc5-9e1c-fc661e8a9c98" :selectedDefault="appointmentsSelected"
                            :withIcons="true" :options="appointments" :setSelectedAction="setAppointments"></select-vue>
                    </div>
                    <div>
                        <h4 class="select-label">Clinician Type</h4>
                        <multi-select style="z-index: 6; min-width: 170px" id="082d439a-0eec-42a6-b5ef-87c05eed1aba"
                            :selectedDefault="clinicianTypeSelected" :options="clinicianType"
                            :setSelectedAction="setClinicianType"></multi-select>
                    </div>
                    <div>
                        <h4 class="select-label">Specialisms</h4>
                        <multi-select :style="{ zIndex: 6, width: '310px' }" id="11aadb33-9d47-435f-8e64-a265767990a1"
                            :selectedDefault="specialismsSelected" :options="specialisms" :withSearch="true"
                            :setSelectedAction="setSpecialisms"></multi-select>
                    </div>
                    <div>
                        <label for="33be408c-d5ff-47b5-85bc-0a0fbbf9bdfd" class="select-label">
                            Duration (Min)
                        </label>
                        <input id="33be408c-d5ff-47b5-85bc-0a0fbbf9bdfd" style="width: 67px" class="input-container"
                            @blur="setDurationValue($event.target.value)" @input="
                                searchDurationValue =
                                $event.target.value?.replace(/\D/g, '')
                                " :value="searchDurationValue" type="number" />
                    </div>
                </div>

                <div class="clinitians-filter-block filter-block-bottom">
                    <div class="filter-tabs-block">
                        <h4 class="select-label">Clinic Type</h4>
                        <tabs-vue id="afa55c68-fc21-4f95-8c4a-4059f59ffd53" :options="clinicType"
                            :activeOption="clinicTypeActiveOption" :setSelectedAction="appointments[0] === appointmentsSelected
                                    ? setClinicTypeSelected
                                    : undefined
                                "></tabs-vue>
                    </div>
                    <div class="filter-location-block" v-if="appointments[2] === appointmentsSelected ||
                        appointments[0] === appointmentsSelected
                        ">
                        <h4 class="select-label" style="margin-left: 20px">
                            Location
                        </h4>
                        <div class="clinitians-filter-subblock">
                            <label for="90753f0f-8b8d-4da6-98c1-c9dab3308063" class="select-label">Postcode</label>
                            <div class="input-error-message-container">
                                <input-vue style="width: 110px" id="90753f0f-8b8d-4da6-98c1-c9dab3308063"
                                    :placeholder="'enter here'" :value="searchPostcodeValue"
                                    @setText="setSearchPostcodeValue" v-model="searchPostcodeValue" role="search" />
                                <span class="error-message">{{
                                    postcodeErrorMessage
                                }}</span>
                            </div>

                            <label for="d34f061c-aeb2-4441-9715-950ae5b30df3" class="select-label">Distance (miles)</label>
                            <input-vue role="search" style="width: 110px" id="d34f061c-aeb2-4441-9715-950ae5b30df3"
                                :placeholder="'enter here'" :value="searchDistanceValue" @setText="setSearchDistanceValue"
                                v-model="searchDistanceValue" :type="'number'" />
                        </div>
                    </div>

                    <div class="filter-clinics-block">
                        <h4 class="select-label">Clinics</h4>
                        <multi-select id="524ab90e-512c-4ca1-927c-053161e83084" :selectedDefault="clinicsSelected"
                            :options="clinics" :withSearch="true" :defaultAll="true"
                            :setSelectedAction="setClinics"></multi-select>
                    </div>
                </div>
                <div v-if="!clinicianTypeSelected.length" class="filter-warning-message">
                    <h2>Please specify the availability search</h2>
                </div>
            </div>
        </div>
        <template v-if="appointmentsSelected?.length && clinicianTypeSelected?.length" #table>
            <div class="layout-block table-wrapper">
                <table-vue :durationValue="durationValue" :includeSelected="includeSelected"
                    :clinicTypeSelected="clinicTypeActiveOption" :appointmentsSelected="appointmentsSelected"
                    :specialismsSelected="specialismsSelected" :clinicianTypeSelected="clinicianTypeSelected"
                    :searchPostcodeValue="searchPostcodeValue" :searchDistanceValue="searchDistanceValue"
                    :clinicsSelected="clinicsSelected" @set-filter-to-storage="setFilterToStorage" />
            </div>
        </template>
    </common-layout>
</template>

<script>
import TableVue from "../../components/AvaiabilityTableVue.vue";
import CommonLayout from "../../views/CommonLayout.vue";
import MultiSelect from "../../components/SelectVue/MultiSelect.vue";
import InputVue from "../../components/InputVue/InputVue.vue";
import TabsVue from "../../components/TabsVue/TabsVue.vue";
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import SelectVue from "../../components/SelectVue/SelectVue.vue";
export default {
    components: {
        CommonLayout,
        TableVue,
        MultiSelect,
        InputVue,
        TabsVue,
        SelectVue,
    },
    setup() {
        const filter = sessionStorage.getItem("filter")
            ? JSON.parse(sessionStorage.getItem("filter"))
            : {};
        const router = useRouter();
        const durationValue = ref(filter?.durationValue || 60);
        const searchDurationValue = ref(60);
        const includeSelected = ref(filter?.includeSelected || "All");
        const clinicTypeSelected = ref(filter?.clinicTypeSelected || "All");
        const appointmentsSelected = ref(filter?.appointmentsSelected || "All");
        const specialismsSelected = ref(filter?.specialismsSelected || []);
        const clinicianTypeSelected = ref(
            filter.clinicianTypeSelected || [
                "CBT Therapist",
                "Psychologist",
                "Counsellor",
            ]
        );
        const clinicianTypeSelectedDefault = ref(
            filter.clinicianTypeSelected || [
                "CBT Therapist",
                "Psychologist",
                "Counsellor",
            ]
        );
        const searchPostcodeValue = ref(filter.searchPostcodeValue || "");
        const postcodeErrorMessage = ref("");
        const searchDistanceValue = ref(filter.searchDistanceValue || 20);
        const clinicsSelected = ref(filter.clinicsSelected || []);
        const store = useStore();
        const specialisms = computed(() => {
            return store.getters["clinicians/specialisations"];
        });
        const clinics = computed(() => {
            const clinicsList = store.getters["availability/clinicsList"];
            if (clinicsList) {
                return clinicsList?.map((data) => data?.name);
            } else return [];
        });
        const include = ["All", "Employed", "Network"];
        const clinicType = ["All", "Remote", "In-person"];

        const clinicTypeActiveOption = ref(
            filter?.clinicTypeActiveOption || "All"
        );
        const appointments = ["All", "Telephone & Video", "Face to Face"];
        const clinicianType = ["CBT Therapist", "Psychologist", "Counsellor"];
        const setSearchPostcodeValue = async (data) => {
            if (data) {
                const status = await store.dispatch(
                    "availability/validatePostCode",
                    data
                );
                if (status === 404) {
                    postcodeErrorMessage.value = "The postcode doesn't exist";
                } else {
                    if (status === 200) {
                        searchPostcodeValue.value = data;
                        postcodeErrorMessage.value = null;
                    }
                }
            } else {
                searchPostcodeValue.value = "";
                postcodeErrorMessage.value = "";
            }
        };
        const setSearchDistanceValue = (data) => {
            searchDistanceValue.value = data;
        };
        const setDurationValue = (data) => {
            if (!data) {
                durationValue.value = 60;
            } else {
                if (+data < 5) {
                    durationValue.value = 5;
                } else {
                    durationValue.value =
                        Math.round(searchDurationValue.value / 5) * 5 || 60;
                }
            }
            searchDurationValue.value = durationValue.value;
        };
        const setClinicianType = (data) => {
            clinicianTypeSelected.value = data;
        };
        const setClinicTypeSelected = (status) => {
            clinicTypeActiveOption.value = status;
        };
        const setInclude = (status) => {
            includeSelected.value = status;
        };
        const setAppointments = (status) => {
            appointmentsSelected.value = status;
        };
        const setSpecialisms = (data) => {
            specialismsSelected.value = data;
        };
        const setClinics = (data) => {
            clinicsSelected.value = data;
        };

        watch(clinics, () => {
            clinicsSelected.value = clinics.value;
        });
        watch(
            [
                appointmentsSelected,
                clinicianTypeSelected,
                clinicTypeActiveOption,
                includeSelected,
            ],
            () => {
                let appointmentType =
                    appointmentsSelected.value.toLocaleLowerCase();
                let clinicFilter =
                    clinicTypeActiveOption.value.toLocaleLowerCase();
                let clinicianTypes = clinicianTypeSelected.value?.map(
                    (data) => {
                        let apiType = data
                            ?.replace(" ", "")
                            ?.toLocaleLowerCase();
                        if (apiType === "cbttherapist") {
                            apiType = "cbtTherapist";
                        }
                        return apiType;
                    }
                );
                if (clinicTypeActiveOption.value === "In-person") {
                    clinicFilter = "inPerson";
                }
                if (appointmentsSelected.value === "Face to Face") {
                    appointmentType = "f2f";
                }
                if (appointmentsSelected.value === "Telephone & Video") {
                    appointmentType = "telephoneAndVideo";
                }
                store.dispatch("availability/setClinicsList", {
                    employmentStatusFilter:
                        includeSelected.value?.toLocaleLowerCase(),
                    clinicFilter: clinicFilter,
                    clinicianTypes: clinicianTypes,
                    appointmentFilter: appointmentType,
                    specialisms: [],
                    searchByClinicianName: "",
                    searchByClinicName: "",
                });
                if (appointments[2] === appointmentsSelected.value) {
                    clinicTypeSelected.value = clinicType[2];
                    clinicTypeActiveOption.value = clinicType[2];
                }
                if (appointments[1] === appointmentsSelected.value) {
                    clinicTypeSelected.value = clinicType[1];
                    clinicTypeActiveOption.value = clinicType[1];
                }
            }
        );

        onMounted(() => {
            window.onbeforeunload = function () {
                sessionStorage.removeItem("filter");
            };
            const filter = sessionStorage.getItem("filter");
            if (!filter) {
                store.dispatch("clinicians/setSpecialisations");
                store.dispatch("availability/setClinicsList", {
                    employmentStatusFilter: "all",
                    clinicFilter: "all",
                    clinicianTypes: [
                        "cbtTherapist",
                        "psychologist",
                        "counsellor",
                        "notSet",
                    ],
                    appointmentFilter: "all",
                    specialisms: [],
                    searchByClinicianName: "",
                    searchByClinicName: "",
                });
            }
        });

        const setFilterToStorage = () => {
            sessionStorage.setItem(
                "filter",
                JSON.stringify({
                    durationValue: durationValue.value,
                    includeSelected: includeSelected.value,
                    clinicTypeSelected: clinicTypeSelected.value,
                    appointmentsSelected: appointmentsSelected.value,
                    specialismsSelected: specialismsSelected.value,
                    clinicianTypeSelected: clinicianTypeSelected.value,
                    searchPostcodeValue: searchPostcodeValue.value,
                    searchDistanceValue: searchDistanceValue.value,
                    clinicsSelected: clinicsSelected.value,
                    clinicTypeActiveOption: clinicTypeActiveOption.value,
                })
            );
        };
        onBeforeRouteLeave((to) => {
            if (to.path !== "/availability/reservation") {
                sessionStorage.removeItem("filter");
            }
        });
        return {
            postcodeErrorMessage,
            router,
            clinicTypeActiveOption,
            searchDistanceValue,
            setSearchDistanceValue,
            setSearchPostcodeValue,
            searchPostcodeValue,
            include,
            setInclude,
            appointments,
            setAppointments,
            includeSelected,
            setDurationValue,
            durationValue,
            specialisms,
            setSpecialisms,
            setClinicianType,
            clinicianType,
            clinicType,
            setClinicTypeSelected,
            clinicTypeSelected,
            setClinics,
            clinics,
            appointmentsSelected,
            clinicianTypeSelected,
            clinicsSelected,
            clinicianTypeSelectedDefault,
            specialismsSelected,
            setFilterToStorage,
            searchDurationValue,
        };
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/pages/Clinitians.scss";
</style>

